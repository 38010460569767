import React from 'react';
import jiraGif from '@/assets/jiraIntegrationGif.gif';
import GlobalButton from '@/components/ui/GlobalButton';
import useActionStore from '../../../../store/Actions';
import useIntegrationsStore from '../../../../store/Integrations';
import CustomModalOverlay2 from '../../../common/Custom/CustomModalOverlay2/CustomModalOverlay2';

export default function IntegrationSuccess() {
  const { selectedIntegration } = useIntegrationsStore();
  const { integrationSuccessModal, toggleIntegrationSuccessModal } =
    useActionStore();

  const handleClose = () => {
    toggleIntegrationSuccessModal(false);
  };
  return (
    <CustomModalOverlay2
      open={integrationSuccessModal}
      onClose={handleClose}
      width="550px"
      radius="12px"
      withCloseButton={false}
    >
      <div className={`flex flex-col items-center`}>
        <div>
          <img src={jiraGif} alt="jiraGif" width={'100'} />
        </div>
        <div className="w-full flex flex-col gap-2 items-center">
          <h1 className="font-semibold text-[20px] text-white-2">
            {selectedIntegration} integrated
          </h1>
          <p className="text-gray-4">
            Your {selectedIntegration} has been successfully integrated on
            Crafter
          </p>
        </div>
        <div className="w-3/5 mt-5">
          <GlobalButton
            onClick={handleClose}
            variant={'blue'}
            state={'default'}
            size={'lg'}
          >
            Done
          </GlobalButton>
        </div>
      </div>
    </CustomModalOverlay2>
  );
}
