import React from 'react';

export default function SearchField({
  type,
  id,
  name,
  value,
  onChange,
  placeholder,
}) {
  return (
    <div className="flex  w-full items-center border-1 py-[10px] px-[14px] border-[#333] bg-transparent rounded-md ">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.66732 13.9999C11.1651 13.9999 14.0007 11.1644 14.0007 7.66659C14.0007 4.16878 11.1651 1.33325 7.66732 1.33325C4.16951 1.33325 1.33398 4.16878 1.33398 7.66659C1.33398 11.1644 4.16951 13.9999 7.66732 13.9999Z"
          stroke="#939393"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.6673 14.6666L13.334 13.3333"
          stroke="#939393"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <input
        type={type}
        id={id}
        name={name}
        placeholder={placeholder}
        className="placeholder:text-gray-6 placeholder:text-sm text-gray-1 lg:text-[16px] xl:text-[16px] 2xl:text-[18px] w-full bg-transparent rounded-md focus:outline-none pl-4"
        value={value}
        onChange={onChange}
      />
    </div>
  );
}
