import GlobalButton from '../../../components/ui/GlobalButton';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';

export const generateSteps = (val) => [
  {
    content: <Step1 />,
    title: '',
    locale: {
      skip: <strong aria-label="skip"></strong>,
      next: (
        <GlobalButton
          className={'w-48'}
          size={'lg'}
          state={'default'}
          variant={'blue'}
        >
          Continue
        </GlobalButton>
      ),
    },
    styles: {
      options: {
        arrowColor: '#000000',
        overlayColor: 'rgba(0,0,0,0.8)',
      },
      tooltip: {
        borderRadius: '15px',
        background: 'linear-gradient(83.98deg, #151414 2.84%, #161616 97.18%)',
        border: '0.5px solid #333333',
        width: '680px',
      },
      buttonClose: {
        color: '#181818',
        background: 'transparent',
        fontSize: '12px',
        fontWeight: 600,
      },
    },
    placement: 'center',
    target: '#insights-body',
  },
  {
    content: <Step2 />,
    title: '',
    locale: {
      skip: (
        <strong aria-label="skip" className="text-xs">
          Skip
        </strong>
      ),
      next: (
        <button className="text-[#0182AB] flex items-center gap-1">
          <span className="text-xs font-semibold">Next</span>
          <div className="w-4">
            <RightArrow />
          </div>
        </button>
      ),
    },
    styles: {
      options: {
        arrowColor: '#fff',
        overlayColor: 'rgba(0,0,0,0.8)',
      },
      tooltip: {
        borderRadius: '15px',
        width: '300px',
        padding: '5px',
      },
      buttonClose: {
        color: '#181818',
        background: 'transparent',
        fontSize: '12px',
        fontWeight: 600,
      },
      tooltipFooter: {
        padding: '0px 15px 10px 0px',
        margin: 0,
        width: 'fit-content',
        display: 'flex',
        marginLeft: 'auto',
      },
    },
    placement: 'bottom',
    target: '.project-dropdown',
  },
  {
    content: <Step3 />,
    title: '',
    locale: {
      skip: (
        <strong aria-label="skip" className="text-xs">
          Skip
        </strong>
      ),
      next: (
        <button className="text-[#0182AB] flex items-center gap-1">
          <span className="text-xs font-semibold">Next</span>
          <div className="w-4">
            <RightArrow />
          </div>
        </button>
      ),
    },
    styles: {
      options: {
        arrowColor: '#fff',
        overlayColor: 'rgba(0,0,0,0.8)',
      },
      tooltip: {
        borderRadius: '15px',
        width: '300px',
        padding: '5px',
      },
      buttonClose: {
        color: '#181818',
        background: 'transparent',
        fontSize: '12px',
        fontWeight: 600,
      },
      tooltipFooter: {
        padding: '0px 15px 10px 0px',
        margin: 0,
        width: 'fit-content',
        display: 'flex',
        marginLeft: 'auto',
      },
    },
    placement: 'bottom',
    target: '.epic-dropdown',
  },
  {
    content: <Step4 />,
    title: '',
    locale: {
      skip: (
        <strong aria-label="skip" className="text-xs">
          Skip
        </strong>
      ),
      next: (
        <button className="text-[#0182AB] flex items-center gap-1">
          <span className="text-xs font-semibold">Next </span>
          <div className="w-4">
            <RightArrow />
          </div>
        </button>
      ),
    },
    styles: {
      options: {
        arrowColor: '#fff',
        overlayColor: 'rgba(0,0,0,0.8)',
      },
      tooltip: {
        borderRadius: '15px',
        width: '250px',
        padding: '5px',
      },
      buttonClose: {
        color: '#181818',
        background: 'transparent',
        fontSize: '12px',
        fontWeight: 600,
      },
      tooltipFooter: {
        padding: '0px 15px 10px 0px',
        margin: 0,
        //   border: '1px solid black',
        width: 'fit-content',
        display: 'flex',
        marginLeft: 'auto',
      },
    },
    placement: 'bottom',
    target: '#last-sync',
  },
  {
    content: <Step5 />,
    title: '',
    locale: {
      skip: (
        <strong aria-label="skip" className="text-xs">
          Skip
        </strong>
      ),
      next: (
        <button className="text-[#0182AB] flex items-center gap-1">
          <span className="text-xs font-semibold">Next</span>
          <div className="w-4">
            <RightArrow />
          </div>
        </button>
      ),
    },
    styles: {
      options: {
        arrowColor: '#fff',
        overlayColor: 'rgba(0,0,0,0.8)',
      },
      tooltip: {
        borderRadius: '15px',
        width: '300px',
        padding: '5px',
      },
      buttonClose: {
        color: '#181818',
        background: 'transparent',
        fontSize: '12px',
        fontWeight: 600,
      },
      tooltipFooter: {
        padding: '0px 15px 10px 0px',
        margin: 0,
        width: 'fit-content',
        display: 'flex',
        marginLeft: 'auto',
      },
    },
    placement: 'bottom',
    target: '#chat-input',
  },
  {
    content: <Step6 />,
    title: '',
    locale: {
      skip: <strong aria-label="skip"></strong>,
      last: (
        <button className="text-[#0182AB] flex items-center gap-1">
          <span className="text-xs font-semibold">Get Started</span>
          <div className="w-4">
            <RightArrow />
          </div>
        </button>
      ),
    },
    styles: {
      options: {
        arrowColor: '#fff',
        overlayColor: 'rgba(0,0,0,0.8)',
      },
      tooltip: {
        borderRadius: '15px',
        width: '300px',
        padding: '5px',
      },
      buttonClose: {
        color: '#181818',
        background: 'transparent',
        fontSize: '12px',
        fontWeight: 600,
      },
      tooltipFooter: {
        padding: '0px 15px 10px 0px',
        margin: 0,
        width: 'fit-content',
        display: 'flex',
        marginLeft: 'auto',
      },
    },
    placement: 'top',
    target: '#insight-charts',
  },
];

const RightArrow = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0247 4.94165L17.083 9.99998L12.0247 15.0583"
        stroke="#0182AB"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.91675 10H16.9417"
        stroke="#0182AB"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
