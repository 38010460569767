import React, { useState } from 'react';
import DashboardWrapper from '@/components/ui/DashboardWrapper';
import GlobalButton from '@/components/ui/GlobalButton';
import UsedInputField from '@/components/ui/UsedInputField';
import Breadcrumb from '../../../components/common/Breadcrumb/Breadcrumb';
import useActionStore from '../../../store/Actions';

export default function AlreadyConfigured({ onCancel }) {
  const { setOpenAIActiveComponent } = useActionStore();

  const handleCancel = () => {
    onCancel();
  };

  const handleEditOpenAIConfig = () => {
    setOpenAIActiveComponent('OpenAIForm');
  };

  const breadcrumbItems = [
    { label: 'Integrations', link: '/integrations' },
    {
      label: `OpenAI`,
      link: `/integrations/OpenAI`,
      isActive: true,
    },
  ];
  return (
    <>
      <div className="h-screen">
        <DashboardWrapper>
          <div className="flex innerFrameBg flex-col gap-8 w-full h-full p-8 mt-8 border border-gray-2/30 rounded-md">
            <div
              id="already"
              className=" w-full h-full px-8 py-8 mx-auto rounded-xl bg1"
            >
              <div className="w-full">
                <Breadcrumb items={breadcrumbItems} />
              </div>
              <div className="w-1/2 flex flex-col items-start justify-start gap-10 mt-12">
                <div className="flex items-start w-full">
                  <div className="flex flex-col gap-2 items-start">
                    <h3 className="font-semibold text-[20px] text-white-1 text-left">
                      Your organization is already synced on Crafter
                    </h3>
                    <p className="text-[14px] text-gray-4 w-5/5 leading-[21px] text-left">
                      Your organization’s OpenAI account is already synced on
                      Crafter, you can sync your Crafter to the OpenAI account
                      by confirming the following details
                    </p>
                  </div>
                </div>
                <form className="w-full flex flex-col gap-4">
                  <div className="flex flex-col gap-8 text-gray-4">
                    <UsedInputField
                      label={'Your OpenAI integration key'}
                      value={'***************************'}
                    />
                  </div>

                  <div className="flex gap-2 w-full justify-start mt-6">
                    <div className="min-w-32">
                      <GlobalButton
                        variant={'blue'}
                        size={'md'}
                        state={'default'}
                        onClick={handleEditOpenAIConfig}
                      >
                        Update API key
                      </GlobalButton>
                    </div>
                    <div className="min-w-32">
                      <GlobalButton
                        variant={'stroke'}
                        size={'md'}
                        state={'default'}
                        onClick={handleCancel}
                      >
                        Cancel
                      </GlobalButton>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </DashboardWrapper>
      </div>
    </>
  );
}
