import React, { useEffect, useState } from 'react';
import JoyRide, { EVENTS, STATUS } from 'react-joyride';
import { generateSteps } from './Steps/steps';

const InsightsTourGuide = ({ start, setStartTour, onTourEnd }) => {
  const [progress, setProgress] = useState(1);
  const totalSteps = 5;

  const [{ run, steps }, setState] = useState({
    run: start,
    stepIndex: 0,
    steps: generateSteps(progress),
  });

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      steps: generateSteps(progress),
    }));
  }, [progress]);

  useEffect(() => {
    if (start) {
      setState((prevState) => ({
        ...prevState,
        run: true,
        stepIndex: 0,
      }));
    }
  }, [start]);

  const handleJoyrideCallback = (data) => {
    const { action, index, status } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setStartTour(false);
      onTourEnd();
    }

    if (action === EVENTS.STEP_AFTER) {
      setProgress(index + 1);
    }
  };
  return (
    <JoyRide
      continuous
      callback={handleJoyrideCallback}
      run={run}
      steps={steps}
      scrollToFirstStep
      hideCloseButton
      disableScrollParentFix
      spotlightPadding={0}
      disableCloseOnEsc
      disableOverlayClose
      disableScrolling={true}
      hideBackButton
      showSkipButton
      scrollDuration={10000}
      styles={{
        options: {
          zIndex: 100,
          arrowColor: '#0182AB',
          primaryColor: 'white',
          spotlightShadow: false,
          beaconSize: 30,
        },
        buttonNext: {
          background: 'transparent',
          padding: 0,
          border: 'none',
          outline: 'none',
        },

        spotlight: {
          transition: 'all 10s ease-in-out',
          padding: 0,
          margin: 0,
        },
        spotlightLegacy: {
          padding: 0,
          margin: 0,
        },
      }}
    />
  );
};

export default InsightsTourGuide;
