export const EpicIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.7"
        d="M12.9167 11.4583H11.1084C9.62508 11.4583 8.41675 10.2583 8.41675 8.76665V6.95831C8.41675 6.61665 8.14175 6.33331 7.79175 6.33331H5.15008C3.22508 6.33331 1.66675 7.58331 1.66675 9.81665V15.35C1.66675 17.5833 3.22508 18.8333 5.15008 18.8333H10.0584C11.9834 18.8333 13.5417 17.5833 13.5417 15.35V12.0833C13.5417 11.7333 13.2584 11.4583 12.9167 11.4583Z"
        fill="#CCE6EE"
      />
      <path
        d="M14.8499 2.16669H13.2082H12.2999H9.94155C8.05822 2.16669 6.53322 3.36669 6.46655 5.50835C6.51655 5.50835 6.55822 5.50002 6.60822 5.50002H8.96655H9.87489H11.5166C13.4416 5.50002 14.9999 6.75002 14.9999 8.98335V10.625V12.8834V14.525C14.9999 14.575 14.9916 14.6167 14.9916 14.6584C16.8499 14.6 18.3332 13.3667 18.3332 11.1917V9.55002V7.29169V5.65002C18.3332 3.41669 16.7749 2.16669 14.8499 2.16669Z"
        fill="#0182AB"
      />
      <path
        d="M9.98296 6.45831C9.72462 6.19998 9.28296 6.37498 9.28296 6.73331V8.91664C9.28296 9.83331 10.058 10.5833 11.008 10.5833C11.5996 10.5916 12.4246 10.5916 13.133 10.5916C13.4913 10.5916 13.6746 10.175 13.4246 9.92498C12.5163 9.01664 10.8996 7.39164 9.98296 6.45831Z"
        fill="#0182AB"
      />
    </svg>
  );
};

export const Eye = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.08 12C16.08 13.98 14.48 15.58 12.5 15.58C10.52 15.58 8.92004 13.98 8.92004 12C8.92004 10.02 10.52 8.42 12.5 8.42C14.48 8.42 16.08 10.02 16.08 12Z"
        stroke="#333333"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.5 20.27C16.03 20.27 19.32 18.19 21.61 14.59C22.51 13.18 22.51 10.81 21.61 9.4C19.32 5.8 16.03 3.72 12.5 3.72C8.96997 3.72 5.67997 5.8 3.38997 9.4C2.48997 10.81 2.48997 13.18 3.38997 14.59C5.67997 18.19 8.96997 20.27 12.5 20.27Z"
        stroke="#333333"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const BulbIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5001 7.89L11.4301 9.75C11.1901 10.16 11.3901 10.5 11.8601 10.5H13.1301C13.6101 10.5 13.8001 10.84 13.5601 11.25L12.5001 13.11"
        stroke="#333333"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.80035 18.0401V16.8801C6.50035 15.4901 4.61035 12.7801 4.61035 9.90005C4.61035 4.95005 9.16035 1.07005 14.3004 2.19005C16.5604 2.69005 18.5404 4.19005 19.5704 6.26005C21.6604 10.4601 19.4604 14.9201 16.2304 16.8701V18.0301C16.2304 18.3201 16.3404 18.9901 15.2704 18.9901H9.76035C8.66035 19.0001 8.80035 18.5701 8.80035 18.0401Z"
        stroke="#333333"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M9 22C11.29 21.35 13.71 21.35 16 22H9Z" fill="#333333" />
      <path
        d="M9 22C11.29 21.35 13.71 21.35 16 22"
        stroke="#333333"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
