import React from 'react';
import errorGif from '@/assets/404.gif';

export default function ErrorGif() {
  return (
    <img
      src={errorGif}
      alt="error"
      width={'100'}
      className="m-auto flex opacity-35"
    />
  );
}
