import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import {
  ErrorIcon,
  WarningIcon,
  DefaultIcon,
  SuccessIcon,
} from '../../icons/ToastIcons';

const Toast = ({ message, type, onClose }) => {
  const getToastType = () => {
    switch (type) {
      case 'success':
        return <SuccessIcon />;
      case 'error':
        return <ErrorIcon />;
      case 'warning':
        return <WarningIcon />;
      default:
        return <DefaultIcon />;
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, x: 200 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.3 }}
        exit={{ opacity: 0, x: 200 }}
        className="w-[350px] modalBg rounded-lg"
      >
        <div className="text-gray-4 flex items-center gap-5 w-[100%] z-50  text-base h-fit px-6 py-6 shadow-lg rounded-2xl">
          <div className="mb-auto">
            <span className="cursor-pointer">{getToastType()}</span>
          </div>
          <div className="text-sm">{message}</div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Toast;
