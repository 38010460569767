import React, { useState } from 'react';

export default function CollapseIcon({ collapsed, setCollapsed }) {
  const handleCollapse = (e) => {
    e.preventDefault();
    setCollapsed(!collapsed);
  };
  return (
    <>
      {collapsed ? (
        <svg
          className="cursor-pointer"
          onClick={handleCollapse}
          width="22"
          height="17"
          viewBox="0 0 22 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.11523 13.7707L10.4619 9.42403C10.9752 8.91069 10.9752 8.07069 10.4619 7.55736L6.11523 3.21069"
            stroke="#939393"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.1152 13.7707L15.4619 9.42403C15.9752 8.91069 15.9752 8.07069 15.4619 7.55736L11.1152 3.21069"
            stroke="#939393"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ) : (
        <svg
          className="cursor-pointer"
          onClick={handleCollapse}
          width="22"
          height="17"
          viewBox="0 0 22 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.175 13.7707L5.82836 9.42403C5.31503 8.91069 5.31503 8.07069 5.82836 7.55736L10.175 3.21069"
            stroke="#939393"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M15.175 13.7707L10.8284 9.42403C10.315 8.91069 10.315 8.07069 10.8284 7.55736L15.175 3.21069"
            stroke="#939393"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      )}
    </>
  );
}
