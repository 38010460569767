import React from 'react';

export default function PdfIcon2() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.4125 0C2.3625 0 1.5 0.8625 1.5 1.9125V22.0875C1.5 23.1375 2.3625 24 3.4125 24H20.5875C21.6375 24 22.5 23.1375 22.5 22.0875V7.6125L15.4125 0H3.4125Z"
        fill="#FB3449"
      />
      <path
        d="M22.5 7.65001V8.02501H17.7C17.7 8.02501 15.3375 7.53751 15.4125 5.51251C15.4125 5.51251 15.4875 7.65001 17.6625 7.65001H22.5Z"
        fill="#F5051E"
      />
      <path
        opacity="0.5"
        d="M15.4125 0V5.475C15.4125 6.1125 15.825 7.65 17.7 7.65H22.5L15.4125 0Z"
        fill="white"
      />
      <path
        d="M7.08748 18.375H5.84998V19.9125C5.84998 20.0625 5.73748 20.175 5.54998 20.175C5.39998 20.175 5.28748 20.0625 5.28748 19.9125V16.0875C5.28748 15.8625 5.47498 15.675 5.69998 15.675H7.08748C7.98748 15.675 8.51248 16.3125 8.51248 17.025C8.51248 17.775 7.98748 18.375 7.08748 18.375ZM7.04998 16.1625H5.84998V17.8875H7.04998C7.57498 17.8875 7.94998 17.55 7.94998 17.025C7.94998 16.5 7.57498 16.1625 7.04998 16.1625ZM10.95 20.175H9.82498C9.59998 20.175 9.41248 19.9875 9.41248 19.7625V16.0875C9.41248 15.8625 9.59998 15.675 9.82498 15.675H10.95C12.3375 15.675 13.275 16.65 13.275 17.925C13.275 19.2 12.375 20.175 10.95 20.175ZM10.95 16.1625H9.97498V19.65H10.95C12.0375 19.65 12.675 18.8625 12.675 17.8875C12.7125 16.95 12.075 16.1625 10.95 16.1625ZM17.0625 16.1625H14.8875V17.625H17.025C17.175 17.625 17.25 17.7375 17.25 17.8875C17.25 18.0375 17.1375 18.1125 17.025 18.1125H14.8875V19.9125C14.8875 20.0625 14.775 20.175 14.5875 20.175C14.4375 20.175 14.325 20.0625 14.325 19.9125V16.0875C14.325 15.8625 14.5125 15.675 14.7375 15.675H17.0625C17.2125 15.675 17.2875 15.7875 17.2875 15.9375C17.325 16.05 17.2125 16.1625 17.0625 16.1625Z"
        fill="white"
      />
    </svg>
  );
}
