import React from 'react';

export default function Integrations({ active }) {
  return (
    <>
      {active ? (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.666 11.875C17.7015 11.875 18.541 11.0355 18.541 10C18.541 8.96447 17.7015 8.125 16.666 8.125C15.6305 8.125 14.791 8.96447 14.791 10C14.791 11.0355 15.6305 11.875 16.666 11.875Z"
            fill="#F7A152"
          />
          <path
            d="M16.666 5.20825C17.7015 5.20825 18.541 4.36879 18.541 3.33325C18.541 2.29772 17.7015 1.45825 16.666 1.45825C15.6305 1.45825 14.791 2.29772 14.791 3.33325C14.791 4.36879 15.6305 5.20825 16.666 5.20825Z"
            fill="#0182AB"
          />
          <path
            d="M16.666 18.5417C17.7015 18.5417 18.541 17.7023 18.541 16.6667C18.541 15.6312 17.7015 14.7917 16.666 14.7917C15.6305 14.7917 14.791 15.6312 14.791 16.6667C14.791 17.7023 15.6305 18.5417 16.666 18.5417Z"
            fill="#A1C181"
          />
          <path
            d="M3.33398 11.875C4.36952 11.875 5.20898 11.0355 5.20898 10C5.20898 8.96447 4.36952 8.125 3.33398 8.125C2.29845 8.125 1.45898 8.96447 1.45898 10C1.45898 11.0355 2.29845 11.875 3.33398 11.875Z"
            fill="#F0EEEE"
          />
          <path
            d="M15.8327 10.6249C16.1743 10.6249 16.4577 10.3416 16.4577 9.99992C16.4577 9.65825 16.1743 9.37492 15.8327 9.37492H9.79102V5.83325C9.79102 4.51659 10.3493 3.95825 11.666 3.95825H15.8327C16.1743 3.95825 16.4577 3.67492 16.4577 3.33325C16.4577 2.99159 16.1743 2.70825 15.8327 2.70825H11.666C9.64935 2.70825 8.54102 3.81659 8.54102 5.83325V9.37492H4.16602C3.82435 9.37492 3.54102 9.65825 3.54102 9.99992C3.54102 10.3416 3.82435 10.6249 4.16602 10.6249H8.54102V14.1666C8.54102 16.1833 9.64935 17.2916 11.666 17.2916H15.8327C16.1743 17.2916 16.4577 17.0083 16.4577 16.6666C16.4577 16.3249 16.1743 16.0416 15.8327 16.0416H11.666C10.3493 16.0416 9.79102 15.4833 9.79102 14.1666V10.6249H15.8327Z"
            fill="#D4D4D4"
          />
        </svg>
      ) : (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.6667 11.6666C17.5871 11.6666 18.3333 10.9204 18.3333 9.99992C18.3333 9.07944 17.5871 8.33325 16.6667 8.33325C15.7462 8.33325 15 9.07944 15 9.99992C15 10.9204 15.7462 11.6666 16.6667 11.6666Z"
            stroke="#333333"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16.6667 5.00008C17.5871 5.00008 18.3333 4.25389 18.3333 3.33341C18.3333 2.41294 17.5871 1.66675 16.6667 1.66675C15.7462 1.66675 15 2.41294 15 3.33341C15 4.25389 15.7462 5.00008 16.6667 5.00008Z"
            stroke="#333333"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16.6667 18.3333C17.5871 18.3333 18.3333 17.5871 18.3333 16.6667C18.3333 15.7462 17.5871 15 16.6667 15C15.7462 15 15 15.7462 15 16.6667C15 17.5871 15.7462 18.3333 16.6667 18.3333Z"
            stroke="#333333"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M3.33268 11.6666C4.25316 11.6666 4.99935 10.9204 4.99935 9.99992C4.99935 9.07944 4.25316 8.33325 3.33268 8.33325C2.41221 8.33325 1.66602 9.07944 1.66602 9.99992C1.66602 10.9204 2.41221 11.6666 3.33268 11.6666Z"
            stroke="#333333"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5 10H15"
            stroke="#737373"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M14.9993 3.33325H11.666C9.99935 3.33325 9.16602 4.16659 9.16602 5.83325V14.1666C9.16602 15.8333 9.99935 16.6666 11.666 16.6666H14.9993"
            stroke="#737373"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      )}
    </>
  );
}
