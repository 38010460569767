import React from 'react';
import styles from '../ChatsSection.module.css';

export const ChatSend = ({ content, timestamp }) => {
  return (
    <div className={`flex flex-col items-end gap-1 mb-5 mt-3`}>
      <div
        className={`px-4 py-2 rounded-md max-w-[80%] text-base text-gray-4 leading-6 ${styles.send_chat_bg}`}
      >
        {content}
      </div>
    </div>
  );
};
