import WebAppAPI from '@/utils/WebAppAPI';

const WebAppService = {
  signUp: async (payload) => {
    try {
      const response = await WebAppAPI().post('/auth', payload);

      return response.data;
    } catch (error) {
      // throw error;
    }
  },
  getOTP: async (payload) => {
    return await WebAppAPI().post('/otp', payload);
  },
  verifyAndLogin: async (payload) => {
    try {
      const response = await WebAppAPI().post('/otp/validate', payload);

      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getAllProjects: async ({ queryKey }) => {
    const [, { page, searchQuery, limit }] = queryKey;
    try {
      const response = await WebAppAPI().get('/tasks', {
        params: {
          page: page || 1,
          search: searchQuery || '',
          limit: limit || 10,
        },
      });
      return response.data.data;
    } catch (error) {
      console.error('Error fetching projects:', error);
      throw error;
    }
  },
  getAllTasks: async () => {
    try {
      const response = await WebAppAPI().get('/tasks');
      return response.data.data;
    } catch (error) {
      console.error('Error fetching projects:', error);
      throw error;
    }
  },

  getProjectDetails: async (project_id) => {
    try {
      const response = await WebAppAPI().get(`/tasks/${project_id}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching project details:', error);
      throw error;
    }
  },

  getOrganizationById: async (organizationId) => {
    try {
      const response = await WebAppAPI().get(
        `/organizations/${organizationId}`,
        {
          params: {
            id: organizationId,
          },
        }
      );
      // console.log(response?.data?.data);
      return response?.data?.data;
    } catch (error) {
      throw error;
    }
  },
  createTicket: async (formData, organizationId) => {
    try {
      const response = await WebAppAPI().post(
        `/organizations/${organizationId}/file`,
        formData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getOrganizationJiraKey: async (organizationId) => {
    try {
      const response = await WebAppAPI().get(
        `/organizations/${organizationId}/projects?id=${organizationId}`
      );
      // console.log(response?.data);
      return response.data;
    } catch (error) {
      // console.log("Error getting organization's Jira Projects", error);
      throw error;
    }
  },

  sendProjectToJira: async (projectId, project_key) => {
    try {
      const response = await WebAppAPI().post(
        `/tasks/${projectId}`,
        project_key
      );
      // console.log(response?.data);
      return response.data;
    } catch (error) {
      // console.error("Error sending project", error);
      throw error;
    }
  },
  deleteProjectById: async (projectId, payload) => {
    try {
      const response = await WebAppAPI().delete(`/tasks/${projectId}`);
      // console.log(response?.data);
      return response.data;
    } catch (error) {
      // console.error("Error sending project", error);
      throw error;
    }
  },

  configureJira: async (organizationId, formData) => {
    try {
      const response = await WebAppAPI().put(
        `/organizations/${organizationId}`,
        formData
      );
      return response.data;
    } catch (error) {
      // console.log("Error configuring Jira", error);
      throw error;
    }
  },
  saveEditedProject: async (projectId, updatedProject) => {
    try {
      const response = await WebAppAPI().put(
        `/tasks/${projectId}`,
        updatedProject
      );
      return response.data;
    } catch (error) {
      console.error('Error saving project', error);
      throw error;
    }
  },

  updateFlag: async (userId) => {
    try {
      const response = await WebAppAPI().patch(`users/${userId}/update-flag`);
      return response.data;
    } catch (error) {
      console.error(`Failed to update flag`, error);
      throw error;
    }
  },

  getAllPlans: async () => {
    try {
      const response = await WebAppAPI().get(`/subscription-plans`);
      return response.data;
    } catch (error) {
      console.error('Failed to get plans');
      throw error;
    }
  },
  createCheckoutSession: async (planId, userId) => {
    try {
      const response = await WebAppAPI().post(
        `/payments/create-checkout-session`,
        planId,
        userId
      );
      return response.data;
    } catch (error) {
      console.error('Failed to create checkout session');
      throw error;
    }
  },

  getCurrentSubscription: async () => {
    try {
      const response = await WebAppAPI().get(`/subscriptions/current`);
      return response.data;
    } catch (error) {
      console.error('Failed to get subscription plan');
      throw error;
    }
  },

  getOrganizationJiraProjects: async (organizationId) => {
    try {
      const response = await WebAppAPI().get(
        `/organizations/${organizationId}/projects?id=${organizationId}`
      );
      // console.log(response?.data);
      return response.data;
    } catch (error) {
      // console.log("Error getting organization's Jira Projects", error);
      throw error;
    }
  },

  getJiraProjectEpics: async (project_key) => {
    try {
      const response = await WebAppAPI().get(`/reports/${project_key}/epics`);
      // console.log(response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getChildrenOfAnEpic: async (projectKey, epicId) => {
    try {
      const response = await WebAppAPI().get(
        `/reports/${projectKey}/epic/${epicId}`
      );
      // console.log(response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  configureOpenAI: async (organizationId, formData) => {
    try {
      const response = await WebAppAPI().put(
        `/organizations/${organizationId}/openai-key`,
        formData
      );
      return response.data;
    } catch (error) {
      // console.log("Error configuring Jira", error);
      throw error;
    }
  },
  deleteOpenAI: async (organizationId) => {
    try {
      const response = await WebAppAPI().delete(
        `/organizations/${organizationId}/openai-key`
      );
      return response.data;
    } catch (error) {
      // console.log("Error configuring Jira", error);
      throw error;
    }
  },
};

export default WebAppService;
