import React from 'react';

export default function Step5() {
  return (
    <div className="flex flex-col items-start p-0">
      <span className="font-semibold text-[12px]">4 of 5</span>

      <p className="mt-3 text-[12px] text-left">
        Use Crafter to get more insights on your project, learn how to improve
        your project success by chatting with Crafter.
      </p>
    </div>
  );
}
