import React from 'react';
import ghostGif from '@/assets/ghost.gif';

export default function Empty({ message, text }) {
  return (
    <div className="flex h-fit flex-col items-center justify-center m-auto ">
      <div>
        <img
          className="opacity-[0.05]"
          width={`150`}
          src={ghostGif}
          alt="ghostGif"
        />
      </div>
      <div className="mt-[-20px] mx-auto">
        <h1 className="font-bold text-xl text-center">{message}</h1>
        <p className="text-gray-3 text-center">{text}</p>
      </div>
    </div>
  );
}
