import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Heading from '@/components/Heading';
import Sidebar from '@/components/Sidebar';
import Body from './Body';
import { Helmet } from 'react-helmet';
import { PlansProvider, usePlans } from '../../context/PlansContext';
import useAuthStore from '../../store/Auth';
import WebAppService from '../../services/WebAppService';
import ErrorToast from '../../components/modals/ErrorToast';
import SuccessToast from '../../components/modals/SuccessToast';
import Plan from './Plan';
import Billing from './Billing';
import { useToast } from '../../context/ToastContext';
import LoadingComponent from '../../components/LoadingComponent';

export default function Plans() {
  const [collapsed, setCollapsed] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const {
    plans,
    loading,
    isSubscribed,
    subscription,
    hasSubscriptionEnded,
    subscriptionLoading,
  } = usePlans();
  const [activeComponent, setActiveComponent] = useState('LOADING');
  const [buttonState, setButtonState] = useState('default');
  const location = useLocation();
  const authStore = useAuthStore();
  const userId = authStore.user.id;
  const { refetch: refetchSubscription } = usePlans();
  const { addToast } = useToast();

  useEffect(() => {
    if (loading) {
      setActiveComponent('LOADING');
    } else if (isSubscribed && !hasSubscriptionEnded) {
      setActiveComponent('BILLING');
    } else if (isSubscribed && hasSubscriptionEnded) {
      setActiveComponent('PLAN');
    } else {
      setActiveComponent('PLAN');
    }
  }, [loading, isSubscribed, hasSubscriptionEnded]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get('status');
    const sessionId = queryParams.get('sessionId');

    if (status === 'success' && sessionId) {
      refetchSubscription();
      addToast('Payment successful', 'success', 3000);
      // setShowSuccessToast(true);
      // setTimeout(() => {
      // 	setShowSuccessToast(false);
      // }, 3000);
    } else if (status === 'cancel' || status === 'failure') {
      addToast('Payment unsuccessful', 'error', 3000);
      // setShowErrorToast(true);
      // setTimeout(() => {
      // 	setShowErrorToast(false);
      // }, 3000);
    } else {
      // addToast("Payment unsuccessful", "error", 3000);
      // console.error("Payment unsuccessful", status, sessionId);
    }
  }, [location, refetchSubscription]);

  const handleCheckout = async (planId) => {
    setButtonState('inactive');
    try {
      const response = await WebAppService.createCheckoutSession({
        planId,
        userId,
      });

      if (response && response.redirect) {
        window.location.href = response.redirect;
      } else {
        addToast('Failed to create checkout session', 'error', 3000);
        console.error('Failed to create checkout session:', response);
        setButtonState('default');
      }
    } catch (error) {
      addToast('Failed to create checkout session', 'error', 3000);
      console.error('Error during checkout:', error);
      setButtonState('default');
    }
  };

  return (
    <main className="relative innerFrameBg text-gray-4 h-screen w-full flex flex-col">
      <Helmet>
        <title>Plans | Crafter</title>
      </Helmet>

      <div className="flex w-full h-full">
        <div
          className={`w-full ${
            collapsed ? 'w-[95%]' : 'w-[80%]'
          } overflow-scroll h-full`}
        >
          <div className="w-full h-full pt-[65px] pb-[45px] px-4 text-white-1">
            {activeComponent === 'BILLING' && (
              <Billing
                hasSubscriptionEnded={hasSubscriptionEnded}
                subscription={subscription}
                loading={subscriptionLoading}
              />
            )}
            {activeComponent === 'PLAN' && (
              <Plan
                plans={plans}
                onCheckout={handleCheckout}
                buttonState={buttonState}
              />
            )}

            {activeComponent === 'LOADING' && (
              <div className="flex border flex-col gap-8 w-full h-full p-8 mt-8 border-gray-4 rounded-md overflow-scroll innerFrameBg">
                <LoadingComponent />
              </div>
            )}
          </div>
        </div>
      </div>

      {showSuccessToast && <SuccessToast message={'Payment successful'} />}
      {showErrorToast && (
        <ErrorToast message={'Payment failed! Please try again'} />
      )}
    </main>
  );
}
