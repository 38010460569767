import React, { useState } from 'react';
import CustomModalOverlay2 from '../../../common/Custom/CustomModalOverlay2/CustomModalOverlay2';
import useActionStore from '../../../../store/Actions';
import GlobalButton from '../../../ui/GlobalButton';

export default function UpdateAPIKey() {
  //   const authStore = useAuthStore();
  const { toggleUpdateAPIKeyModal, updateAPIKeyModal } = useActionStore();
  const [updating, setUpdating] = useState(false);

  const handleClose = () => {
    toggleUpdateAPIKeyModal(false);
  };

  return (
    <CustomModalOverlay2
      open={updateAPIKeyModal}
      onClose={handleClose}
      width="650px"
      radius="12px"
      withCloseButton={false}
    >
      <div className="flex flex-col gap-8">
        <div className="w-full flex flex-col gap-2 items-start">
          <h1 className="font-semibold text-[20px] text-white-1">
            Update API key?
          </h1>
          <p className="text-gray-4">
            Updating the API key will automatically update the previous API key
            integrated
          </p>
        </div>
        <div className="w-3/4 flex grow">
          <GlobalButton
            // onClick={() => handleDeleteIntegration(selectedIntegration)}

            variant={'blue'}
            state={updating ? 'disabled' : 'default'}
            size={'lg'}
            className={`text-error-2`}
            disabled={updating}
          >
            {updating ? 'Update API Key' : 'Updating'}
          </GlobalButton>
          <GlobalButton
            variant={'redStroke'}
            onClick={handleClose}
            state={'default'}
            size={'lg'}
          >
            Cancel
          </GlobalButton>
        </div>
      </div>
    </CustomModalOverlay2>
  );
}
