import React, { createContext, useState, useEffect, useContext } from 'react';
import WebAppService from '../services/WebAppService';
import { useQuery, useQueryClient } from 'react-query';
import useAuthStore from '../store/Auth';
import { useToast } from './ToastContext';

const PlansContext = createContext();

export const PlansProvider = ({ children }) => {
  const { user, isAuthenticated } = useAuthStore();
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [subscription, setSubscription] = useState({});
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [hasSubscriptionEnded, setHasSubscriptionEnded] = useState(false);
  const [planExpiry, setPlanExpiry] = useState('');
  const queryClient = useQueryClient();
  const { addToast } = useToast();

  const { data: plansData, isLoading: plansLoading } = useQuery(
    ['plans', user?.id], // Add user.id as a dependency
    WebAppService.getAllPlans,
    {
      enabled: isAuthenticated, // Only fetch if the user is authenticated
      onSuccess: (data) => {
        // console.log("Fetched plans data:", data);
      },
      onError: (error) => {
        console.error('Error fetching plans:', error);
        addToast(
          `${error?.response?.data?.error || 'Error fetching plans'}`,
          'error',
          3000
        );
      },
    }
  );

  const { data: subscriptionData, isLoading: subscriptionLoading } = useQuery(
    ['subscription', user?.id], // Add user.id as a dependency
    WebAppService.getCurrentSubscription,
    {
      enabled: isAuthenticated, // Only fetch if the user is authenticated
      onSuccess: (data) => {
        // console.log("Fetched subscription data:", data);
      },
      onError: (error) => {
        console.error('Error fetching subscription:', error);
        addToast(
          `${error?.response?.data?.error || 'Error fetching subscription'}`,
          'error',
          3000
        );
      },
    }
  );

  useEffect(() => {
    setLoading(plansLoading || subscriptionLoading);

    if (plansData) {
      setPlans(plansData?.data);
    }

    if (subscriptionData?.data !== null) {
      setSubscription(subscriptionData?.data);
      setIsSubscribed(true);
      setPlanExpiry(subscriptionData?.data?.expiryDate);
    } else {
      setIsSubscribed(false);
      setPlanExpiry('');
    }
  }, [plansData, subscriptionData, plansLoading, subscriptionLoading]);

  const refetchSubscription = () => {
    queryClient.invalidateQueries(['subscription', user?.id]);
  };

  useEffect(() => {
    if (isSubscribed && planExpiry !== '') {
      const currentDate = new Date();
      const expiryDate = new Date(planExpiry);

      if (currentDate > expiryDate) {
        setHasSubscriptionEnded(true);
      } else {
        setHasSubscriptionEnded(false);
      }
    }
  }, [isSubscribed, planExpiry]);

  return (
    <PlansContext.Provider
      value={{
        plans,
        loading,
        subscription,
        isSubscribed,
        hasSubscriptionEnded,
        refetchSubscription,
        subscriptionLoading,
      }}
    >
      {children}
    </PlansContext.Provider>
  );
};

// Custom hook to use the PlansContext
export const usePlans = () => {
  return useContext(PlansContext);
};
