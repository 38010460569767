import React from 'react';

export default function TxtIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_209_4915)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.41702 0.00939941C2.36627 0.00939941 1.51465 0.861399 1.51465 1.91215V22.1025C1.51465 23.1536 2.36627 24.0053 3.41702 24.0053H20.5823C21.6326 24.0053 22.485 23.1536 22.485 22.1025V7.62565L15.3968 0.00939941H3.41702Z"
          fill="#8199AF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22.4853 7.64135V8.01635H17.6857C17.6857 8.01635 15.3187 7.54385 15.3873 5.50085C15.3873 5.50085 15.4653 7.64135 17.6388 7.64135H22.4853Z"
          fill="#617F9B"
        />
        <path
          opacity="0.5"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.3971 0.00939941V5.46977C15.3971 6.09077 15.8111 7.64177 17.6861 7.64177H22.4857L15.3971 0.00939941Z"
          fill="white"
        />
        <path
          d="M6.678 20.32C6.5565 20.32 6.458 20.2215 6.458 20.1V16.742C6.458 16.6205 6.3595 16.522 6.238 16.522H5.22C5.0985 16.522 5 16.4235 5 16.302V16.22C5 16.0985 5.0985 16 5.22 16H8.332C8.4535 16 8.552 16.0985 8.552 16.22V16.302C8.552 16.4235 8.4535 16.522 8.332 16.522H7.314C7.1925 16.522 7.094 16.6205 7.094 16.742V20.1C7.094 20.2215 6.9955 20.32 6.874 20.32H6.678Z"
          fill="white"
        />
        <path
          d="M12.0441 20.32C11.9708 20.32 11.9024 20.2836 11.8615 20.2228L10.5949 18.34L9.21391 16.3452C9.11289 16.1993 9.21733 16 9.39479 16H9.60179C9.675 16 9.74342 16.0364 9.7843 16.0972L10.9789 17.872L12.4324 19.9749C12.5332 20.1208 12.4288 20.32 12.2514 20.32H12.0441ZM10.7433 17.9972C10.7038 17.9241 10.7088 17.835 10.7563 17.7669L11.9213 16.0943C11.9624 16.0352 12.0298 16 12.1018 16H12.244C12.4228 16 12.527 16.202 12.4232 16.3477L11.1658 18.1124C11.0713 18.2451 10.8705 18.2326 10.7931 18.0893L10.7433 17.9972ZM10.8144 18.3058C10.8528 18.3784 10.8475 18.4664 10.8007 18.5339L9.62853 20.2253C9.58743 20.2846 9.51986 20.32 9.44771 20.32H9.30567C9.12687 20.32 9.02275 20.118 9.12647 19.9724L10.394 18.1926C10.4889 18.0592 10.6911 18.0726 10.7677 18.2174L10.8144 18.3058Z"
          fill="white"
        />
        <path
          d="M14.7288 20.32C14.6073 20.32 14.5088 20.2215 14.5088 20.1V16.742C14.5088 16.6205 14.4103 16.522 14.2888 16.522H13.2708C13.1493 16.522 13.0508 16.4235 13.0508 16.302V16.22C13.0508 16.0985 13.1493 16 13.2708 16H16.3828C16.5043 16 16.6028 16.0985 16.6028 16.22V16.302C16.6028 16.4235 16.5043 16.522 16.3828 16.522H15.3648C15.2433 16.522 15.1448 16.6205 15.1448 16.742V20.1C15.1448 20.2215 15.0463 20.32 14.9248 20.32H14.7288Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_209_4915">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
