import React, { useState } from 'react';
import GlobalButton from '@/components/ui/GlobalButton';
import { Link } from 'react-router-dom';
import useAuthStore from '@/store/Auth';
import PrivacyModal from '@/components/modals/PrivacyModal';
import TermsModal from '@/components/modals/TermsModal';
import DataPrivacyModal from '@/components/modals/DataPrivacyModal';
import Breadcrumb from '../../../components/common/Breadcrumb/Breadcrumb';
import { JiraIcon2 } from '../../../svgs';

const AccessRequest = ({ integration, onCancel, onContinue }) => {
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showDataModal, setShowDataModal] = useState(false);

  const openPrivacyModal = () => {
    setShowPrivacyModal(true);
  };
  const closePrivacyModal = () => {
    setShowPrivacyModal(false);
  };

  const openTermsModal = () => {
    setShowTermsModal(true);
  };
  const closeTermsModal = () => {
    setShowTermsModal(false);
  };
  const openDataModal = () => {
    setShowDataModal(true);
  };
  const closeDataModal = () => {
    setShowDataModal(false);
  };

  const breadcrumbItems = [
    { label: 'Integrations', link: '/integrations' },
    {
      label: `Jira`,
      link: `/integrations/Jira`,
      isActive: true,
    },
  ];

  return (
    <>
      <div className="w-full h-screen pt-[60px] pb-[45px] px-4 lg:text-[16px] xl:text-[16px] 2xl:text-[18px] text-white-1">
        <div className="flex innerFrameBg flex-col gap-8 w-full h-full p-8 mt-8 border border-gray-2/30 rounded-md">
          <div className="w-full border innerFrameBg">
            <Breadcrumb items={breadcrumbItems} />
          </div>
          <div className="flex flex-col gap-10 w-full h-full p-8 mx-auto  rounded-xl bg1">
            <div className="flex items-center justify-start gap-3 w-full ">
              <div className="w-[50px]">
                <JiraIcon2 />
              </div>
              <div className="flex flex-col items-start">
                <h3 className="font-semibold text-[20px] text-left">
                  Integrate with Jira
                </h3>
                <p className="text-gray-4 text-left">
                  Integrate your Crafter account with Jira and enjoy a seamless
                  experience
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-4 text-gray-4">
              <p>In Jira, Crafter will like to</p>
              <ul className="flex flex-col gap-3 list-disc list-inside">
                <li>Update your projects in your organization account</li>
                <li>
                  Update your new tickets / issues in your organization projects
                </li>
              </ul>
            </div>
            <div className="flex flex-col gap-4 text-gray-4">
              <p>By accepting this app</p>
              <ul className="flex flex-col gap-3 list-disc list-inside">
                <li>
                  You grant Crafter access to your organization data which is
                  protected by our{' '}
                  <span
                    onClick={openDataModal}
                    className="cursor-pointer text-blue-2 underline underline-offset-4"
                  >
                    Data protection agreement
                  </span>{' '}
                </li>
                <li>
                  You agree to the{' '}
                  <span
                    onClick={openPrivacyModal}
                    className="cursor-pointer text-blue-2 underline underline-offset-4"
                  >
                    privacy policy
                  </span>{' '}
                  and{' '}
                  <span
                    onClick={openTermsModal}
                    className="cursor-pointer text-blue-2 underline underline-offset-4"
                  >
                    terms of use
                  </span>
                </li>
              </ul>
            </div>
            <div className="flex  w-full justify-start">
              <div className="flex gap-2">
                <div className="min-w-48">
                  <GlobalButton
                    variant={'blue'}
                    size={'md'}
                    state={'default'}
                    onClick={onContinue}
                  >
                    Integrate with Jira
                  </GlobalButton>
                </div>
                <div className="min-w-48">
                  <GlobalButton
                    variant={'stroke'}
                    size={'md'}
                    state={'default'}
                    onClick={onCancel}
                  >
                    Cancel
                  </GlobalButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showPrivacyModal && <PrivacyModal onClose={closePrivacyModal} />}
      {showTermsModal && <TermsModal onClose={closeTermsModal} />}
      {showDataModal && <DataPrivacyModal onClose={closeDataModal} />}
    </>
  );
};

export default AccessRequest;
