import React, { useState } from 'react';
import GlobalButton from '@/components/ui/GlobalButton';
import UsedInputField from '@/components/ui/UsedInputField';
import useAuthStore from '@/store/Auth';
import JiraIntegrationForm from './JiraIntegrationForm';
import DashboardWrapper from '@/components/ui/DashboardWrapper';
import Breadcrumb from '../../../components/common/Breadcrumb/Breadcrumb';
import useActionStore from '../../../store/Actions';

export default function AlreadyConfigured({ onCancel }) {
  const authStore = useAuthStore();
  const organizationDetails = authStore.organization;
  const { setJiraActiveComponent } = useActionStore();

  const handleCancel = () => {
    onCancel();
  };

  const handleEditJiraConfig = () => {
    setJiraActiveComponent('JiraIntegrationForm');
  };

  const breadcrumbItems = [
    { label: 'Integrations', link: '/integrations' },
    {
      label: `Jira`,
      link: `/integrations/Jira`,
      isActive: true,
    },
  ];

  return (
    <>
      <div className="h-screen">
        <DashboardWrapper>
          <div className="flex innerFrameBg flex-col gap-8 w-full h-full p-8 mt-8 border border-gray-2/30 rounded-md">
            <div
              id="already"
              className=" w-full h-full px-8 py-8 mx-auto rounded-xl bg1"
            >
              <div className="w-full">
                <Breadcrumb items={breadcrumbItems} />
              </div>
              <div className="w-fit mx-auto flex flex-col items-center justify-center gap-10 mt-12">
                <div className="flex flex-col gap-2 items-start">
                  <h3 className="font-semibold text-[20px] text-white-1 text-left">
                    Your organization is already synced on Crafter
                  </h3>
                  <p className="text-[16px] text-gray-4 text-left w-4/5 leading-[26px]">
                    Your organization&apos;s Jira account is already synced on
                    Crafter, you can sync your Crafter to the Jira account by
                    confirming the following details
                  </p>
                </div>
                <form className="w-full flex flex-col gap-4">
                  <div className="flex flex-col gap-8 text-gray-4">
                    <UsedInputField
                      label={'Organization Name'}
                      value={organizationDetails.name}
                    />
                    <UsedInputField
                      label={"Organization's Jira Admin Email"}
                      value={organizationDetails.jira_admin_email}
                    />
                    <UsedInputField
                      label={`Organization’s Jira URL`}
                      value={organizationDetails.jira_url}
                    />
                    <UsedInputField
                      label={'Organization’s Jira API key'}
                      value={'***************************'}
                    />
                  </div>

                  <div className="flex gap-2 w-full justify-start mt-6">
                    <div className="min-w-32">
                      <GlobalButton
                        variant={'blue'}
                        size={'md'}
                        state={'default'}
                        onClick={handleEditJiraConfig}
                      >
                        Edit Jira Config
                      </GlobalButton>
                    </div>
                    <div className="min-w-32">
                      <GlobalButton
                        variant={'stroke'}
                        size={'md'}
                        state={'default'}
                        onClick={handleCancel}
                      >
                        Back
                      </GlobalButton>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </DashboardWrapper>
      </div>
    </>
  );
}
