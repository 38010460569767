import React, { useState, useEffect } from 'react';
import FileIcon2 from '@/icons/FileIcon2';
import InputField from '@/components/ui/InputField';
import InputField2 from '@/components/ui/InputField2';
import InputField3 from '@/components/ui/InputField3';
import GlobalButton from '@/components/ui/GlobalButton';
import GlobalCheckbox from '@/components/ui/GlobalCheckbox';
import InfoCircle from '@/icons/InfoCircle';
import CloseIcon from '@/icons/CloseIcon';
import WebAppService from '@/services/WebAppService';
import Clipboard from '@/components/ui/Clipboard';
import StatusModal from './StatusModal';
import useAuthStore from '@/store/Auth';
import SuccessToast from '@/components/modals/SuccessToast';
import ErrorToast from '@/components/modals/ErrorToast';
import { useProjects } from '../../context/ProjectsContext';
import UploadDocInput from '../ui/UploadDocInputs/UploadDocInput';
import UploadDocTextArea from '../ui/UploadDocInputs/UploadDocTextArea';

export default function GoogleDocModal({
  isOpen,
  onClose,
  onCreateTickets,
  sentProjects,
  setSentProjects,
  setCreateTicketsResponse,
}) {
  const { refetchProjects } = useProjects();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [successToast, setSuccessToast] = useState(false);
  const [errorToast, setErrorToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSection, setShowSection] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    description: '',
    googleDocLink: '',
    document_id: '',
    acceptance_criteria: false,
    test_cases: false,
  });

  const authStore = useAuthStore();
  const organizationId = authStore.user.organization;

  // const isGoogleDocsLink = (link) => {
  // 	return link.startsWith("https://docs.google.com/");
  // };

  const extractGoogleDocId = (url) => {
    if (!url) return null;

    const match = url.match(/\/document\/d\/([a-zA-Z0-9-_]+)\/edit/);
    return match ? match[1] : null;
  };

  useEffect(() => {
    if (formData.name && formData.googleDocLink) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [formData.name, formData.description, formData.googleDocLink]);

  useEffect(() => {
    if (formData.googleDocLink) {
      const googleDocId = extractGoogleDocId(formData.googleDocLink);
      setFormData((prevFormData) => ({
        ...prevFormData,
        document_id: googleDocId,
      }));
    }
  }, [formData.googleDocLink]);

  useEffect(() => {
    if (formData.googleDocLink) {
      setShowSection(true);
    }
  }, [formData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (id, isChecked) => {
    setFormData({
      ...formData,
      [id]: isChecked,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // console.log(formData);

    setIsSubmitting(true);

    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('description', formData.description);
    formDataToSend.append('googleDocLink', formData.googleDocLink);
    formDataToSend.append('document_id', formData.document_id);
    if (formData.acceptance_criteria) {
      formDataToSend.append(
        'acceptance_criteria',
        formData.acceptance_criteria
      );
    }
    if (formData.test_cases) {
      formDataToSend.append('test_cases', formData.test_cases);
    }

    try {
      const createTicketResponse = await WebAppService.createTicket(
        formDataToSend,
        organizationId
      );

      // console.log(createTicketResponse);
      setCreateTicketsResponse(createTicketResponse);
      setFormSubmitted(true);

      setSentProjects((prevProjects) => [...prevProjects, formData]);
      refetchProjects();
      onCreateTickets();
    } catch (error) {
      console.log(error);
    }

    // Close the modal after 5 seconds
    setTimeout(() => {
      onClose();
    }, 2000);
  };

  return (
    <div className="modal">
      <div
        onClick={onClose}
        className="modal-overlay absolute inset-0 bg-black opacity-100 "
      ></div>
      {formSubmitted ? (
        <StatusModal onClose={onClose} />
      ) : (
        <form
          onSubmit={handleSubmit}
          className="flex flex-col z-20 w-[90%] md:w-[90%] lg:w-[50%] max-h-[90%] py-[48px] px-8  md:px-16 lg:px-[48px] bgModal rounded-xl overflow-scroll"
        >
          <div className="flex flex-col gap-y-8 items-start justify-start">
            <div className="flex items-center justify-between w-full">
              <div className="flex flex-col items-start w-4/5">
                <h2 className="flex gap-2 items-center text-2xl font-bold text-gradient text-left">
                  <FileIcon2 />
                  New project
                </h2>
              </div>

              <span onClick={onClose} className="cursor-pointer">
                <CloseIcon />
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-2 mt-5">
            <UploadDocInput
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder={'Enter project title'}
            />
            <UploadDocTextArea
              name="description"
              value={formData.description}
              onChange={handleChange}
              placeholder={'Project description'}
            />
          </div>
          <div
            className={`relative w-[90%] mx-auto mt-5 cursor-pointer rounded-lg flex items-center`}
          >
            <input
              className="w-full  rounded-full border-0.5 border-blue-3 text-blue-3 px-[24px] py-[26px] bg-black-3 text-center focus:outline-none placeholder:text-center placeholder:text-blue-3/80"
              type="url"
              value={formData.googleDocLink}
              name="googleDocLink"
              placeholder="Add the link to your google document"
              onChange={handleChange}
            />
          </div>

          {formData.googleDocLink && (
            <>
              <section className="w-full flex flex-col gap-5 mx-auto items-center px-[16px] py-[16px] rounded-md">
                {/* <Clipboard
									text={"analyzer@crafter-project.iam.gserviceaccount.com"}
								/> */}
                <p className="text-orange-1 text-center w-4/5">
                  In your Google doc, hit Share and give Viewer access to
                  'Anyone with the Link'. This allows Crafter to read your
                  document.
                </p>
              </section>
            </>
          )}

          <section
            className={`${
              showSection ? 'max-h-[500px] mt-5' : 'max-h-0 overflow-hidden'
            } flex flex-col transition-max-height duration-500 ease-in-out`}
          >
            <div>
              <div className="flex flex-col gap-1 items-center justify-center">
                <label className="flex  items-end gap-1 font-semibold text-[20px]">
                  <span>Tickets format </span>
                  <span className="text-gray-6 text-[12px]">(Optional) </span>
                  <span>
                    <InfoCircle />
                  </span>
                </label>
                <p className="text-sm text-gray-6">
                  Tell us the sections you want to include in the tickets
                </p>

                <div className="mt-3 flex gap-x-10 gap-y-3 items-center justify-start flex-row overflow-hidden w-full flex-wrap">
                  <div className="flex mx-auto gap-3">
                    <GlobalCheckbox
                      id="acceptance_criteria"
                      label="Acceptance Criteria"
                      isChecked={formData.acceptance_criteria}
                      onChange={handleCheckboxChange}
                    />

                    <GlobalCheckbox
                      id="test_cases"
                      label="Test Cases"
                      isChecked={formData.test_cases}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="w-[70%] mx-auto mt-5">
            <GlobalButton
              variant={'secondary'}
              size={'lg'}
              state={isSubmitting || !formValid ? 'inactive' : 'default'}
              type={'submit'}
              className=" flex items-center gap-2 justify-center font-semibold text-sm "
            >
              {isSubmitting ? 'Generating...' : 'Generate tickets'}
            </GlobalButton>
          </div>
        </form>
      )}

      {successToast && <SuccessToast message={successMessage} />}
      {errorToast && <ErrorToast message={errorMessage} />}
    </div>
  );
}
