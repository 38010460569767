import React from 'react';
import LineChartFrame from './LineChartFrame/LineChartFrame';

export default function LineChart({ data }) {
  return (
    <div className="flex w-full h-full gap-2 relative">
      <div className="flex w-full h-full gap-2">
        {data.map((item, index) => (
          <LineChartFrame key={index} status={item.status} value={item.value} />
        ))}
      </div>

      {/* <div className="absolute top-0 h-3 w-full custom-dash z-10"></div>
      <div className="absolute top-[50px] h-3 w-full custom-dash z-10"></div>
      <div className="absolute top-[100px] h-3 w-full custom-dash z-10"></div>
      <div className="absolute top-[150px] h-3 w-full custom-dash z-10"></div> */}
    </div>
  );
}
