import React from 'react';

export default function Caret() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M12.8998 11.525L9.74146 7.31671H5.06646C4.26646 7.31671 3.86646 8.28338 4.43312 8.85004L8.74979 13.1667C9.44146 13.8584 10.5665 13.8584 11.2581 13.1667L12.8998 11.525Z"
        fill="#F0EEEE"
      />
      <path
        d="M14.9337 7.31671H9.74207L12.9004 11.525L15.5754 8.85004C16.1337 8.28338 15.7337 7.31671 14.9337 7.31671Z"
        fill="#F0EEEE"
      />
    </svg>
  );
}
