import React from 'react';
import ticketsGif from '@/assets/ticketsGif.gif';

export default function StatusModal({ isOpen, onClose }) {
  return (
    <div className="modal">
      <div
        onClick={onClose}
        className="modal-overlay absolute inset-0 bg-black-1 opacity-50"
      ></div>

      <div className="flex flex-col items-center gap-5 modalBg z-20 w-[50%] md:w-[90%] lg:w-[40%] max-h-[90%] mt-10 pt-[0px] pb-[48px] px-8  md:px-16 lg:px-[48px]  rounded-lg shadow-lg">
        <img src={ticketsGif} alt="" width={'200'} className="opacity-70 " />

        <div className="flex flex-col mt-[-20px] items-center text-center gap-3">
          <h1 className="text-[20px] font-semibold">Generating your tickets</h1>
          <p className="text-gray-6">
            This may take a while and you will be notified when it is all done
          </p>
        </div>
      </div>
    </div>
  );
}
