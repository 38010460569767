import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { EpicIcon } from '../../../icons/InsightIcons';
import Timestamp from '../../../icons/Timestamp';
import LineChart from '../Charts/LineChart/LineChart';
import Badge from '../../../components/ui/Badge';
import Badge2 from '../../../components/ui/Badge2';
import { Stale, UpTrend } from '../../../icons/TrendIcons';
import GlobalDropdown4 from '../../../components/ui/GlobalDropdown4';
import epicsData from '../../../store/mock-data/epics.json';
import WebAppService from '@/services/WebAppService';
import Table from '../../../components/table/Table';
import useInsightsStore from '@/store/Insights';
import LoadingComponent from '@/components/LoadingComponent';
import DateSince2 from '../../../components/DateSince/DateSince2';
import ReactPaginate from 'react-paginate';
import PdfIcon2 from '../../../icons/files/PdfIcon2';
import CSVIcon from '../../../icons/files/CSVIcon';
import ExportButtonDropdown from '../ExportButton/ExportButton';
import { exportToCSV } from '../../../utils/helpers/helpers';
import html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';
import PDFTemplate from '../PDF/PDFTemplate';

export default function Body() {
  const { epicId } = useParams();
  const {
    selectedEpic,
    jiraProjectEpicChildren,
    updateJiraProjectEpicChildren,
    selectedProject,
    updateSelectedEpic,
    updateJiraProjectEpics,
    setChildrenLoading,
  } = useInsightsStore();
  const navigate = useNavigate();

  const issueStatus = jiraProjectEpicChildren.completionStatus;
  const progress = Math.round(jiraProjectEpicChildren.progressPercentage);
  const data = [
    {
      status: 'completed',
      value: Math.round(issueStatus?.completedPercentage),
    },
    {
      status: 'active',
      value: Math.round(issueStatus?.activePercentage),
    },
    {
      status: 'dormant',
      value: Math.round(issueStatus?.dormantPercentage),
    },
    {
      status: 'todo',
      value: Math.round(issueStatus?.toDoPercentage),
    },
    {
      status: 'unassigned',
      value: Math.round(issueStatus?.unassignedPercentage),
    },
  ];
  const itemsPerPage = 10;
  const pageCount = Math.ceil(
    jiraProjectEpicChildren.issues.length / itemsPerPage
  );
  const [currentPage, setCurrentPage] = useState(0);
  const offset = currentPage * itemsPerPage;
  const currentData = jiraProjectEpicChildren.issues.slice(
    offset,
    offset + itemsPerPage
  );

  const selectedProjectKey = selectedProject.key;

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const fetchJiraProjectEpics = async (selectedProjectKey) => {
    try {
      const response =
        await WebAppService.getJiraProjectEpics(selectedProjectKey);
      const epicsData = response.data;

      // Check if current selected epic exists in the new epicsData
      const epicExists = epicsData.issues.find(
        (epic) => epic.key === selectedEpic?.key
      );

      if (epicExists) {
        updateSelectedEpic(epicExists);
      } else if (selectedEpic) {
        updateSelectedEpic(null);
      }

      updateJiraProjectEpics(epicsData);
    } catch (error) {
      console.error('Error getting Jira project epics', error);
    }
  };

  const handleGetEpicChildren = async (selectedProjectKey, epicId) => {
    try {
      const response = await WebAppService.getChildrenOfAnEpic(
        selectedProjectKey,
        epicId
      );
      const epicChildren = response.data;

      // console.log('Epic Children Data', epicChildren);
      updateJiraProjectEpicChildren(epicChildren);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  useEffect(() => {
    if (selectedProjectKey) {
      fetchJiraProjectEpics(selectedProjectKey);
    }
    if (selectedProjectKey && epicId) {
      handleGetEpicChildren(selectedProjectKey, epicId);
    }
  }, [selectedProjectKey, epicId]);

  const exportOptions = [
    { label: 'PDF', value: 'pdf', icon: <PdfIcon2 />, isActive: false },
    { label: 'CSV', value: 'csv', icon: <CSVIcon />, isActive: true },
  ];

  const exportToPDF = () => {
    const element = document.getElementById('pdf-container');

    if (!element) {
      console.error('PDF container not found!');
      return;
    }

    try {
      html2pdf()
        .set({
          filename: `${selectedEpic?.key || 'default'} report.pdf`,
        })
        .from(element)
        .save();
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  // function handleExport(option) {
  //   switch (option.value) {
  //     case 'pdf':
  //       exportToPDF();
  //       break;
  //     case 'csv':
  //       exportToCSV(jiraProjectEpicChildren);
  //       break;

  //     default:
  //       break;
  //   }
  // }

  function handleExport(option) {
    if (option.value === 'pdf' && option.isActive) {
      exportToPDF();
    } else if (option.value === 'csv' && option.isActive) {
      exportToCSV(jiraProjectEpicChildren);
    }
  }

  return (
    <div
      className={`min-w-[1200px] w-full h-screen pt-[65px] pb-[45px] px-4 text-sm text-white-1 overflow-scroll `}
    >
      <div className="flex bg3 flex-col gap-8 w-full  h-full pt-8 px-8 pb-4 mt-8 mb-8 border border-gray-2/30 rounded-md overflow-scroll innerFrameBg">
        <div className="w-full flex flex-col gap-5 h-full">
          <Link className="text-gray-4 flex items-center gap-2" to={'/'}>
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.37992 4.45331L2.33325 8.49998L6.37992 12.5466"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M13.6668 8.5H2.44678"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Go back
          </Link>

          <div className="w-full flex items-center justify-between gap-5">
            <div className="flex items-center gap-10">
              <span className="flex flex-row gap-2 border-b-1 py-3 px-2 rounded-md border-gray-3 w-fit">
                <EpicIcon />
                <p className="font-semibold">
                  {selectedEpic.key}: {selectedEpic.summary}
                </p>
              </span>

              <span className="flex gap-2 items-center text-gray-4">
                <Timestamp />
                <p className="font-bold">Project age: </p>
                <DateSince2 date={selectedEpic?.created} />
              </span>
            </div>

            <div className="">
              <ExportButtonDropdown
                state={selectedEpic ? 'default' : 'inactive'}
                options={exportOptions}
                onSelect={handleExport}
              />
            </div>
          </div>

          <div className="mt-3 w-full h-auto flex flex-row items-center gap-5 justify-between">
            <div className="w-3/5 h-[100px]">
              <LineChart data={data} />
            </div>
            <header className="w-fit flex gap-4 items-center justify-end ml-auto">
              <span className="flex items-center w-fit gap-2">
                <label className="text-[12px] text-gray-1">Status</label>
                <div className="w-full">
                  <Badge status={selectedEpic.status} />
                </div>
              </span>
              <span className="flex items-center w-fit gap-2">
                <label className="text-[12px] text-gray-1 w-fit flex-shrink-0">
                  Project progress{' '}
                </label>
                <div className="w-full">
                  <Badge2>
                    <span className="text-[12px] text-gray-1">{progress}%</span>
                    <UpTrend />
                  </Badge2>
                </div>
              </span>
            </header>
          </div>

          <div className="w-full h-full flex flex-col mt-4">
            <h4 className="font-semibold text-lg">Chart log</h4>
            <EpicTable data={currentData} />

            <div className="mt-2 mr-auto mb-4 pt-10">
              <ReactPaginate
                previousLabel={'Prev'}
                nextLabel={'Next'}
                breakLabel={'...'}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName={
                  'pagination flex items-center justify-center mt-4 gap-3'
                }
                pageClassName={`border-0.5 border-gray-1/30 px-4 py-2 rounded-sm font-semibold`}
                previousLinkClassName={`text-white-1 paginationBtn px-5 py-2 rounded-lg font-semibold`}
                nextLinkClassName={`text-white-1 paginationBtn px-5 py-2 rounded-lg font-semibold`}
                disabledClassName={'opacity-50 cursor-not-allowed'}
                activeClassName={'pageBtnBg'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const EpicTable = ({ data }) => {
  const columns = [
    'Ticket code',
    'Assignees',
    'Ticket status',
    'Created at',
    '...',
  ];

  // Map your epics data to match the table structure
  const rows = data.map((epic) => ({
    'ticket code': <p className="text-gray-4">{epic?.key}</p>,
    assignees: (
      <>
        {epic?.assignee !== null ? (
          <div className="flex flex-row items-center gap-2">
            <img
              className="rounded-full border-1 border-gray-3"
              width={35}
              src={epic.assignee?.avatarUrl}
              alt={`${epic.assignee?.name}'s avatar`}
            />
            <p className="text-gray-4 truncate w-24">{epic.assignee?.name}</p>
          </div>
        ) : (
          <p className="text-gray-4 truncate w-24">Unassigned</p>
        )}
      </>
    ),
    'ticket status': (
      <span>
        <Badge withPointer status={epic?.status} />
      </span>
    ),
    'created at': (
      <p className="text-gray-4">
        {new Date(epic?.createdAt).toLocaleDateString()}
      </p>
    ),
    '...': (
      <div>
        <Link
          target="_blank"
          className="underline underline-offset-4 text-blue-1 "
          to={epic.issueUrl}
        >
          View in Jira
        </Link>
      </div>
    ),
  }));

  return (
    <div className="w-full mt-4">
      <Table columns={columns} rows={rows} />

      <div className="hidden">
        <PDFTemplate />
      </div>
    </div>
  );
};
