import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Card1 from './Card1';
import { formatDatesDmy } from '../../utils/helpers/helpers';
import GlobalButton from '@/components/ui/GlobalButton';

export default function Billing({
  subscription,
  hasSubscriptionEnded,
  loading,
}) {
  const handleSubmit = async (e) => {
    e.preventDefault();

    window.location.href =
      'https://billing.stripe.com/p/login/28o6qb5m3ftC90Y4gg';
  };
  if (loading) {
    return (
      <div
        id="container"
        className="flex bg3 flex-col gap-10 w-full h-full p-8 mt-8 mb-8 border border-gray-2/30 rounded-md overflow-scroll innerFrameBg"
      >
        <div className="flex gap-2 items-center">
          <h1 className="font-semibold text-[20px]">Plans & billings</h1>
        </div>

        <div className="p-5 w-full fullBorder rounded-lg flex flex-col gap-1">
          <div className="text-xl flex flex-row items-center justify-between">
            <h1 className="text-white-1 font-semibold">Loading...</h1>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      id="container"
      className="flex flex-col gap-4 w-full h-full px-8 pt-8 pb-3 mt-8"
    >
      <div className="bg1 rounded-xl overflow-hidden bg1 py-8 px-8 h-full">
        <div className="flex gap-2 items-center justify-between w-full">
          <div className="flex flex-col items-start">
            <h1 className="font-semibold text-[24px] tracking-tight">
              Plans & billings
            </h1>
            <p className="text-gray-4">
              Get started with Crafter and experience the power of a seamless
              workflow
            </p>
          </div>

          <div className=" ">
            <GlobalButton
              variant={'blue'}
              size={'md'}
              state={'default'}
              type={'submit'}
              onClick={handleSubmit}
              className=" flex items-center gap-2 justify-center font-semibold text-sm "
            >
              {'Manage my subscription'}
            </GlobalButton>
          </div>
        </div>

        <div className="p-5 w-full currentPlanHolder flex flex-col gap-1 mt-8">
          <div className="text-xl flex flex-row items-center justify-between">
            <h1 className="font-semibold">
              You are currently on the{' '}
              <span className="text-blue-1">{subscription?.plan?.name}</span>{' '}
              plan
            </h1>
            <p>
              <span className="font-semibold">
                ${subscription?.plan?.prices?.monthly[0]?.amount}
              </span>
              /month
            </p>
          </div>

          <p className="w-full text-gray-4 text-base">
            Crafter Pro is on its way, we are working to revolutionize your
            engineering and product team's workflow.{' '}
            <Link
              to={'https://usecrafter.com/join-waitlist'}
              target="_blank"
              className="text-orange-1 text-base font-semibold underline underline-offset-4"
            >
              Join the waitlist
            </Link>
          </p>
        </div>

        <div className="flex flex-col gap-5 mt-8">
          <div>
            <h1 className="font-semibold text-lg">Plan features</h1>
          </div>

          <Card1 subscription={subscription} />
        </div>
        <div className="text-base text-left flex flex-col gap-1 mt-8">
          <div className="flex flex-row gap-1">
            <p className="flex flex-row text-base gap-1 text-gray-4 pb-3">
              👉🏼 Your subscription will expire on
              <span className="text-orange-1 text-base font-semibold">
                {/* {formatExpiryDate(subscription?.expiryDate)} */}
                {formatDatesDmy(subscription?.expiryDate)}
              </span>
            </p>
          </div>
          <p className="text-base text-gray-4">
            Got any questions about your subscription? Reach out to our team on{' '}
            <span className="text-orange-1 text-base font-semibold">
              {' '}
              support@usecrafter.com
            </span>{' '}
            for more enquiries.
          </p>
        </div>
      </div>
    </div>
  );
}
