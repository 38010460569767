import React from 'react';
import Heading from '../../components/Heading';
import ErrorGif from '../../components/ErrorGif';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="h-screen bg-black-6 relative flex">
      {/* <div className="bg-black-6 z-20 absolute top-0 w-full flex items-center justify-between py-4 h-[80px] px-[20px] lg:px-[20px] md:px-[20px] border-b-0.5 border-gray-2">
				<Heading />
			</div> */}

      <div className="flex items-center justify-center text-white-1 w-full">
        <div className="flex flex-col gap-4 items-center mx-auto">
          <div>
            <ErrorGif />
          </div>
          <h1 className="font-bold text-5xl">Page Not Found</h1>
          <p>
            The page you are looking for does not exist.{' '}
            <Link
              to={'/'}
              className="font-semibold underline underline-offset-4 text-orange-1"
            >
              Back to home.
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
