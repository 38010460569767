import React from 'react';

export default function LinkIcon() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.67992 8C2.15325 7.36667 1.83325 6.55333 1.83325 5.66667C1.83325 3.65333 3.47992 2 5.49992 2H8.83325C10.8466 2 12.4999 3.65333 12.4999 5.66667C12.4999 7.68 10.8533 9.33333 8.83325 9.33333H7.16659"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.32 7.99984C14.8467 8.63317 15.1667 9.4465 15.1667 10.3332C15.1667 12.3465 13.52 13.9998 11.5 13.9998H8.16667C6.15333 13.9998 4.5 12.3465 4.5 10.3332C4.5 8.31984 6.14667 6.6665 8.16667 6.6665H9.83333"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
