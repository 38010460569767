import React from 'react';

export default function InputField4({
  label,
  type,
  id,
  name,
  value,
  onChange,
  placeholder,
  isRequired,
}) {
  return (
    <div className="w-full flex flex-col items-start gap-1">
      <label className="text-left text-xs md:text-sm lg:text-sm text-white-1 font-semibold">
        {label} {isRequired && <span className="text-sm text-error-1">*</span>}
      </label>
      <input
        type={type}
        id={id}
        name={name}
        placeholder={placeholder}
        className="placeholder:text-gray-3 placeholder:text-sm  w-full bg-transparent rounded-md py-[10px] px-[14px] border-1 border-gray-3/20 focus:outline-none text-sm md:text-base lg:text-base"
        value={value}
        onChange={onChange}
        required={isRequired}
      />
    </div>
  );
}
