import React, { useState } from 'react';
import Body from './Body';
import { Helmet } from 'react-helmet';
import { Body2 } from './Body2';
import TemporaryChatModal from '../../components/modals/TemporaryChatModal/TemporaryChatModal';

export default function Insights() {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <main
      id="insights-body"
      className="relative innerFrameBg text-gray-4 h-screen overflow-hidden w-full flex flex-col"
    >
      <Helmet>
        <title>Insights | Crafter</title>
      </Helmet>
      <div className="flex w-full h-full">
        <div
          className={`w-full ${
            collapsed ? 'w-[95%]' : 'w-[80%]'
          } overflow-scroll h-screen`}
        >
          <Body2 collapsed={collapsed} />
          {/* <Body collapsed={collapsed} /> */}
        </div>
      </div>

      <TemporaryChatModal />
    </main>
  );
}
