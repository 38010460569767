import React, { useMemo } from 'react';

const DetailedDateSince = ({ date }) => {
  // Parse the entered date
  const givenDate = new Date(date);
  const currentDate = new Date();

  // Calculate the time difference in milliseconds
  let timeDifference = currentDate - givenDate;

  // Define time intervals in milliseconds
  const intervals = {
    year: 1000 * 60 * 60 * 24 * 365,
    month: 1000 * 60 * 60 * 24 * 30,
    week: 1000 * 60 * 60 * 24 * 7,
    day: 1000 * 60 * 60 * 24,
    hour: 1000 * 60 * 60,
  };

  // Compute the detailed time using useMemo to avoid recalculating
  const detailedTime = useMemo(() => {
    // Check if the time difference is less than one day
    if (timeDifference < intervals.day) {
      const hours = Math.floor(timeDifference / intervals.hour);
      if (hours === 0) {
        return 'less than an hour';
      }
      return `${hours} hour${hours > 1 ? 's' : ''}`;
    }

    const parts = [];
    for (const [unit, value] of Object.entries(intervals)) {
      const elapsed = Math.floor(timeDifference / value);
      if (elapsed >= 1) {
        parts.push(`${elapsed} ${unit}${elapsed > 1 ? 's' : ''}`);
        timeDifference -= elapsed * value;
      }
    }

    // Join the parts with commas and an "and" before the last part
    return parts.length > 1
      ? `${parts.slice(0, -1).join(', ')} and ${parts.slice(-1)}`
      : parts[0];
  }, [timeDifference]);

  return <span className="w-fit flex flex-shrink-0">{detailedTime}</span>;
};

export default DetailedDateSince;
