import React, { useEffect, useState } from 'react';
import GlobalButton from '../../components/ui/GlobalButton';

export default function FreeCard() {
  const [buttonSize, setButtonSize] = useState('lg');
  const liteDetails = [
    'Chat with Crafter AI',
    'Access to Jira project insights and   summary reports',
    // "1 free export to Jira",
    // "Access to Jira project insights and summary reports",
    // "Unlimited CSV download",
  ];

  useEffect(() => {
    // Determine the screen size and set button size accordingly
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setButtonSize('sm');
      } else {
        setButtonSize('md');
      }
    };

    // Initial call to set the button size
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const goToApp = () => {
    window.open('https://app.usecrafter.com/', '_blank');
  };

  return (
    <div className="flex flex-col gap-5 h-[600px] md:h-full lg:h-full pt-5 pb-10 px-5 w-full pricingCardBg rounded-3xl">
      <div className="flex w-full items-left flex-col justify-between">
        <p className="text-base font-normal text-blue-3">Free</p>
        <h1 className="text-white-1 font-semibold text-[32px]">
          $0.00<span className={`text-base text-gray-4`}>/Month</span>
        </h1>
      </div>
      <p className="text-gray-6 font-normal text-[16px]">
        Great for trying out Crafter app to have wind of our offers
      </p>
      <div>
        <GlobalButton
          variant={'secondary'}
          size={buttonSize}
          state={'inactive'}
          onClick={goToApp}
        >
          Start for free
        </GlobalButton>
      </div>

      <div className={`flex items-center gap-4`}>
        <div className={`w-full flex items-center`}>
          <div className={`w-1 h-1 bg-gray-6/30 rounded-full`}></div>
          <hr className={`border-0.5 border-gray-6/30 w-full`} />
        </div>
        <span className={`text-gray-6 text-sm`}>Features</span>
        <div className={`w-full flex items-center`}>
          <hr className={`border-0.5 border-gray-6/30 w-full`} />
          <div className={`w-1 h-1 bg-gray-6/30 rounded-full`}></div>
        </div>
      </div>

      <div className="w-full text-sm flex flex-col gap-5 text-gray-4">
        {liteDetails.map((liteDetail, index) => (
          <span className={`flex items-start gap-2`} key={index}>
            <span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                  stroke="#737373"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.75 12L10.58 14.83L16.25 9.16998"
                  stroke="#737373"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            {liteDetail}
          </span>
        ))}
      </div>
    </div>
  );
}
