import React from 'react';

export default function JpegIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_209_4647)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.4219 0.0157471C2.37152 0.0157471 1.5199 0.868122 1.5199 1.9185V22.1089C1.5199 23.16 2.37152 24.0112 3.4219 24.0112H20.5875C21.6379 24.0112 22.4903 23.1596 22.4903 22.1089V7.63237L15.4013 0.0157471H3.4219Z"
          fill="#49C9A7"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22.4914 7.63195V8.00695H17.6918C17.6918 8.00695 15.3248 7.53445 15.3934 5.49182C15.3934 5.49182 15.4714 7.63195 17.6449 7.63195H22.4914Z"
          fill="#37BB91"
        />
        <path
          opacity="0.5"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.4028 0V5.46037C15.4028 6.08137 15.8168 7.632 17.6918 7.632H22.4915L15.4028 0Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.29468 20.1521V12.3131H13.1344V20.1521H5.29468ZM12.3443 13.086H6.08518V17.811H12.3443V13.086ZM8.75068 16.23L10.2166 14.259L10.6692 15.0551L11.1923 14.8924L11.5613 17.004H6.65555L7.96655 15.759L8.75068 16.23ZM7.30468 14.865C6.96343 14.865 6.6863 14.607 6.6863 14.2882C6.6863 13.9699 6.96343 13.7115 7.30468 13.7115C7.64668 13.7115 7.92343 13.9699 7.92343 14.2882C7.92343 14.607 7.64668 14.865 7.30468 14.865Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_209_4647">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
