import React from 'react';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  ArcElement,
  plugins,
} from 'chart.js';

ChartJS.register(Tooltip, Legend, ArcElement);

export default function PieChart({ data }) {
  const pieChartData = {
    labels: data.map((item) => item.status),
    datasets: [
      {
        label: 'Task Status',
        data: data.map((item) => item.value),
        hoverOffset: 4,
        backgroundColor: [
          '#4BCE97', // completed tasks
          '#0182AB', // active tasks
          '#F58E30', // dormant tasks
          '#684C91', // to do tasks
          '#181818', // unassigned
        ],
        borderColor: ['#737373'],
        borderWidth: 0.5,
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'right',
        display: false,
      },
      maintainAspectRatio: false,
      animation: {
        duration: 2000,
        easing: 'easeOutQuart',
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        titleColor: '#fff',
        bodyColor: '#fff',
        callbacks: {
          label: (tooltipItem) => {
            return `${tooltipItem.label} tasks`;
          },
        },
      },
      cutout: '30%',
      hover: {
        mode: 'index',
      },
    },
  };
  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  return (
    <div className="flex items-center gap-10 justify-between w-full h-[200px] py-4 bg-transparent">
      <div className="w-[200px] h- py-4">
        <Pie options={options} data={pieChartData} />
      </div>

      <div className="flex flex-col gap-5  w-2/3">
        <p className="font-semibold border-b-1 border-gray-2/20 py-2">
          Chart summary
        </p>
        <div className="w-full grid grid-cols-2 gap-3 justify-center">
          {data.map((item, index) => (
            <div key={index} className="flex items-center">
              <span
                className="w-4 h-4 mr-2 rounded-full border border-dashed"
                style={{
                  borderColor:
                    index === data.length - 1
                      ? '#A6A6A6'
                      : pieChartData.datasets[0].backgroundColor[index],

                  backgroundColor:
                    pieChartData.datasets[0].backgroundColor[index] + '3D',
                  borderWidth: '1px',
                }}
              ></span>

              <p className="text-gray-3 ">
                {capitalizeFirstLetter(item.status)} tasks{' '}
                <span className="text-white-1">{item.value}%</span>
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
