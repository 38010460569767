import React from 'react';

export default function Step6() {
  return (
    <div className="flex flex-col items-start p-0">
      <span className="font-semibold text-[12px]">5 of 5</span>

      <p className="mt-3 text-[12px] text-left">
        Your insight chart appears here, get an overview of your project’s
        progress.
      </p>
    </div>
  );
}
