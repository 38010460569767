export const SuccessIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        fill="#63B575"
      />
      <path
        d="M10.5799 15.5801C10.3799 15.5801 10.1899 15.5001 10.0499 15.3601L7.21994 12.5301C6.92994 12.2401 6.92994 11.7601 7.21994 11.4701C7.50994 11.1801 7.98994 11.1801 8.27994 11.4701L10.5799 13.7701L15.7199 8.6301C16.0099 8.3401 16.4899 8.3401 16.7799 8.6301C17.0699 8.9201 17.0699 9.4001 16.7799 9.6901L11.1099 15.3601C10.9699 15.5001 10.7799 15.5801 10.5799 15.5801Z"
        fill="#63B575"
      />
    </svg>
  );
};

export const ErrorIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M11.9998 22.8337C17.9829 22.8337 22.8332 17.9834 22.8332 12.0003C22.8332 6.01724 17.9829 1.16699 11.9998 1.16699C6.01675 1.16699 1.1665 6.01724 1.1665 12.0003C1.1665 17.9834 6.01675 22.8337 11.9998 22.8337Z"
        fill="#FF4E4E"
      />
      <path
        d="M13.1485 12.0001L15.6401 9.50847C15.9543 9.1943 15.9543 8.6743 15.6401 8.36014C15.326 8.04597 14.806 8.04597 14.4918 8.36014L12.0001 10.8518L9.50847 8.36014C9.1943 8.04597 8.6743 8.04597 8.36014 8.36014C8.04597 8.6743 8.04597 9.1943 8.36014 9.50847L10.8518 12.0001L8.36014 14.4918C8.04597 14.806 8.04597 15.326 8.36014 15.6401C8.52264 15.8026 8.72847 15.8785 8.9343 15.8785C9.14014 15.8785 9.34597 15.8026 9.50847 15.6401L12.0001 13.1485L14.4918 15.6401C14.6543 15.8026 14.8601 15.8785 15.066 15.8785C15.2718 15.8785 15.4776 15.8026 15.6401 15.6401C15.9543 15.326 15.9543 14.806 15.6401 14.4918L13.1485 12.0001Z"
        fill="#FF4E4E"
      />
    </svg>
  );
};

export const WarningIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="21.67" height="21.67" rx="10.835" fill="#604D17" />
      <path
        d="M10.8498 7V12.3554M10.88 15.0331V15.1H10.8196V15.0331H10.88Z"
        stroke="#E8B931"
        stroke-width="1.95"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const DefaultIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="21.67" height="21.67" rx="10.835" fill="#033747" />
      <path
        d="M10.8498 7V12.3554M10.88 15.0331V15.1H10.8196V15.0331H10.88Z"
        stroke="#0182AB"
        stroke-width="1.95"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
