import React from 'react';

export default function TickIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0001 18.3332C14.6025 18.3332 18.3334 14.6022 18.3334 9.99984C18.3334 5.39746 14.6025 1.6665 10.0001 1.6665C5.39771 1.6665 1.66675 5.39746 1.66675 9.99984C1.66675 14.6022 5.39771 18.3332 10.0001 18.3332Z"
        fill="#0182AB"
      />
      <path
        d="M8.8167 12.9832C8.65003 12.9832 8.4917 12.9165 8.37503 12.7998L6.0167 10.4415C5.77503 10.1998 5.77503 9.79984 6.0167 9.55817C6.25837 9.3165 6.65837 9.3165 6.90003 9.55817L8.8167 11.4748L13.1 7.1915C13.3417 6.94984 13.7417 6.94984 13.9834 7.1915C14.225 7.43317 14.225 7.83317 13.9834 8.07484L9.25837 12.7998C9.1417 12.9165 8.98337 12.9832 8.8167 12.9832Z"
        fill="#CCE6EE"
      />
    </svg>
  );
}
