import React from 'react';
import clsx from 'clsx';
import useActionStore from '../../store/Actions';
import useIntegrationsStore from '../../store/Integrations';
import GlobalButton from '../../components/ui/GlobalButton';
import { useNavigate } from 'react-router-dom';

export default function Card({
  component,
  title,
  description,
  isInactive,
  available,
  onClick,
  isIntegrated,
  deletable,

  collapsed,
}) {
  const cardClass = clsx({
    'opacity-30 blur-none cursor-not-allowed': isInactive,
    'cursor-pointer': isInactive === false,
  });
  const { toggleDeleteIntegration } = useActionStore();
  const navigate = useNavigate();
  const { updateSelectedIntegration } = useIntegrationsStore();

  const handleDeleteClick = (integration) => {
    updateSelectedIntegration(integration);
    toggleDeleteIntegration(true);
  };

  const handleUpdateClick = (integration) => {
    updateSelectedIntegration(integration);
    navigate(`/integrations/${integration}`);
  };

  return (
    <div
      onClick={onClick}
      className={`relative w-full md:w-full lg:w-full text-white-3 integrationCardBg hover:bg-gray-2 h-[170px] md:h-[220px] xl:h-[200px] px-2 md:px-5 py-3 md:py-6 lg:py-5 flex gap-4 lg:gap-4 items-start border border-gray-2/30 rounded-md ${cardClass}`}
    >
      {available === false && (
        <div className="absolute top-2 md:top-5 right-2 md:right-5 text-white px-2 py-1 rounded-tr-md text-xs font-semibold text-error-2">
          Coming soon
        </div>
      )}

      <div className="w-[40px] h-[40px]">{component}</div>
      <div className="flex flex-col gap-3">
        <div
          className={`flex w-full justify-between gap-2 items-center ${isIntegrated ? '' : 'py-1'} `}
        >
          <h2 className="font-semibold text-white-2 text-[20px] md:text-[20px] lg:text-[20px] xl:text-[24px]">
            {title}
          </h2>
          {isIntegrated && (
            <div className="text-white px-2 py-1 rounded-tr-md text-xs font-semibold text-error-2">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.4"
                  d="M9.99984 18.3332C14.6022 18.3332 18.3332 14.6022 18.3332 9.99984C18.3332 5.39746 14.6022 1.6665 9.99984 1.6665C5.39746 1.6665 1.6665 5.39746 1.6665 9.99984C1.6665 14.6022 5.39746 18.3332 9.99984 18.3332Z"
                  fill="#A1C181"
                />
                <path
                  d="M8.8167 12.9832C8.65003 12.9832 8.4917 12.9165 8.37503 12.7998L6.0167 10.4415C5.77503 10.1998 5.77503 9.79984 6.0167 9.55817C6.25837 9.3165 6.65837 9.3165 6.90003 9.55817L8.8167 11.4748L13.1 7.1915C13.3417 6.94984 13.7417 6.94984 13.9834 7.1915C14.225 7.43317 14.225 7.83317 13.9834 8.07484L9.25837 12.7998C9.1417 12.9165 8.98337 12.9832 8.8167 12.9832Z"
                  fill="#A1C181"
                />
              </svg>
            </div>
          )}
        </div>
        <p
          className={`text-[14px] md:text-[14px] lg:text-[16px] text-gray-4 leading-[26px]`}
        >
          {description}
        </p>

        {isIntegrated ? (
          <div
            className={`${available === false ? 'cursor-not-allowed' : ''}
							 flex gap-2 absolute bottom-5 right-3 lg:right-5`}
          >
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleUpdateClick(title);
              }}
              className={`${
                available === false ? 'cursor-not-allowed' : ''
              } text-blue-2 border border-blue-2 rounded-full px-4 py-2 text-sm font-semibold`}
            >
              Update integration
            </button>

            {deletable && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteClick(title);
                }}
                className={`${
                  available === false ? 'cursor-not-allowed' : ''
                } text-error-2 rounded-full px-4 py-2 text-sm font-semibold`}
              >
                Delete integration
              </button>
            )}
          </div>
        ) : (
          <div
            className={`${available === false ? 'cursor-not-allowed' : ''}
							 flex absolute bottom-5 right-3 lg:right-5`}
          >
            <button
              onClick={onClick}
              className={`${
                available === false ? 'cursor-not-allowed' : ''
              } text-blue-2 border border-blue-2 rounded-full px-4 py-2 text-sm font-semibold`}
            >
              Integrate {title}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
