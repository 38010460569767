import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import IntegrationSuccess from '../../../components/modals/StatusModals/IntegrationSuccess/IntegrationSuccess';
import useAuthStore from '../../../store/Auth';
import AlreadyConfigured from './AlreadyConfigured';
import OpenAIForm from './OpenAIForm';
import useActionStore from '../../../store/Actions';
import IntegrationFailed from '../../../components/modals/StatusModals/IntegrationFailed/IntegrationFailed';

export default function OpenAI() {
  const [collapsed, setCollapsed] = useState(false);
  const { openAIActiveComponent, setOpenAIActiveComponent } = useActionStore();
  const navigate = useNavigate();
  const [activeComponent, setActiveComponent] = useState('');
  const authStore = useAuthStore();

  const hasSetOpenAIConfig = authStore.organization.hasSetOpenAIConfig;

  const handleCancel = () => {
    navigate('/integrations');
  };

  useEffect(() => {
    if (hasSetOpenAIConfig) {
      setOpenAIActiveComponent('AlreadyConfigured');
    } else {
      setOpenAIActiveComponent('OpenAIForm');
    }
  }, [hasSetOpenAIConfig]);
  return (
    <main className="relative innerFrameBg text-gray-4 h-screen overflow-hidden w-full flex flex-col">
      <Helmet>
        <title>Open AI | Crafter</title>
      </Helmet>
      <div className="flex w-full h-full">
        <div
          className={`w-full ${
            collapsed ? 'w-[95%]' : 'w-[80%]'
          } overflow-scroll h-screen`}
        >
          {openAIActiveComponent === 'AlreadyConfigured' && (
            <AlreadyConfigured onCancel={handleCancel} />
          )}
          {openAIActiveComponent === 'OpenAIForm' && (
            <OpenAIForm onCancel={handleCancel} />
          )}
        </div>
      </div>

      <IntegrationSuccess />
      <IntegrationFailed />
    </main>
  );
}
