import React from 'react';

export default function CSVIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.84557 0C1.96994 0 1.26025 0.71 1.26025 1.58563V18.4109C1.26025 19.2869 1.96994 19.9966 2.84557 19.9966H17.1499C18.0253 19.9966 18.7356 19.2869 18.7356 18.4109V6.34688L12.8284 0L2.84557 0Z"
        fill="#45B058"
      />
      <path
        d="M7.59558 13.4995C7.63495 13.5445 7.65745 13.6008 7.65745 13.6627C7.65745 13.7808 7.56183 13.8877 7.43776 13.8877C7.38151 13.8877 7.31964 13.8652 7.28026 13.8145C7.06089 13.5502 6.68933 13.3811 6.34058 13.3811C5.51901 13.3811 4.89464 14.0114 4.89464 14.9061C4.89464 15.7952 5.51933 16.4308 6.34058 16.4308C6.69495 16.4308 7.0437 16.2789 7.28026 16.003C7.31933 15.958 7.38151 15.9302 7.43776 15.9302C7.56745 15.9302 7.65745 16.0423 7.65745 16.1608C7.65745 16.217 7.63495 16.2733 7.59558 16.3183C7.30276 16.622 6.90339 16.8473 6.34089 16.8473C5.26058 16.8473 4.41089 16.0595 4.41089 14.9061C4.41089 13.7527 5.26058 12.9648 6.34089 12.9648C6.90339 12.9652 7.30839 13.1902 7.59558 13.4995ZM9.7212 16.8477C9.16433 16.8477 8.72526 16.662 8.40464 16.3805C8.35401 16.3355 8.33151 16.2736 8.33151 16.2117C8.33151 16.0989 8.41589 15.9752 8.55089 15.9752C8.59589 15.9752 8.64651 15.9864 8.68589 16.0202C8.94464 16.2511 9.30464 16.4308 9.73808 16.4308C10.4078 16.4308 10.6215 16.0708 10.6215 15.7836C10.6215 14.8158 8.39901 15.3505 8.39901 14.0111C8.39901 13.3923 8.95026 12.9702 9.68745 12.9702C10.1712 12.9702 10.5878 13.1161 10.8915 13.3695C10.9421 13.4145 10.9703 13.4764 10.9703 13.5383C10.9703 13.6508 10.8746 13.7633 10.7506 13.7633C10.7056 13.7633 10.655 13.7464 10.6156 13.7127C10.34 13.4877 9.99683 13.3864 9.65339 13.3864C9.20339 13.3864 8.88245 13.6283 8.88245 13.983C8.88245 14.827 11.105 14.343 11.105 15.7442C11.1053 16.2905 10.734 16.8477 9.7212 16.8477ZM15.0318 13.3308L13.7321 16.5605C13.6703 16.7123 13.5181 16.8136 13.3609 16.8136H13.3496C13.1865 16.8136 13.0346 16.7123 12.9725 16.5605L11.6781 13.3308C11.6668 13.3027 11.6612 13.2745 11.6612 13.2408C11.6612 13.1283 11.7621 12.993 11.9143 12.993C12.01 12.993 12.1 13.0492 12.1393 13.1448L13.3546 16.2673L14.57 13.1448C14.6037 13.0548 14.6937 12.993 14.795 12.993C14.9412 12.993 15.0481 13.1111 15.0481 13.2408C15.0484 13.2689 15.0428 13.3027 15.0318 13.3308Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.7502 6.36156V6.67406H14.7502C14.7502 6.67406 12.7777 6.28031 12.8352 4.57812C12.8352 4.57812 12.9002 6.36156 14.7112 6.36156H18.7502Z"
        fill="#349C42"
      />
      <path
        opacity="0.5"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.843 0.00195312V4.55227C12.843 5.06977 13.188 6.36195 14.7505 6.36195H18.7505L12.843 0.00195312Z"
        fill="white"
      />
    </svg>
  );
}
