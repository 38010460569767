import React from 'react';

export default function ProjectsIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1 1.6665H6.89998C6.58332 1.6665 6.31665 1.93317 6.31665 2.24984C6.31665 2.5665 6.58332 2.83317 6.89998 2.83317H9.61665L10.7833 4.38317C11.0416 4.72484 11.075 4.77484 11.5583 4.77484H14.6583C14.975 4.77484 15.2833 4.8165 15.5833 4.89984C15.6167 5.04984 15.6333 5.19984 15.6333 5.35817V5.64984C15.6333 5.9665 15.9 6.23317 16.2166 6.23317C16.5333 6.23317 16.8 5.9665 16.8 5.64984V5.34984C16.7833 3.3165 15.1333 1.6665 13.1 1.6665Z"
        fill="#F58E30"
      />
      <path
        d="M16.7834 5.44984C16.4251 5.1915 16.0167 4.99984 15.5751 4.8915C15.2834 4.80817 14.9667 4.7665 14.6501 4.7665H11.5501C11.0667 4.7665 11.0334 4.7165 10.7751 4.37484L9.60841 2.82484C9.06675 2.10817 8.64175 1.6665 7.28341 1.6665H5.35008C3.31675 1.6665 1.66675 3.3165 1.66675 5.34984V14.6498C1.66675 16.6832 3.31675 18.3332 5.35008 18.3332H14.6501C16.6834 18.3332 18.3334 16.6832 18.3334 14.6498V8.44984C18.3334 7.20817 17.7251 6.1165 16.7834 5.44984ZM11.9417 13.3332H8.05841C7.73342 13.3332 7.47508 13.0748 7.47508 12.7498C7.47508 12.4332 7.73342 12.1665 8.05841 12.1665H11.9334C12.2501 12.1665 12.5167 12.4332 12.5167 12.7498C12.5167 13.0748 12.2584 13.3332 11.9417 13.3332Z"
        fill="#0182AB"
      />
    </svg>
  );
}
