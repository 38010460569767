import React, { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import CustomButton from '@/components/common/Custom/CustomButton/CustomButton';
import GlobalButton from '@/components/ui/GlobalButton';
import InputField from '@/components/ui/InputField';
import InputField4 from '@/components/ui/InputField4';
import { Link } from 'react-router-dom';
import WebAppService from '@/services/WebAppService';
import useAuthStore from '@/store/Auth';
import CrafterLogo from '@/icons/CrafterLogo';
import TermsModal from '@/components/modals/TermsModal';
import PrivacyModal from '@/components/modals/PrivacyModal';

export default function LoginForm({ setSubmissionSuccess, onEmailEntered }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [formData, setFormData] = useState({
    purpose: 'authorization',
    email: '',
  });
  const [showTerms, setShowTerms] = useState(false);
  const [showPolicy, setShowPolicy] = useState(false);
  const [buttonSize, setButtonSize] = useState('md');

  const openTerms = () => {
    setShowTerms(true);
  };

  const closeTerms = () => {
    setShowTerms(false);
  };
  const openPolicy = () => {
    setShowPolicy(true);
  };

  const closePolicy = () => {
    setShowPolicy(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === 'email') {
      onEmailEntered(value); // Pass the entered email to the parent component
    }
  };

  const loginMutation = useMutation((data) => WebAppService.getOTP(data), {
    onSuccess: () => {
      setIsSubmitting(false);
      // ("OTP request successful");
      setSubmissionSuccess(true);
    },
    onError: (error) => {
      setIsSubmitting(false);
      console.error('OTP request failed:', error);
      // throw error;
    },
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formValid) {
      setIsSubmitting(true);
      loginMutation.mutate(formData);
    }
  };

  useEffect(() => {
    const { email } = formData;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = email.trim() !== '' && emailRegex.test(email);
    setFormValid(isValid);
  }, [formData]);

  useEffect(() => {
    // Determine the screen size and set button size accordingly
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setButtonSize('sm');
      } else if (window.innerWidth > 1024) {
        setButtonSize('lg');
      } else {
        setButtonSize('md');
      }
    };

    // Initial call to set the button size
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="flex flex-col gap-8 md:gap-8 pt-[60px] md:pt-0  md:m-auto items-center justify-center w-full">
      <div className="w-full flex flex-col gap-6">
        <div className="w-full flex flex-col items-center gap-6">
          <Link
            className="w-[120px] md:w-[120px] lg:w-[180px]"
            to={'https://usecrafter.com'}
            target="_blank"
          >
            <CrafterLogo />
          </Link>
        </div>
        <div className="flex m-auto h-fit px-5 md:px-10 lg:px-10 py-16 md:py-10 lg:py-10 w-[95%] md:w-[70%] lg:w-[70%] xl:w-[60%] xl:max-w-[550px] authBorder">
          <div className="flex flex-col items-start gap-7 text-left lg:text-center w-full lg:mx-auto">
            <h1 className="text-center text-white-1 font-bold text-[18px] md:text-[20px] lg:text-[24px]">
              Welcome back!
            </h1>
            <form
              onSubmit={handleSubmit}
              className="flex flex-col gap-6 w-full"
            >
              <div className="flex flex-col gap-6">
                <InputField4
                  name="email"
                  label="Company Email"
                  type="email"
                  placeholder="johndoe@usecrafter.com"
                  isRequired={true}
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>

              <CustomButton
                variant="secondary"
                size={buttonSize}
                state={isSubmitting || !formValid ? 'inactive' : 'default'}
                type="submit"
                disabled={!formValid || isSubmitting}
              >
                {isSubmitting ? 'Requesting...' : 'Request access code'}
              </CustomButton>
            </form>
            <div className="w-full">
              <p className="text-center text-gray-4 text-[12px] md:text-[14px] lg:text-[16px]">
                Don’t have an account?{' '}
                <Link
                  to="/signup"
                  className="text-blue-1 hover:underline underline-offset-4 font-semibold transition-all duration-500"
                >
                  Create an account
                </Link>
              </p>
            </div>
          </div>
        </div>
        {/* <p className="w-[90%] text-center mb-5 text-xs md:text-sm lg:text-sm">
        By logging in, you accept our{' '}
        <span
          onClick={openTerms}
          className="cursor-pointer text-blue-4 font-semibold"
        >
          Terms of service
        </span>{' '}
        and{' '}
        <span
          onClick={openPolicy}
          className="cursor-pointer text-blue-4 font-semibold"
        >
          Privacy Policy
        </span>
      </p> */}

        {showTerms && <TermsModal onClose={closeTerms} />}
        {showPolicy && <PrivacyModal onClose={closePolicy} />}
      </div>
    </div>
  );
}
