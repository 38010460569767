import React from 'react';
import GlobalButton from '@/components/ui/GlobalButton';

export default function Card1({ subscription }) {
  const subscriptionFeatures = subscription?.plan?.feature;
  // console.log(subscriptionFeatures);

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const decapitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toLowerCase() + string.slice(1);
  };

  return (
    <div className="px-4 py-5 flex flex-col gap-5 h-fit w-full border-0.5 border-gray-5/50 rounded-lg modalBg2">
      <ul className="w-full text-sm flex flex-col gap-5 list-disc list-outside pl-5 text-gray-4">
        <li>Chat with Crafter AI</li>
        <li>Access to Jira project insights and summary reports</li>
        <li>{`${capitalizeFirstLetter(
          subscriptionFeatures?.upload
        )} task generation from tech specs and PRDs`}</li>
        <li>{`Access to ${decapitalizeFirstLetter(
          subscriptionFeatures?.documentFormats
        )} supported document formats.`}</li>
        <li>{`${capitalizeFirstLetter(
          subscriptionFeatures?.editsSave
        )} task edits and saves`}</li>
        <li>{`${capitalizeFirstLetter(
          subscriptionFeatures?.csvDownloads
        )} CSV downloads`}</li>
        {/* <li>{`${capitalizeFirstLetter(
          subscriptionFeatures?.uploadToJira
        )} exports to Jira`}</li> */}
      </ul>
    </div>
  );
}
