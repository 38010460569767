import React from 'react';

export default function Terms({ title, message, list }) {
  return (
    <div className="flex flex-col gap-1">
      <h1 className="text-xl text-white-1 text-[18px] md:text-[20px] lg:text-[24px]">
        {title}
      </h1>
      <p className="text-[14px] md:text-[16px] lg:text-[16px]">{message}</p>

      {list ? (
        <ul className="flex flex-col gap-3 mt-2 list-disc list-outside">
          {list.map((item, index) => (
            <li
              className="ml-4 text-[14px] md:text-[16px] lg:text-[16px]"
              key={index}
            >
              {item}
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
}
