import React from 'react';

export default function ExportIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.76697 10.2333L9.9003 12.3667L12.0336 10.2333"
        stroke="#F0EEEE"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.90002 3.83331V12.3083"
        stroke="#F0EEEE"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.6667 10.65C16.6667 14.3333 14.1667 17.3167 10 17.3167C5.83337 17.3167 3.33337 14.3333 3.33337 10.65"
        stroke="#F0EEEE"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
