import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

const useIntegrationsStore = create(
  persist(
    (set) => ({
      selectedIntegration: '',
      updateSelectedIntegration: (integration) =>
        set({
          selectedIntegration: integration,
        }),
    }),
    {
      name: 'integrations',
      getStorage: () => createJSONStorage(() => sessionStorage),
    }
  )
);

export default useIntegrationsStore;
