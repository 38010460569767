import React from 'react';

export default function CustomModalOverlay2({
  open,
  onClose,
  title,
  width = '500px',
  children,
  withCloseButton,
  radius = '8px',
}) {
  if (!open) return null;

  return (
    <div className="modal fixed inset-0 flex items-center justify-center z-50">
      {/* Overlay */}
      <div
        onClick={onClose}
        className="modal-overlay absolute inset-0 bg-black-1 opacity-80"
      ></div>

      {/* Modal Content */}
      <div
        className="modalBg3 p-10 shadow-lg relative z-10"
        style={{ width, borderRadius: radius }} // Add the borderRadius style
      >
        {title && (
          <h2 className="text-2xl font-bold text-white-1 mb-4 border-b-1 border-gray-2/40 pb-4">
            {title}
          </h2>
        )}

        {children}

        {withCloseButton && (
          <button
            onClick={onClose}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          >
            &times;
          </button>
        )}
      </div>
    </div>
  );
}
