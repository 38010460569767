import React from 'react';

import { Link, NavLink } from 'react-router-dom';

export default function MenuItem({
  icon: Icon,
  title,
  link,
  target,
  collapsed,
  active,
}) {
  return (
    <NavLink
      to={link}
      target={target}
      className={`px-3 py-2 flex items-center gap-2 rounded-sm hover:bg-gray-7 ${
        active ? 'bg-gray-7 font-semibold text-white-1' : ''
      }`}
    >
      <div className="w-[50px] py-2 flex items-center justify-center">
        <Icon active={active} />
      </div>
      {!collapsed && <p>{title}</p>}
    </NavLink>
  );
}
