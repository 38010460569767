import React from 'react';
import { LineChartArrow } from '../../../../../icons/Indicators';
import { motion } from 'framer-motion';

export default function LineChartFrame({ status, value }) {
  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  function renderTextStyles() {
    switch (status) {
      case 'completed':
        return 'text-green-3';
      case 'active':
        return 'text-blue-1';
      case 'dormant':
        return 'text-orange-4';
      case 'unassigned':
      case 'inactive':
        return 'text-gray-3';
      case 'todo':
        return 'text-[#684C91]';
      default:
        return '';
    }
  }
  function renderBgStyles() {
    switch (status) {
      case 'completed':
        return 'bg-green-3';
      case 'active':
        return 'bg-blue-1';
      case 'dormant':
        return 'bg-orange-4';
      case 'unassigned':
      case 'inactive':
        return 'bg-gray-3';
      case 'todo':
        return 'bg-[#684C91]';
      default:
        return '';
    }
  }
  function renderBgStyles2() {
    switch (status) {
      case 'completed':
        return 'bg-gradient-to-t from-green-3 to-transparent';
      case 'active':
        return 'bg-gradient-to-t from-blue-1 to-transparent';
      case 'dormant':
        return 'bg-gradient-to-t from-orange-4 to-transparent';
      case 'unassigned':
      case 'inactive':
        return 'bg-gradient-to-t from-gray-3 to-transparent';
      case 'todo':
        return 'bg-gradient-to-t from-[#684C91] to-transparent';
      default:
        return '';
    }
  }
  function renderBgStyles3() {
    switch (status) {
      case 'completed':
        return 'bg-gradient-to-t from-green-3/20 to-transparent';
      case 'active':
        return 'bg-gradient-to-t from-blue-1/30 to-transparent';
      case 'dormant':
        return 'bg-gradient-to-t from-orange-4/40 to-transparent';
      case 'unassigned':
      case 'inactive':
        return 'bg-gradient-to-t from-gray-3/40 to-transparent';
      case 'todo':
        return 'bg-gradient-to-t from-[#684C91]/40 to-transparent';
      default:
        return '';
    }
  }
  const _status = capitalizeFirstLetter(status);

  return (
    <>
      <motion.div
        initial={{
          width: 0,
        }}
        animate={{
          width: `20%`,
        }}
        transition={{
          duration: 1,
        }}
        className={`z-0 relative min-w-[30px] h-full  flex flex-col  border-l-2 border-black-1/80 `}
      >
        <div className={`${renderTextStyles()} ${'px-3'} py-2`}>
          <p className={`text-[10px]`}>{_status} tasks</p>

          <div className="flex items-center gap-1">
            <LineChartArrow />
            <p className="text-[10px]">{value}%</p>
          </div>
        </div>
        <div
          style={{
            height: `${value}%`,
          }}
          className={`absolute left-0 bottom-0 w-full flex flex-col  ${renderBgStyles3()}`}
        ></div>
        <div
          className={`w-[2px]  absolute -left-[1.5px] bottom-0 bg-gradient-to-b   ${renderBgStyles2()}`}
          style={{
            height: `${value}%`,
          }}
        ></div>
        <div className={`${renderBgStyles()} h-[2px] mt-auto`}></div>
      </motion.div>
    </>
  );
}
