import React, { useEffect, useState } from 'react';

import emailGif from '@/assets/emailGif.gif';
import GlobalButton from '@/components/ui/GlobalButton';
import WebAppService from '@/services/WebAppService';
import CrafterLogo from '../../../icons/CrafterLogo';
import { Link } from 'react-router-dom';

export default function CheckYourMail({ onContinue, enteredEmail }) {
  const [buttonSize, setButtonSize] = useState('md');
  const [imageWidth, setImageWidth] = useState(120);

  const handleContinueClick = () => {
    onContinue();
  };

  useEffect(() => {
    // Determine the screen size and set button size accordingly
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setButtonSize('sm');
      } else if (window.innerWidth > 1024) {
        setButtonSize('lg');
      } else {
        setButtonSize('md');
      }
    };

    // Initial call to set the button size
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="flex flex-col gap-8 md:gap-8 pt-[60px] md:pt-0  md:m-auto items-center justify-center w-full">
      <div className="w-full flex flex-col gap-6">
        <div className="w-full flex flex-col items-center gap-6">
          <Link
            className="w-[120px] md:w-[120px] lg:w-[180px]"
            to={'https://usecrafter.com'}
            target="_blank"
          >
            <CrafterLogo />
          </Link>
        </div>
        <div className="flex m-auto h-fit px-5 md:px-10 lg:px-10 py-16 md:py-10 lg:py-10 w-[95%] md:w-[70%] lg:w-[70%] xl:w-[60%] xl:max-w-[550px] authBorder">
          <div className="flex flex-col gap-y-8 w-full mx-auto">
            <div className="flex flex-col m-auto items-center justify-center mt-[-20px] md:mt-[-40px] lg:mt-[-40px]">
              <img width={imageWidth} src={emailGif} alt="emailGif" />
            </div>
            <div className="flex flex-col gap-y-1 m-auto text-center w-4/5">
              <h1 className="text-center text-white-1 font-bold text-[18px] md:text-[20px] lg:text-[24px] tracking-tight">
                You've got mail!
              </h1>
              <p className="text-[12px] md:text-[14px] lg:text-[14px] text-gray-6">
                Check your inbox and copy the one-time password (OTP) to
                proceed.
              </p>
            </div>

            <div className="w-4/5 m-auto">
              <GlobalButton
                onClick={handleContinueClick}
                variant={'blue'}
                state={'default'}
                size={buttonSize}
              >
                Continue
              </GlobalButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
