import React, { useEffect, useState } from 'react';
import Caret from '../../icons/Caret';
import ProjectsIcon from '../../icons/ProjectsIcon';
import LoadingComponent from '../LoadingComponent';
import useInsightsStore from '../../store/Insights';
import WebAppService from '../../services/WebAppService';

export default function EpicDropdown({
  id,
  name,
  disabled,
  label,
  isRequired,
  options,
  placeholder,
  isProject,
  onChange,
  value,
  fetchData,
  setIsRefreshing,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value || {});
  const [loading, setLoading] = useState(false);
  const {
    selectedProject,
    updateSelectedEpic,
    setChildrenLoading,
    updateJiraProjectEpicChildren,
  } = useInsightsStore();
  const selectedProjectKey = selectedProject.key;
  const epicId = selectedOption.key;

  const handleOpenDropdown = async (e) => {
    e.preventDefault();
    if (
      !isOpen &&
      fetchData &&
      typeof fetchData === 'function' &&
      selectedOption === ''
    ) {
      setIsOpen(true);
      setLoading(true);
      await fetchData();
      setLoading(false);
    }
    setIsOpen(!isOpen);
  };

  const handleGetEpicChildren = async (selectedProjectKey, epicId) => {
    try {
      setChildrenLoading(true);
      setIsRefreshing(true);
      const response = await WebAppService.getChildrenOfAnEpic(
        selectedProjectKey,
        epicId
      );
      const epicChildren = response.data;
      updateJiraProjectEpicChildren(epicChildren);
    } catch (error) {
      console.error(error);
    } finally {
      setChildrenLoading(false);
      setIsRefreshing(false);
    }
  };

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    if (onChange && typeof onChange === 'function') {
      onChange(option);
    }
    // console.log(option);
    updateSelectedEpic(option);

    // console.log(selectedProjectKey);
    // console.log(option.key);
    handleGetEpicChildren(selectedProjectKey, option.key);
    setIsOpen(false);
  };

  return (
    <div className="w-full flex flex-col items-start gap-2">
      <label className="text-sm text-gray-4 font-semibold">
        {label}
        {isRequired && <span className="text-sm text-error-1">*</span>}
      </label>
      <div className="w-full relative">
        <button
          disabled={disabled}
          id={id}
          name={name}
          onClick={handleOpenDropdown}
          className={`flex w-full h-[40px] items-center gap-2 justify-between ${
            selectedOption ? 'text-white-1' : 'text-gray-2'
          } ${disabled ? 'cursor-not-allowed' : ''} text-gray-2 text-white bg-transparent rounded-md py-[10px] px-[14px] border-1 border-gray-2 focus:outline-none`}
        >
          <span className="flex items-center gap-2">
            {isProject && <ProjectsIcon />}
            {selectedOption && selectedOption.key && selectedOption.summary ? (
              <p className="text-white-1 font-semibold capitalize">
                {selectedOption.key}: {selectedOption.summary}
              </p>
            ) : (
              <p className="text-gray-4 font-normal">{placeholder}</p>
            )}
          </span>
          <Caret />
        </button>

        {isOpen && (
          <div className="rounded-md absolute w-full mt-2 max-h-[150px] overflow-y-scroll flex flex-col items-start text-gray-4 modalBg">
            {loading && <LoadingComponent />}
            {!loading && options.length > 0 && (
              <ul className="w-full">
                {options.map((option, index) => (
                  <li
                    key={index}
                    onClick={() => handleSelectOption(option)}
                    className="w-full text-left py-3 px-4 border-b-0.5 border-black-1 text-gray-4 hover:text-white-2 cursor-pointer capitalize"
                  >
                    {option.key}: {option.summary}
                  </li>
                ))}
              </ul>
            )}
            {!loading && options.length === 0 && (
              <p className="w-full text-center py-6 px-4 text-gray-4">
                No epics found
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
