import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

const CustomButton = ({
  children,
  className,
  onClick,
  onBlur,
  to,
  variant,
  size,
  type,
  state,
  ...rest
}) => {
  const buttonClass = clsx(
    'rounded-full',
    'font-semibold',
    'focus:outline-none',
    'transition',
    'w-full',
    'duration-300',
    'ease-in-out',
    'text-center',

    {
      // Variant styles
      'border-none bg-transparent':
        variant === 'transparent' && state === 'default',
      'btn-primary text-white-1': variant === 'primary' && state === 'default',
      'btn-secondary text-gray-2':
        variant === 'secondary' && state === 'default',
      'btn-blue text-white-1': variant === 'blue' && state === 'default',
      'btn-stroke text-gray-4': variant === 'stroke' && state === 'default',
      'btn-red-stroke text-error-2':
        variant === 'redStroke' && state === 'default',
      'btn-secondary-red text-error-3 border border-red-2':
        variant === 'secondaryRed' && state === 'default',

      // Size styles
      'py-2 px-4 text-sm h-[36px]': size === 'sm',
      'py-3 px-6 text-sm h-[44px]': size === 'md',
      'py-4 px-8 h-[56px]': size === 'lg',

      // State styles
      'cursor-pointer': state === 'default' || state === 'hover',
      'cursor-not-allowed btn-primary2 text-black-1 border-1 border-black-1':
        state === 'inactive',
    },
    className,
    rest.className
  );

  if (to) {
    return (
      <Link to={to}>
        <a
          href={to}
          className={buttonClass}
          onClick={state !== 'default' ? (e) => e.preventDefault() : undefined}
          onBlur={onBlur}
        >
          {children}
        </a>
      </Link>
    );
  } else {
    return (
      <>
        <button
          type={type}
          className={buttonClass}
          onClick={onClick}
          onBlur={onBlur}
          disabled={state === 'inactive'}
          {...rest}
        >
          {children}
        </button>
      </>
    );
  }
};

export default CustomButton;
