import React from 'react';

const Step4 = () => {
  return (
    <div className="flex flex-col items-start p-0">
      <span className="font-semibold text-[12px]">3 of 5</span>

      <p className="mt-3 text-[12px] text-left">
        Get to know when your project was last synced with Crafter.
      </p>
    </div>
  );
};

export default Step4;
