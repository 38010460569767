import React from 'react';
import useInsightsStore from '../../../store/Insights';
import { LineChartArrow } from '../../../icons/Indicators';
import Timestamp from '../../../icons/Timestamp';
import DateSince2 from '../../../components/DateSince/DateSince2';
import Badge2 from '../../../components/ui/Badge2';
import { Stale, UpTrend } from '../../../icons/TrendIcons';
import Badge from '../../../components/ui/Badge';

export default function PDFTemplate() {
  const { selectedEpic, jiraProjectEpicChildren } = useInsightsStore();
  const issueStatus = jiraProjectEpicChildren?.completionStatus;
  const progress = Math.round(jiraProjectEpicChildren?.progressPercentage);

  const data = [
    {
      status: 'completed',
      value: Math.round(issueStatus?.completedPercentage) || 0,
    },
    {
      status: 'active',
      value: Math.round(issueStatus?.activePercentage) || 0,
    },
    {
      status: 'dormant',
      value: Math.round(issueStatus?.dormantPercentage) || 0,
    },
    {
      status: 'todo',
      value: Math.round(issueStatus?.toDoPercentage) || 0,
    },
    {
      status: 'unassigned',
      value: Math.round(issueStatus?.unassignedPercentage) || 0,
    },
  ];

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // Function to map status to solid background styles
  const getStatusStyles = (status) => {
    const styles = {
      completed: {
        text: 'text-green-3',
        bg: 'bg-green-3',
        bar: 'bg-green-3',
      },
      active: {
        text: 'text-blue-1',
        bg: 'bg-blue-1',
        bar: 'bg-blue-1',
      },
      dormant: {
        text: 'text-orange-4',
        bg: 'bg-orange-4',
        bar: 'bg-orange-4',
      },
      todo: {
        text: 'text-[#684C91]',
        bg: 'bg-[#684C91]',
        bar: 'bg-[#684C91]',
      },
      unassigned: {
        text: 'text-gray-3',
        bg: 'bg-gray-3',
        bar: 'bg-gray-3',
      },
    };

    return styles[status] || {};
  };

  return (
    <div
      className="py-[50px] bg-black-6 h-[1122px] flex flex-col gap-4"
      id="pdf-container"
    >
      <div id="chat-input" className="flex flex-col gap-3">
        <p className={`text-center font-bold text-2xl text-white-2`}>
          {selectedEpic.key}: {selectedEpic.summary}
        </p>
      </div>

      <span className="flex gap-2 items-center justify-center text-gray-4">
        <Timestamp />
        <p className="font-bold">Project age: </p>
        <DateSince2 date={selectedEpic?.created} />
      </span>

      <div className="w-full flex flex-row gap-5 justify-center mt-5">
        <div
          id="insight-charts"
          className="cursor-pointer chart-container-bg rounded-xl w-[80%] h-full px-[18px] py-[30px]"
        >
          <header className="w-full flex gap-4 items-center justify-between">
            <span className="flex items-center w-fit gap-2">
              <label className="text-[12px] text-gray-1">Status</label>
              <div className="w-full">
                <Badge withPointer status={selectedEpic?.status} />
              </div>
            </span>
            <span className="flex items-center w-fit gap-2">
              <label className="text-[12px] text-gray-1 w-fit flex-shrink-0">
                Project progress{' '}
              </label>
              <div className="w-full">
                {typeof progress !== 'number' || isNaN(progress) ? (
                  <Badge2>
                    <span className="text-[12px]">...............</span>
                    <Stale />
                  </Badge2>
                ) : (
                  <Badge2>
                    <span className="text-[12px] text-gray-1">{progress}%</span>
                    <UpTrend />
                  </Badge2>
                )}
              </div>
            </span>
          </header>
          <div className="flex w-full h-[200px] gap-2 relative mt-[25px]">
            <div className="flex w-full h-full gap-2">
              {data.map((item, index) => {
                const styles = getStatusStyles(item.status);
                const capitalizedStatus = capitalizeFirstLetter(item.status);
                const adjustedHeight =
                  !isNaN(item.value) && item.value > 0 ? item.value : 1;

                return (
                  <div
                    key={index}
                    className={`z-0 relative w-full min-w-[30px] h-full flex flex-col border-l-2 border-black-1/80`}
                  >
                    <div className={`${styles.text} px-3 py-2`}>
                      <p className="text-[10px]">{capitalizedStatus} tasks</p>
                      <div className="flex items-center gap-1">
                        <LineChartArrow />
                        <p className="text-[10px]">{item.value}%</p>
                      </div>
                    </div>

                    <div
                      style={{ height: `${adjustedHeight}%` }}
                      className={`absolute left-0 bottom-0 w-full flex flex-col ${styles.bg}`}
                    ></div>

                    <div
                      className={`w-[2px] absolute -left-[1.5px] bottom-0 ${styles.bar}`}
                      style={{ height: `${adjustedHeight}%` }}
                    ></div>

                    <div className={`${styles.bg} h-[2px] mt-auto`}></div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
