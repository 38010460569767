import React, { useState } from 'react';
import GlobalButton from '@/components/ui/GlobalButton';
import SuccessToast from '@/components/modals/SuccessToast';

export default function ConfirmDelete({ onClose, onDelete, isDeleting, type }) {
  return (
    <div className="modal">
      <div
        onClick={(event) => {
          event.stopPropagation();
          onClose();
        }}
        className="modal-overlay absolute inset-0 bg-black-1/40 opacity-90"
      ></div>

      <div className="modalBg px-8 py-10 w-[90%] md:w-[90%] lg:w-[40%] xl:max-w-[700px] max-h-[90%] h-fit top-1/2 left-1/2 absolute border-0.5 border-gray-2/50 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-7 items-center rounded-lg shadow-md text-white-1">
        <div className="flex flex-col gap-2 text-center">
          <h1 className="font-semibold text-xl">Delete {type}?</h1>

          <p className="text-gray-4">
            Are you sure you want to delete this {type} from this project?
          </p>
        </div>

        <hr className="border-0.5 border-black-1 w-full" />

        <div className="flex gap-5 w-full">
          <div className="w-full">
            <GlobalButton
              onClick={(event) => {
                event.stopPropagation();
                onDelete();
              }}
              variant={'blue'}
              size={'lg'}
              state={isDeleting ? 'inactive' : 'default'}
            >
              {isDeleting ? 'Deleting...' : `Delete ${type}`}
            </GlobalButton>
          </div>

          <div className="w-full">
            <GlobalButton
              onClick={(event) => {
                event.stopPropagation();
                onClose();
              }}
              variant={`redStroke`}
              state={`default`}
              size={`lg`}
              className={``}
            >
              Cancel
            </GlobalButton>
          </div>
        </div>
      </div>
    </div>
  );
}
