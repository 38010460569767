import { useState } from 'react';
import useInsightsStore from '../../../store/Insights';
import styles from './PromptInput.module.css';
import useChatsStore, { fetchAIResponse } from '../../../store/Chats';

export default function PromptInput1({ isTourStarted }) {
  const { selectedEpic, setInsightActiveComponent, selectedProject } =
    useInsightsStore();

  const { inputValue, setInputValue } = useChatsStore();
  const [focusTrap, setFocusTrap] = useState(false);

  const disableInput = !isTourStarted && !selectedEpic;
  const disableButton = !selectedEpic || inputValue.trim() === '';

  const handleSend = async () => {
    if (!inputValue.trim()) return;

    setInsightActiveComponent('chats');

    try {
      await fetchAIResponse(inputValue, selectedProject.key, selectedEpic.key);
      setInputValue('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Prevent default "Enter" behavior (like form submission).
      if (!disableButton) {
        handleSend();
      }
    }
  };

  return (
    <div
      className={`${styles.prompt_input_bg} ${
        disableInput ? 'opacity-90' : 'opacity-100'
      } px-5 w-full h-[64px] flex items-center gap-5 rounded-full cursor-text`}
    >
      <input
        className="w-full h-full bg-transparent border-none focus:outline-none placeholder:text-gray-3 text-white-3"
        type="text"
        onFocus={focusTrap}
        onKeyDown={handleKeyDown} // Add keydown handler
        placeholder="Chat with Crafter"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        disabled={disableInput}
      />

      <button
        disabled={disableButton}
        onClick={handleSend}
        className={`${styles.prompt_button_1} w-fit p-[6px] h-fit rounded-full flex items-center justify-center ${
          disableButton ? 'opacity-50 cursor-not-allowed' : ''
        }`}
      >
        <div
          className={`-rotate-45 w-[30px] h-[30px] ${
            disableButton ? 'bg-black-1' : 'text-[#016C8E]'
          }`}
        >
          <ButtonSvg />
        </div>
      </button>
    </div>
  );
}

const ButtonSvg = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.082 9.92776L11.0953 4.93442C4.38697 1.57442 1.63364 4.32776 4.99364 11.0361L6.00864 13.0661C6.30031 13.6611 6.30031 14.3494 6.00864 14.9444L4.99364 16.9628C1.63364 23.6711 4.37531 26.4244 11.0953 23.0644L21.082 18.0711C25.562 15.8311 25.562 12.1678 21.082 9.92776ZM17.3136 14.8744H11.0136C10.5353 14.8744 10.1386 14.4778 10.1386 13.9994C10.1386 13.5211 10.5353 13.1244 11.0136 13.1244H17.3136C17.792 13.1244 18.1886 13.5211 18.1886 13.9994C18.1886 14.4778 17.792 14.8744 17.3136 14.8744Z"
        fill="currentColor"
      />
    </svg>
  );
};
