import React, { useEffect, useState } from 'react';
import { formatDistanceToNow } from 'date-fns'; // Import the necessary function
import ProjectsIcon from '@/icons/ProjectsIcon';
import MoreIcon from '@/icons/MoreIcon';
import EditIconSm from '@/icons/EditIconSm';
import JiraIconSm from '@/icons/JiraIconSm';
import TrashIconSm from '@/icons/TrashIconSm';
import EyeIcon from '@/icons/EyeIcon';
import SendToJiraModal from '@/components/modals/SendToJiraModal';
import ErrorToast from '@/components/modals/ErrorToast';
import ConfirmDelete from '@/components/modals/ConfirmDelete';
import SuccessToast from '@/components/modals/SuccessToast';
import WebAppService from '@/services/WebAppService';
import { useNavigate } from 'react-router';
import { useProjects } from '../../context/ProjectsContext';
import { useToast } from '../../context/ToastContext';

export default function Project2({ project, updateProjects }) {
  const { refetchProjects } = useProjects();
  const [showModal, setShowModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [sendToJira, setSendToJira] = useState(false);
  const [clicked, setClicked] = useState(false);
  // const [showErrorToast, setShowErrorToast] = useState(false);
  // const [errorMessage, setErrorMessage] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  // const [showSuccessToast, setShowSuccessToast] = useState(false);
  const navigate = useNavigate();
  const { addToast } = useToast();

  const openDropdown = (event) => {
    event.stopPropagation();
    setClicked(true);
    setShowDropdown(true);
  };
  const closeDropdown = () => {
    setClicked(false);
    setShowDropdown(false);
  };

  const openSendToJira = () => {
    // console.log("Open send to Jira");
    setSendToJira(true);
  };

  const closeSendToJira = () => {
    setSendToJira(false);
  };

  const openConfirmDelete = () => {
    // console.log("Open Confirm Delete");
    setConfirmDelete(true);
  };

  const closeConfirmDelete = () => {
    setConfirmDelete(false);
  };

  const tasks = project?.tasks;

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const dropdownElement = document.querySelector('.dropdown'); // Adjusted selector

      // Check if the clicked element is inside the dropdown
      if (dropdownElement && dropdownElement.contains(event.target)) {
        return;
      }

      // If not inside dropdown, close the dropdown
      setClicked(false);
      setShowDropdown(false);
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const createdAtDate = new Date(project.created_at);
  // Format the date as "20 hours ago" or similar
  let formattedCreatedAt = formatDistanceToNow(createdAtDate, {
    addSuffix: true,
    includeSeconds: true,
  });

  // Remove the "about" prefix if it exists
  if (formattedCreatedAt.startsWith('about')) {
    formattedCreatedAt = formattedCreatedAt.replace('about', '').trim();
  }
  if (formattedCreatedAt.startsWith('less than')) {
    formattedCreatedAt = formattedCreatedAt.replace('less than', '').trim();
  }

  const projectName = project?.name;
  const projectId = project?.id;

  const lastDotIndex = projectName.lastIndexOf('.');

  // Check if "." exists in the string and get the substring before it
  const formattedProjectName =
    lastDotIndex !== -1 ? projectName.substring(0, lastDotIndex) : projectName;

  const handleNavigate = () => {
    navigate(`/projects/${project.id}`);
  };

  const handleDeleteProject = async () => {
    setIsDeleting(true);

    try {
      const deleteProjectResponse =
        await WebAppService.deleteProjectById(projectId);

      if (deleteProjectResponse?.success) {
        setTimeout(() => {
          setIsDeleting(false);
        }, 2900);

        setTimeout(() => {
          setConfirmDelete(false);
        }, 3000);

        addToast('Project deleted successfully', 'success', 3000);

        // setTimeout(() => {
        // 	setShowSuccessToast(true);
        // }, 3000);

        // setTimeout(() => {
        // 	setShowSuccessToast(false);
        // }, 4000);

        updateProjects(projectId);
        refetchProjects();
      }
    } catch (error) {
      setTimeout(() => {
        setIsDeleting(false);
      }, 2900);

      setTimeout(() => {
        setConfirmDelete(false);
      }, 3000);

      addToast(
        `${error?.response?.data?.error || 'Error deleting project'}`,
        'error',
        3000
      );
      refetchProjects();

      // setTimeout(() => {
      // 	setErrorMessage(
      // 		error?.response?.data?.error || "Error deleting project"
      // 	);
      // 	setShowErrorToast(true);
      // }, 3000);
      // setTimeout(() => {
      // 	setShowErrorToast(false);
      // 	refetchProjects();
      // }, 4000);
    }
  };

  return (
    <div className="h-[45px] grid grid-cols-1 w-full">
      <div
        onClick={tasks.length <= 0 ? null : handleNavigate}
        className={`${
          tasks.length <= 0
            ? 'cursor-not-allowed text-gray-2 opacity-100 bg-black-3'
            : 'cursor-pointer text-gray-4 projectBg hover:text-gray-5 '
        } flex items-center justify-between  px-5 py-3 rounded-md transition-colors duration-300`}
      >
        <div className="flex gap-2">
          {/* <p>{project.label}</p> */}
          <ProjectsIcon />
          <p className="lg:text-[16px] xl:text-[16px] 2xl:text-[16px]">
            {formattedProjectName}
          </p>
        </div>

        <div className="flex items-center gap-2 lg:text-[12px] xl:text-[12px] 2xl:text-[14px]">
          <p>Created {formattedCreatedAt}</p>
          <MoreIcon
            className={`text-blue-3 hover:text-gray-4 cursor-pointer`}
            onClick={openDropdown}
            clicked={clicked}
          />
        </div>

        {showDropdown && (
          <div className="absolute dropdown right-[90px] z-[1000] text-gray-4">
            <div className="w-[100%] bg-black-5 z-50 border border-gray-5 text-xs h-fit px-1 py-2 shadow-lg rounded-md">
              <ul className="w-full flex flex-col items-start gap-1">
                {tasks.length > 0 ? (
                  <>
                    <button
                      onClick={(event) => {
                        event.stopPropagation();
                        openSendToJira();
                      }}
                      className="flex gap-1 px-2 py-2 w-full rounded-sm hover:bg-black-1 items-center"
                    >
                      <JiraIconSm />
                      Send to Jira
                    </button>
                    <button
                      onClick={handleNavigate}
                      className="flex gap-1 px-2 py-2 w-full rounded-sm hover:bg-black-1 items-center"
                    >
                      <EyeIcon />
                      View Project
                    </button>
                  </>
                ) : null}
                <button
                  onClick={(event) => {
                    event.stopPropagation();
                    openConfirmDelete();
                  }}
                  className="text-error-1 flex gap-1 px-2 py-2 w-full rounded-sm hover:bg-black-1 items-center"
                >
                  <TrashIconSm />
                  Delete Project
                </button>
              </ul>
            </div>
          </div>
        )}
        {confirmDelete && (
          <ConfirmDelete
            isDeleting={isDeleting}
            onDelete={handleDeleteProject}
            onClose={closeConfirmDelete}
            type={`project`}
          />
        )}
        {/* 
				{showErrorToast && <ErrorToast message={errorMessage} />}
				{showSuccessToast && (
					<SuccessToast message={`Project deleted successfullyyy`} />
				)} */}

        {sendToJira && (
          <SendToJiraModal
            type={`project`}
            projectId={project.id}
            onClose={closeSendToJira}
          />
        )}
      </div>
    </div>
  );
}
