import React from 'react';

export default function JiraIconSm() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1645 0H9.53353C9.53353 1.15305 9.99158 2.25887 10.8069 3.0742C11.6222 3.88953 12.7281 4.34757 13.8811 4.34757H15.6552V6.06053C15.6568 8.45945 17.6011 10.4038 20 10.4054V0.835487C20 0.374191 19.626 0 19.1645 0Z"
        fill="#2684FF"
      />
      <path
        d="M14.3991 4.79877H4.76822C4.7697 7.19768 6.71398 9.14203 9.11297 9.1436H10.8871V10.862C10.8902 13.261 12.8358 15.204 15.2347 15.204V5.63441C15.2347 5.17296 14.8606 4.79877 14.3991 4.79877Z"
        fill="url(#paint0_linear_2204_4359)"
      />
      <path
        d="M9.63098 9.59479H0C0 11.9959 1.94655 13.9424 4.34757 13.9424H6.12727V15.6552C6.12884 18.052 8.06975 19.9955 10.4665 20V10.4304C10.4665 9.9689 10.0924 9.59479 9.63098 9.59479Z"
        fill="url(#paint1_linear_2204_4359)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2204_4359"
          x1="5005.93"
          y1="9.2079"
          x2="3007.1"
          y2="2331.46"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.18" stop-color="#0052CC" />
          <stop offset="1" stop-color="#2684FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2204_4359"
          x1="2690.13"
          y1="23.1332"
          x2="1488.29"
          y2="1347.45"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.18" stop-color="#0052CC" />
          <stop offset="1" stop-color="#2684FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
