import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import UploadDocModal from '@/components/modals/UploadDocModal';
import PlusIcon from '@/icons/PlusIcon';
import LinkIcon from '@/icons/LinkIcon';
import GoogleDocModal from '@/components/modals/GoogleDocModal';
import Project2 from './Project2';
import Empty from '@/components/ui/Empty';
import LoadingComponent from '@/components/LoadingComponent';
import { useToast } from '../../context/ToastContext';
import WebAppService from '../../services/WebAppService';

export default function Body({
  onCreateTickets,
  sentProjects,
  setSentProjects,
  setCreateTicketsResponse,
  projects,
  loading,
  refetchProjects,
}) {
  const [showUploadDocModal, setShowUploadDocModal] = useState(false);
  const [showGoogleDocModal, setShowGoogleDocModal] = useState(false);
  const [visibleProjects, setVisibleProjects] = useState([]);
  const containerRef = useRef(null);
  const { addToast } = useToast();

  const openUploadDocModal = useCallback(() => {
    setShowUploadDocModal(true);
  }, []);

  const closeUploadDocModal = () => {
    setShowUploadDocModal(false);
  };
  const openGoogleDocModal = useCallback(() => {
    setShowGoogleDocModal(true);
  }, []);

  const closeGoogleDocModal = () => {
    setShowGoogleDocModal(false);
  };

  const updateProjects = (deletedProjectId) => {
    setSentProjects((prevProjects) =>
      prevProjects.filter((project) => project.id !== deletedProjectId)
    );
  };

  const updateVisibleProjects = () => {
    if (containerRef.current) {
      const containerHeight = containerRef.current.offsetHeight;
      const itemHeight = 65;
      const visibleCount = Math.floor(containerHeight / itemHeight);
      setVisibleProjects(projects.slice(0, visibleCount));
    }
  };

  useEffect(() => {
    updateVisibleProjects();
  }, [projects, containerRef]);

  useEffect(() => {
    window.addEventListener('resize', updateVisibleProjects);
    return () => {
      window.removeEventListener('resize', updateVisibleProjects);
    };
  }, []);

  return (
    <div
      className={`w-full h-screen pt-[65px] pb-[45px] px-4 text-sm text-white-1 overflow-hidden `}
    >
      <div className="flex bg3 flex-col gap-8 w-full h-full pt-8 px-8 pb-4 mt-8 mb-8 border border-gray-2/30 rounded-md overflow-hidden innerFrameBg">
        <div className="flex flex-col gap-4 w-full px-6 py-6 rounded-xl bg1">
          <div className="flex w-full items-center justify-between">
            <div className="w-5/5 flex flex-col gap-[6px]">
              <h2 className="font-bold lg:text-[16px] xl:text-[16px]">
                Create new projects
              </h2>
              <p className="text-gray-6 w-full lg:text-[14px] xl:text-[14px]">
                Upload product requirements documents, tech specs, flow charts
                or system architecture diagrams to create tasks for your
                project.
              </p>
            </div>
          </div>
          <div className="w-full flex gap-8 ">
            <div className="w-1/2 uploadHolderBg p-6 h-fit rounded-md">
              <div className="uploadHolderBg2 flex flex-col gap-3 p-6 rounded-md">
                <div
                  onClick={openUploadDocModal}
                  className="cursor-pointer text-center flex gap-2 bg-blue-2 px-[16px] py-[10px] rounded-lg w-3/5 mx-auto items-center justify-center lg:text-[14px] xl:text-[14px] font-semibold"
                >
                  <PlusIcon />
                  Upload Document
                </div>

                <p className="text-gray-6 text-center lg:text-[14px] xl:text-[14px] leading-[21px] w-4/5 mx-auto">
                  File should be in the following format TXT, PDF, DOCX (Word),
                  JPG, WEBP and PNG{' '}
                  <span className="text-orange-3">(10mb max upload)</span>
                </p>
              </div>
            </div>
            <div className="w-1/2 uploadHolderBg p-6 h-fit rounded-md">
              <div className="uploadHolderBg2 flex flex-col gap-3 p-6 rounded-md w-full">
                <div
                  onClick={openGoogleDocModal}
                  className="flex items-center text-center cursor-pointer justify-center rounded-lg gap-3 googleInputBg px-[16px] py-[10px] border-0.5 border-blue-3 lg:text-[14px] xl:text-[14px] text-blue-3 w-[75%] mx-auto"
                >
                  <LinkIcon />
                  <p>Add the link to your Google document</p>
                </div>
                <p className="text-gray-6 text-center lg:text-[14px] xl:text-[14px] w-4/5 mx-auto leading-[21px]">
                  Viewer access to your document will be needed for Crafter to
                  generate tasks.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex h-full flex-col gap-y-4 overflow-hidden">
          <div className="flex items-center justify-between">
            <h3 className="font-semibold lg:text-[16px] xl:text-[16px]">
              Your projects
            </h3>
            <Link
              className="text-blue-3 font-semibold lg:text-[14px] xl:text-[14px] underline underline-offset-8"
              to={'/projects'}
            >
              See all projects
            </Link>
          </div>

          <div
            ref={containerRef}
            className={`overflow-scroll h-full p-2 rounded-md`}
          >
            {loading ? (
              <div className="flex justify-center items-center h-full">
                <LoadingComponent />
              </div>
            ) : visibleProjects.length > 0 ? (
              <div className="flex h-fit flex-col gap-y-4 overflow-scroll">
                {visibleProjects.map((project, index) => (
                  <div key={index}>
                    <Project2
                      updateProjects={updateProjects}
                      project={project}
                    />
                  </div>
                ))}
              </div>
            ) : (
              // Show message if there are no projects
              <div className="flex h-full justify-center gap-y-4 projectsBg">
                <Empty
                  message={'You currently have no project'}
                  text={
                    'Create a new project to analyze your product specs and tech documents'
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {showUploadDocModal && (
        <UploadDocModal
          onClose={closeUploadDocModal}
          onCreateTickets={onCreateTickets}
          sentProjects={sentProjects}
          setSentProjects={setSentProjects}
          setCreateTicketsResponse={setCreateTicketsResponse}
        />
      )}
      {showGoogleDocModal && (
        <GoogleDocModal
          onClose={closeGoogleDocModal}
          onCreateTickets={onCreateTickets}
          sentProjects={sentProjects}
          setSentProjects={setSentProjects}
          setCreateTicketsResponse={setCreateTicketsResponse}
        />
      )}
    </div>
  );
}
