import React, { useState } from 'react';
import Badge from '../../../components/ui/Badge';
import Badge2 from '../../../components/ui/Badge2';
import { Stale, UpTrend } from '../../../icons/TrendIcons';
import GlobalDropdown4 from '../../../components/ui/GlobalDropdown4';
import { DocumentCopy } from '../../../icons/Document';
import LineChart from './LineChart/LineChart';
import PieChart from './PieChart/PieChart';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useInsightsStore from '../../../store/Insights';

export default function Charts({ ref }) {
  const [selectedChart, setSelectedChart] = useState('LineChart');
  const { selectedEpic, jiraProjectEpicChildren } = useInsightsStore();
  const epicId = selectedEpic?.key;
  const navigate = useNavigate();
  const issueStatus = jiraProjectEpicChildren?.completionStatus;
  const progress = Math.round(jiraProjectEpicChildren?.progressPercentage);

  const data = [
    {
      status: 'completed',
      value: Math.round(issueStatus?.completedPercentage),
    },
    {
      status: 'active',
      value: Math.round(issueStatus?.activePercentage),
    },
    {
      status: 'dormant',
      value: Math.round(issueStatus?.dormantPercentage),
    },
    {
      status: 'todo',
      value: Math.round(issueStatus?.toDoPercentage),
    },
    {
      status: 'unassigned',
      value: Math.round(issueStatus?.unassignedPercentage),
    },
  ];

  const chartOptions = [
    {
      label: 'Line chart',
      value: 'LineChart',
    },
    {
      label: 'Pie chart',
      value: 'PieChart',
    },
  ];

  // Function to handle chart selection
  const handleChartSelection = (selectedValue) => {
    setSelectedChart(selectedValue);
  };

  return (
    <>
      <header className="w-full flex gap-4 items-center pt-4 px-3">
        <span className="flex items-center w-fit gap-2">
          <label className="text-[12px] text-gray-1">Status</label>
          <div className="w-full">
            <Badge withPointer status={selectedEpic?.status} />
          </div>
        </span>
        <span className="flex items-center w-fit gap-2">
          <label className="text-[12px] text-gray-1 w-fit flex-shrink-0">
            Project progress{' '}
          </label>
          <div className="w-full">
            {typeof progress !== 'number' || isNaN(progress) ? (
              <Badge2>
                <span className="text-[12px]">...............</span>
                <Stale />
              </Badge2>
            ) : (
              <Badge2>
                <span className="text-[12px] text-gray-1">{progress}%</span>
                <UpTrend />
              </Badge2>
            )}
          </div>
        </span>

        <div className="w-fit flex ml-auto z-50">
          {/* <GlobalDropdown4
            placeholder={null}
            options={chartOptions}
            value={selectedChart}
            onChange={(value) => handleChartSelection(value)}
          /> */}

          <button
            onClick={() => navigate(`/insights/${epicId}`)}
            disabled={!selectedEpic}
            className={`border-b px-2 py-1 disabled:cursor-not-allowed ${selectedEpic ? 'border-blue-1 text-blue-1' : 'border-white-1 text-white-1 opacity-10'}`}
          >
            <p>See details</p>
          </button>
        </div>
      </header>

      {issueStatus ? (
        <div
          ref={ref}
          // onClick={() => navigate(`/insights/${epicId}`)}
          className="w-[80%] mx-auto pt-4 h-[200px] flex flex-row gap-2  mt-4"
        >
          {selectedChart === 'LineChart' ? (
            <LineChart data={data} />
          ) : (
            <PieChart data={data} />
          )}
        </div>
      ) : (
        <div className="flex-1 mt-4 w-full text-gray-3 flex gap-4 p-10 overflow-y-auto">
          {/* <div className="w-full flex flex-col gap-1 items-center justify-center">
            <DocumentCopy />
            <h4 className="font-bold text-sm">Nothing to see here yet</h4>
            <p className="text-sm">Your progress chart will appear here</p>
          </div> */}
          {data.map((item, index) => (
            <>
              <div
                className={`z-0 relative min-w-[30px] w-full h-[170px]  flex flex-col  border-l-1 border-gray-1/20 `}
              >
                <div
                  className={`absolute left-0 bottom-0 w-full flex flex-col`}
                ></div>
                <div
                  className={`w-[2px]  absolute -left-[1.5px] bottom-0 bg-gradient-to-t from-gray-3 to-transparent`}
                ></div>
                <div className={`h-[2px] mt-auto bg-gray-2/20`}></div>
              </div>
            </>
          ))}
        </div>
      )}
    </>
  );
}
