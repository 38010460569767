import React, { useEffect, useState } from 'react';
import JiraIcon from '@/icons/JiraIcon';
import TrashIcon from '@/icons/TrashIcon';
import Empty from '@/components/ui/Empty';
import DocTextIcon from '@/icons/DocTextIcon';
import TicketFull from './TicketFull';
import SendToJiraModal from '@/components/modals/SendToJiraModal';
import DashboardWrapper from '@/components/ui/DashboardWrapper';
import { Link } from 'react-router-dom';
import CSVIcon from '@/icons/CSVIcon';
import WebAppService from '@/services/WebAppService';
import BackIcon from '@/icons/BackIcon';
import { useNavigate } from 'react-router-dom';
import LoadingComponent from '@/components/LoadingComponent';
import ErrorToast from '@/components/modals/ErrorToast';
import ConfirmDelete from '@/components/modals/ConfirmDelete';
import SuccessToast from '@/components/modals/SuccessToast';
import { useProjects } from '../../../context/ProjectsContext';
import { useToast } from '../../../context/ToastContext';
import Breadcrumb from '../../../components/common/Breadcrumb/Breadcrumb';

export default function ProjectBody({
  projectDetails,
  showTicketPage,
  setShowTicketPage,
  loading,
}) {
  const [showJiraModal, setShowJiraModal] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [csvData, setCsvData] = useState('');
  const [projectId, setProjectId] = useState('');
  const navigate = useNavigate();
  const { refetchProjects } = useProjects();
  const { addToast } = useToast();

  const openJiraModal = () => {
    setShowJiraModal(true);
  };

  const closeJiraModal = () => {
    setShowJiraModal(false);
  };

  const tasks = projectDetails?.tasks || [];

  const taskId = projectDetails?.id;

  const [newTasks, setNewTasks] = useState(tasks);

  useEffect(() => {
    setProjectId(taskId);
  }, [taskId]);

  const getNewTasks = async () => {
    try {
      const getProjectsDetailsResponse =
        await WebAppService.getProjectDetails(taskId);

      setNewTasks(getProjectsDetailsResponse?.data?.tasks);
    } catch (error) {
      setErrorMessage(error?.response?.data?.error);
      addToast('Error fetching tasks', 'error', 3000);
    }
  };

  useEffect(() => {
    if (taskId) {
      getNewTasks();
    }
  }, [tasks]);

  const projectName = projectDetails?.name;

  const lastDotIndex = projectName?.lastIndexOf('.');

  // Check if "." exists in the string and get the substring before it
  const formattedProjectName =
    lastDotIndex !== -1 ? projectName?.substring(0, lastDotIndex) : projectName;

  const createdAtDate = new Date(projectDetails?.created_at);
  const now = new Date();
  const timeDifference = now - createdAtDate;
  const timeAgo = getTimeAgoString(timeDifference);

  function getTimeAgoString(milliseconds) {
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    if (days > 0) {
      return `${days} day${days !== 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    } else {
      return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
    }
  }
  const handleDownloadCSV = () => {
    // Check the existence of each field in tasks
    const hasAcceptanceCriteria = tasks.some(
      (task) => task?.acceptance_criteria
    );
    const hasTestCases = tasks.some((task) => task?.test_cases);

    // Dynamically construct headers based on the available fields
    const headers = ['Title', 'Description'];
    if (hasAcceptanceCriteria) headers.push('Acceptance Criteria');
    if (hasTestCases) headers.push('Test Cases');

    // Dynamically construct CSV data
    const csvRows = tasks.map((task) => {
      const row = [
        `"${task?.title?.replace(/"/g, '""')}"`,
        `"${task?.description?.replace(/"/g, '""')}"`,
      ];
      if (hasAcceptanceCriteria)
        row.push(`"${task?.acceptance_criteria?.replace(/"/g, '""')}"`);
      if (hasTestCases) row.push(`"${task?.test_cases?.replace(/"/g, '""')}"`);
      return row;
    });

    // Join header and data rows
    const csvContent =
      'data:text/csv;charset=utf-8,' +
      headers.join(',') +
      '\n' +
      csvRows.map((row) => row.join(',')).join('\n');

    // Create a link element for download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${formattedProjectName}_tasks.csv`);

    // Append the link to the body, trigger download, and clean up
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleBack = () => {
    window.history.back();
  };

  const openConfirmDelete = () => {
    setConfirmDelete(true);
  };
  const closeConfirmDelete = () => {
    setConfirmDelete(false);
  };

  const handleDeleteTicket = (indexToDelete) => {
    const updatedTasks = [...newTasks]; // Create a new array
    updatedTasks.splice(indexToDelete, 1);
    setNewTasks(updatedTasks);
  };

  const handleDeleteProject = async () => {
    setIsDeleting(true);

    try {
      const deleteProjectResponse =
        await WebAppService.deleteProjectById(taskId);

      if (deleteProjectResponse?.success) {
        setTimeout(() => {
          setIsDeleting(false);
          setConfirmDelete(false);
        }, 2000);

        setTimeout(() => {
          addToast('Project deleted successfully', 'success', 3000);
          // setShowSuccessToast(true);
        }, 3000);

        refetchProjects();

        setTimeout(() => {
          // setShowSuccessToast(false);
          window.history.back();
        }, 3000);
      }
    } catch (error) {
      setTimeout(() => {
        setIsDeleting(false);
      }, 2900);

      setTimeout(() => {
        setConfirmDelete(false);
      }, 3000);
      setTimeout(() => {
        setErrorMessage(
          error?.response?.data?.error || 'Error deleting project'
        );
        addToast(`${errorMessage}`, 'error', 3000);
        // setShowErrorToast(true);
      }, 3000);
      // setTimeout(() => {
      // 	setShowErrorToast(false);
      // }, 4000);

      refetchProjects();
    }
  };

  const breadcrumbItems = [
    { label: 'All projects', link: '/projects' },
    {
      label: `${formattedProjectName}`,
      link: `/projects/${taskId}`,
      isActive: true,
    },
  ];
  return (
    <>
      <DashboardWrapper>
        {loading ? (
          <div className="flex flex-col gap-8 w-full h-full p-8 mt-8  rounded-md">
            <LoadingComponent />
          </div>
        ) : (
          <div className="flex flex-col gap-8 w-full h-[92%] pt-4 px-8 mt-8">
            <div className="w-full border innerFrameBg">
              <Breadcrumb items={breadcrumbItems} />
            </div>
            <div className="flex bg1 flex-col gap-8 w-full h-full px-8 py-4 border border-gray-2/30 rounded-md">
              <div className="w-full">
                <div className="flex items-center justify-end gap-4 px-0 rounded-md text-gray-1 text-sm">
                  <div className="flex gap-2">
                    <button
                      disabled={tasks.length > 0 ? false : true}
                      onClick={handleDownloadCSV}
                      className={`${
                        tasks.length > 0
                          ? 'hover:bg-black-1'
                          : 'opacity-30 cursor-not-allowed'
                      } flex items-center rounded-md gap-2 py-3 px-2 `}
                    >
                      <CSVIcon />
                      Download .CSV
                    </button>
                    <button
                      disabled={tasks.length > 0 ? false : true}
                      onClick={openJiraModal}
                      className={`${
                        tasks.length > 0
                          ? 'hover:bg-black-1'
                          : 'opacity-30 cursor-not-allowed'
                      } flex items-center rounded-md gap-2 py-3 px-2`}
                    >
                      <JiraIcon />
                      Send project to Jira
                    </button>
                    <button
                      onClick={openConfirmDelete}
                      className="flex hover:bg-black-1 items-center gap-2 py-3 px-2 rounded-lg text-error-2"
                    >
                      <TrashIcon />
                      Delete project
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex gap-1 items center">
                  {/* <BackIcon
                    className={`hover:text-gray-5 mr-3`}
                    onClick={handleBack}
                  /> */}

                  {/* <DocTextIcon /> */}
                  <ProjectsIcon />
                  <h1 className="text-[24px] font-bold">
                    {formattedProjectName}
                  </h1>
                </div>
                <div>
                  <p className="text-gray-3 text-sm">Created {timeAgo}</p>
                </div>
              </div>

              {tasks.length > 0 ? (
                <div className="mt-2 rounded-md flex items-center gap-4 justify-between w-full">
                  <div className="w-full flex flex-col gap-2">
                    <h1 className="text-[18px] font-semibold">
                      Tickets created
                    </h1>

                    <div className="flex items-start gap-4 justify-between w-full">
                      <p className="text-[14px] w-full text-gray-6">
                        You can edit the tickets to suit your project needs
                        before adding them to your project management tools.
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}

              {newTasks.length > 0 ? (
                <div className="flex flex-col gap-4 overflow-scroll pb-5">
                  {newTasks.map((task, index) => (
                    <div key={index}>
                      <TicketFull
                        key={index}
                        index={index}
                        task={task}
                        tasks={newTasks}
                        onDeleteTicket={() => handleDeleteTicket(index)}
                        taskId={taskId}
                        showTicketPage={showTicketPage}
                        setShowTicketPage={setShowTicketPage}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <Empty
                  message={`No tickets in this project.`}
                  text={`You currently have no tickets in this project.`}
                />
              )}
            </div>
          </div>
        )}
        {showJiraModal && (
          <SendToJiraModal
            projectId={projectId}
            type={'project'}
            onClose={closeJiraModal}
          />
        )}
        {showErrorToast && <ErrorToast message={errorMessage} />}
      </DashboardWrapper>
      {confirmDelete && (
        <ConfirmDelete
          type={`project`}
          isDeleting={isDeleting}
          onDelete={handleDeleteProject}
          onClose={closeConfirmDelete}
        />
      )}
      {showSuccessToast && (
        <SuccessToast message={`Project deleted successfully`} />
      )}
    </>
  );
}

const ProjectsIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1 1.66663H6.89998C6.58332 1.66663 6.31665 1.93329 6.31665 2.24996C6.31665 2.56663 6.58332 2.83329 6.89998 2.83329H9.61665L10.7833 4.38329C11.0416 4.72496 11.075 4.77496 11.5583 4.77496H14.6583C14.975 4.77496 15.2833 4.81663 15.5833 4.89996C15.6167 5.04996 15.6333 5.19996 15.6333 5.35829V5.64996C15.6333 5.96663 15.9 6.23329 16.2166 6.23329C16.5333 6.23329 16.8 5.96663 16.8 5.64996V5.34996C16.7833 3.31663 15.1333 1.66663 13.1 1.66663Z"
        fill="#F58E30"
      />
      <path
        d="M16.7833 5.44996C16.425 5.19163 16.0166 4.99996 15.575 4.89163C15.2833 4.80829 14.9666 4.76663 14.65 4.76663H11.55C11.0666 4.76663 11.0333 4.71663 10.775 4.37496L9.60829 2.82496C9.06663 2.10829 8.64163 1.66663 7.28329 1.66663H5.34996C3.31663 1.66663 1.66663 3.31663 1.66663 5.34996V14.65C1.66663 16.6833 3.31663 18.3333 5.34996 18.3333H14.65C16.6833 18.3333 18.3333 16.6833 18.3333 14.65V8.44996C18.3333 7.20829 17.725 6.11663 16.7833 5.44996ZM11.9416 13.3333H8.05829C7.73329 13.3333 7.47496 13.075 7.47496 12.75C7.47496 12.4333 7.73329 12.1666 8.05829 12.1666H11.9333C12.25 12.1666 12.5166 12.4333 12.5166 12.75C12.5166 13.075 12.2583 13.3333 11.9416 13.3333Z"
        fill="#F0EEEE"
      />
    </svg>
  );
};
