import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Body from './Body';

export default function EpicInsights() {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <main className="relative innerFrameBg text-gray-4 h-screen overflow-hidden w-full flex flex-col">
      <Helmet>
        <title>Epic Insights | Crafter</title>
      </Helmet>
      <div className="flex w-full h-full">
        <div
          className={`w-full ${
            collapsed ? 'w-[95%]' : 'w-[80%]'
          } overflow-scroll h-screen`}
        >
          <Body collapsed={collapsed} />
        </div>
      </div>
    </main>
  );
}
