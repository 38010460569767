import './index.css';
import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import Login from '@/pages/auth/login/Login';
import SignUp from '@/pages/auth/signup/SignUp';
import Homepage from '@/pages/home/Homepage';
import Projects from '@/pages/projects/Projects';
import ProjectPage from '@/pages/projects/projectPage/ProjectPage';
import Integrations from '@/pages/integrations/Integrations';
import Plans from '@/pages/plans/Plans';
import Jira from '@/pages/integrations/Jira/Jira';
import NotFound from '@/pages/notFound/NotFound';
import useAuthStore from '@/store/Auth';
import Layout from './Layout';
import ProtectedRoute from './ProtectedRoute';
import { PlansProvider, usePlans } from './context/PlansContext';
import { ProjectsProvider, useProjects } from './context/ProjectsContext';
import { PollingProvider, usePolling } from './context/PollingContext';
import SuccessToast from '@/components/modals/SuccessToast';
import ErrorToast from '@/components/modals/ErrorToast';
import LoadingComponent from './components/LoadingComponent';
import Insights from '@/pages/insights/Insights';
import EpicInsights from '@/pages/insights/EpicInsights/EpicInsights';
import OpenAI from './pages/integrations/OpenAI/OpenAI';
const queryClient = new QueryClient();

function App() {
  const MEASUREMENT_ID = 'G-3GVYEYB7EL';
  ReactGA.initialize(MEASUREMENT_ID);

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <ProjectsProvider>
          <PlansProvider>
            <PollingProvider>
              <AppContent
                isAuthenticated={isAuthenticated}
                collapsed={collapsed}
                setCollapsed={setCollapsed}
              />
            </PollingProvider>
          </PlansProvider>
        </ProjectsProvider>
      </QueryClientProvider>
    </div>
  );
}

function AppContent({ isAuthenticated, collapsed, setCollapsed }) {
  const { subscriptionLoading: plansLoading } = usePlans();
  const { projectsLoading: projectsLoading } = useProjects();
  const {
    isChecking,
    startPollingTicketsStatus,
    setCreateTicketsResponse,
    successToast,
    errorToast,
    successMessage,
    errorMessage,
    sentProjects,
    setSentProjects,
    createTicketsResponse,
    setIsChecking,
  } = usePolling();
  const [openPendingProjects, setOpenPendingProjects] = useState(false);
  const isLoading = plansLoading || projectsLoading;

  if (isLoading) {
    return (
      <div className="bg-black-6 h-screen flex items-center justify-center text-white-1">
        <LoadingComponent />
      </div>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
      </Routes>

      <Layout
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        createTicketsResponse={createTicketsResponse}
        sentProjects={sentProjects}
        setSentProjects={setSentProjects}
        isChecking={isChecking}
        setIsChecking={setIsChecking}
        openPendingProjects={openPendingProjects}
        setOpenPendingProjects={setOpenPendingProjects}
      >
        <div>
          <Routes className={`w-full`}>
            <Route
              path="/create"
              element={
                <ProtectedRoute
                  component={Homepage}
                  onCreateTickets={startPollingTicketsStatus}
                  setCreateTicketsResponse={setCreateTicketsResponse}
                  isChecking={isChecking}
                  sentProjects={sentProjects}
                  setSentProjects={setSentProjects}
                />
              }
            />
            <Route
              index
              path="/"
              element={
                <PrivateRoute
                  isAuthenticated={isAuthenticated}
                  component={<Insights />}
                />
              }
            />
            <Route
              path="/insights/:epicId"
              element={<ProtectedRoute component={EpicInsights} />}
            />
            <Route
              path="/projects"
              element={<ProtectedRoute component={Projects} />}
            />
            <Route
              path="/projects/:projectId"
              element={<ProtectedRoute component={ProjectPage} />}
            />
            <Route
              path="/integrations"
              element={<ProtectedRoute component={Integrations} />}
            />
            <Route
              path="/integrations/Jira"
              element={<ProtectedRoute component={Jira} />}
            />
            <Route
              path="/integrations/OpenAI"
              element={<ProtectedRoute component={OpenAI} />}
            />
            <Route
              path="/plans"
              element={
                <PrivateRoute
                  component={<Plans />}
                  isAuthenticated={isAuthenticated}
                />
              }
            />
          </Routes>
        </div>
      </Layout>

      {successToast && <SuccessToast message={successMessage} />}
      {errorToast && <ErrorToast message={errorMessage} />}
    </BrowserRouter>
  );
}

function PrivateRoute({ component, isAuthenticated, ...rest }) {
  return isAuthenticated ? <>{component}</> : <Navigate to="/login" replace />;
}

export default App;
