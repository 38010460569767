import React from 'react';

export default function CSVIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.4147 0C2.36395 0 1.51233 0.852 1.51233 1.90275V22.0931C1.51233 23.1443 2.36395 23.9959 3.4147 23.9959H20.58C21.6303 23.9959 22.4827 23.1443 22.4827 22.0931V7.61625L15.3941 0L3.4147 0Z"
        fill="#45B058"
      />
      <path
        d="M9.11472 16.1989C9.16197 16.2529 9.18897 16.3204 9.18897 16.3946C9.18897 16.5364 9.07422 16.6646 8.92534 16.6646C8.85784 16.6646 8.78359 16.6376 8.73634 16.5769C8.47309 16.2596 8.02722 16.0568 7.60872 16.0568C6.62284 16.0568 5.87359 16.8131 5.87359 17.8868C5.87359 18.9536 6.62322 19.7164 7.60872 19.7164C8.03397 19.7164 8.45247 19.5341 8.73634 19.203C8.78322 19.149 8.85784 19.1156 8.92534 19.1156C9.08097 19.1156 9.18897 19.2503 9.18897 19.3924C9.18897 19.4599 9.16197 19.5274 9.11472 19.5814C8.76334 19.9459 8.28409 20.2163 7.60909 20.2163C6.31272 20.2163 5.29309 19.2709 5.29309 17.8868C5.29309 16.5026 6.31272 15.5573 7.60909 15.5573C8.28409 15.5576 8.77009 15.8276 9.11472 16.1989ZM11.6655 20.2166C10.9972 20.2166 10.4703 19.9939 10.0856 19.656C10.0248 19.602 9.99784 19.5278 9.99784 19.4535C9.99784 19.3181 10.0991 19.1696 10.2611 19.1696C10.3151 19.1696 10.3758 19.1831 10.4231 19.2236C10.7336 19.5008 11.1656 19.7164 11.6857 19.7164C12.4893 19.7164 12.7458 19.2844 12.7458 18.9398C12.7458 17.7784 10.0788 18.42 10.0788 16.8128C10.0788 16.0703 10.7403 15.5636 11.625 15.5636C12.2055 15.5636 12.7053 15.7388 13.0698 16.0429C13.1306 16.0969 13.1643 16.1711 13.1643 16.2454C13.1643 16.3804 13.0496 16.5154 12.9007 16.5154C12.8467 16.5154 12.786 16.4951 12.7387 16.4546C12.408 16.1846 11.9962 16.0631 11.5841 16.0631C11.0441 16.0631 10.659 16.3534 10.659 16.779C10.659 17.7919 13.326 17.211 13.326 18.8925C13.3263 19.548 12.8808 20.2166 11.6655 20.2166ZM18.0382 15.9964L16.4786 19.872C16.4043 20.0543 16.2217 20.1758 16.0331 20.1758H16.0196C15.8238 20.1758 15.6416 20.0543 15.567 19.872L14.0137 15.9964C14.0002 15.9626 13.9935 15.9289 13.9935 15.8884C13.9935 15.7534 14.1146 15.591 14.2972 15.591C14.412 15.591 14.52 15.6585 14.5672 15.7733L16.0256 19.5203L17.484 15.7733C17.5245 15.6653 17.6325 15.591 17.754 15.591C17.9295 15.591 18.0577 15.7328 18.0577 15.8884C18.0581 15.9221 18.0513 15.9626 18.0382 15.9964Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.5004 7.63387V8.00887H17.7004C17.7004 8.00887 15.3334 7.53637 15.4024 5.49374C15.4024 5.49374 15.4804 7.63387 17.6535 7.63387H22.5004Z"
        fill="#349C42"
      />
      <path
        opacity="0.5"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.4117 0.0022583V5.46263C15.4117 6.08363 15.8257 7.63426 17.7007 7.63426H22.5007L15.4117 0.0022583Z"
        fill="white"
      />
    </svg>
  );
}
