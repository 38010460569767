import React from 'react';

export default function Check() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M7.16643 1.63289C7.62643 1.23956 8.37976 1.23956 8.84643 1.63289L9.89976 2.53956C10.0998 2.71289 10.4731 2.85289 10.7398 2.85289H11.8731C12.5798 2.85289 13.1598 3.43289 13.1598 4.13956V5.27289C13.1598 5.53289 13.2998 5.91289 13.4731 6.11289L14.3798 7.16622C14.7731 7.62622 14.7731 8.37956 14.3798 8.84622L13.4731 9.89956C13.2998 10.0996 13.1598 10.4729 13.1598 10.7396V11.8729C13.1598 12.5796 12.5798 13.1596 11.8731 13.1596H10.7398C10.4798 13.1596 10.0998 13.2996 9.89976 13.4729L8.84643 14.3796C8.38643 14.7729 7.63309 14.7729 7.16643 14.3796L6.11309 13.4729C5.91309 13.2996 5.53976 13.1596 5.27309 13.1596H4.11976C3.41309 13.1596 2.83309 12.5796 2.83309 11.8729V10.7329C2.83309 10.4729 2.69309 10.0996 2.52643 9.89956L1.62643 8.83956C1.23976 8.37956 1.23976 7.63289 1.62643 7.17289L2.52643 6.11289C2.69309 5.91289 2.83309 5.53956 2.83309 5.27956V4.13289C2.83309 3.42622 3.41309 2.84622 4.11976 2.84622H5.27309C5.53309 2.84622 5.91309 2.70622 6.11309 2.53289L7.16643 1.63289Z"
        fill="currentColor"
      />
      <path
        d="M7.19321 10.1129C7.05988 10.1129 6.93321 10.0595 6.83988 9.9662L5.22654 8.35286C5.03321 8.15953 5.03321 7.83953 5.22654 7.6462C5.41988 7.45286 5.73988 7.45286 5.93321 7.6462L7.19321 8.9062L10.0599 6.03953C10.2532 5.8462 10.5732 5.8462 10.7665 6.03953C10.9599 6.23286 10.9599 6.55286 10.7665 6.7462L7.54654 9.9662C7.45321 10.0595 7.32654 10.1129 7.19321 10.1129Z"
        fill="currentColor"
      />
    </svg>
  );
}
