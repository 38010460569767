import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

export default function Project3({ project }) {
  const [showProjectModal, setShowProjectModal] = useState(false);
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/projects/${project.id}`);
  };

  const tasks = project?.tasks;

  const createdAtDate = new Date(project.created_at);
  const now = new Date();
  const timeDifference = now - createdAtDate;
  const timeAgo = getTimeAgoString(timeDifference);

  const projectName = project?.name;

  const lastDotIndex = projectName.lastIndexOf('.');

  // Check if "." exists in the string and get the substring before it
  const formattedProjectName =
    lastDotIndex !== -1 ? projectName.substring(0, lastDotIndex) : projectName;

  function getTimeAgoString(milliseconds) {
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    if (days > 0) {
      return `${days} day${days !== 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    } else {
      return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
    }
  }

  return (
    <div
      onClick={handleNavigate}
      className={`${
        tasks.length <= 0
          ? 'text-gray-2 opacity-80 bg-black-7'
          : ' text-gray-4 bg7 hover:text-gray-5 '
      } flex cursor-pointer items-center justify-between px-5 py-5 rounded-md transition-colors duration-300`}
    >
      <div className="flex gap-2">
        {/* <p>{project.label}</p> */}
        <ProjectsIcon />

        <p className="text-[16px]">{formattedProjectName}</p>
      </div>
      <div className="flex items-center gap-2 text-[12px]">
        <p>Created {timeAgo}</p>
        {/* <MoreIcon /> */}
      </div>
    </div>
  );
}

const ProjectsIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1 1.66663H6.89998C6.58332 1.66663 6.31665 1.93329 6.31665 2.24996C6.31665 2.56663 6.58332 2.83329 6.89998 2.83329H9.61665L10.7833 4.38329C11.0416 4.72496 11.075 4.77496 11.5583 4.77496H14.6583C14.975 4.77496 15.2833 4.81663 15.5833 4.89996C15.6167 5.04996 15.6333 5.19996 15.6333 5.35829V5.64996C15.6333 5.96663 15.9 6.23329 16.2166 6.23329C16.5333 6.23329 16.8 5.96663 16.8 5.64996V5.34996C16.7833 3.31663 15.1333 1.66663 13.1 1.66663Z"
        fill="#F58E30"
      />
      <path
        d="M16.7833 5.44996C16.425 5.19163 16.0166 4.99996 15.575 4.89163C15.2833 4.80829 14.9666 4.76663 14.65 4.76663H11.55C11.0666 4.76663 11.0333 4.71663 10.775 4.37496L9.60829 2.82496C9.06663 2.10829 8.64163 1.66663 7.28329 1.66663H5.34996C3.31663 1.66663 1.66663 3.31663 1.66663 5.34996V14.65C1.66663 16.6833 3.31663 18.3333 5.34996 18.3333H14.65C16.6833 18.3333 18.3333 16.6833 18.3333 14.65V8.44996C18.3333 7.20829 17.725 6.11663 16.7833 5.44996ZM11.9416 13.3333H8.05829C7.73329 13.3333 7.47496 13.075 7.47496 12.75C7.47496 12.4333 7.73329 12.1666 8.05829 12.1666H11.9333C12.25 12.1666 12.5166 12.4333 12.5166 12.75C12.5166 13.075 12.2583 13.3333 11.9416 13.3333Z"
        fill="#F0EEEE"
      />
    </svg>
  );
};
