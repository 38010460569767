import React from 'react';
import SuccessIcon from '@/icons/SuccessIcon';

export default function SuccessToast({ message }) {
  return (
    <div className="w-[30%] absolute bg5 top-10 right-[10px] z-[1000] text-gray-4">
      <div className="flex items-center gap-5 w-[100%] z-50 border-0.5 border-gray-5/50 text-base h-fit px-4 py-8 shadow-lg rounded-md">
        <div>
          <span className="cursor-pointer">
            <SuccessIcon />
          </span>
        </div>
        <div>{message}</div>
      </div>
    </div>
  );
}
