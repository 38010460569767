import React, { useState } from 'react';
import BackIcon from '@/icons/BackIcon';
import EditIcon from '@/icons/EditIcon';
import TrashIcon from '@/icons/TrashIcon';
import SendToJiraModal from '@/components/modals/SendToJiraModal';
import { useNavigate } from 'react-router-dom';
import DashboardWrapper from '@/components/ui/DashboardWrapper';
import WebAppService from '@/services/WebAppService';
import GlobalButton from '@/components/ui/GlobalButton';
import ConfirmSave from '@/components/modals/ConfirmSave';
import Breadcrumb from '../../../components/common/Breadcrumb/Breadcrumb';

export default function TicketBody({
  projectDetails,
  clickedTicketIndex,
  showProjectPage,
  setShowProjectPage,
}) {
  const [showJiraModal, setShowJiraModal] = useState(false);
  const [confirmSave, setConfirmSave] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const tasks = projectDetails?.tasks || [];
  const projectId = projectDetails?.id;
  const navigate = useNavigate();

  // Initialize state with values from clickedTicket
  const clickedTicket = tasks[clickedTicketIndex] || {};
  const [editedTitle, setEditedTitle] = useState(clickedTicket.title || '');
  const [editedDescription, setEditedDescription] = useState(
    clickedTicket.description
  );
  const [editedAcceptanceCriteria, setEditedAcceptanceCriteria] = useState(
    clickedTicket?.acceptance_criteria
  );
  const [editedTestCases, setEditedTestCases] = useState(
    clickedTicket?.test_cases
  );

  const openJiraModal = () => {
    setShowJiraModal(true);
  };

  const closeJiraModal = () => {
    setShowJiraModal(false);
  };

  const openConfirmSave = () => {
    setConfirmSave(true);
  };
  const closeConfirmSave = () => {
    setConfirmSave(false);
  };

  const backClick = () => {
    setShowProjectPage(true);
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleSaveClick = async () => {
    // Update the ticket's description and acceptance criteria with edited values
    const updatedTasks = [...tasks];
    updatedTasks[clickedTicketIndex].title = editedTitle;
    updatedTasks[clickedTicketIndex].description = editedDescription;
    updatedTasks[clickedTicketIndex].acceptance_criteria =
      editedAcceptanceCriteria;
    updatedTasks[clickedTicketIndex].test_cases = editedTestCases;
    setIsSaving(true);

    try {
      const saveEditedProjectResponse = await WebAppService.saveEditedProject(
        projectId,
        {
          tasks: updatedTasks,
          // jira_project_key: "string",
          // name: "LearnUp",
          // description: "string",
        }
      );
    } catch (error) {}

    setIsSaving(false);
    closeConfirmSave();
    setEditMode(false);
  };

  const handleCancel = () => {
    setEditMode(false);
    // Reset edited values to clickedTicket values
    setEditedTitle(clickedTicket.title || '');
    setEditedDescription(clickedTicket.description || '');
    setEditedAcceptanceCriteria(clickedTicket.acceptance_criteria || '');
    setEditedTestCases(clickedTicket.test_cases || '');
  };

  const breadcrumbItems = [
    { label: 'All projects', link: '/projects' },
    {
      label: `${projectDetails.name}`,
      link: `/projects/${projectDetails.id}`,
    },
    {
      label: `${clickedTicket.title}`,
      // link: `/projects/${taskId}`,
      isActive: true,
    },
  ];

  return (
    <DashboardWrapper>
      <div className="flex innerFrameBg flex-col gap-8 w-full h-[92%] px-8 pt-4 mt-8 border border-gray-2/30 rounded-md overflow-scroll">
        <div className="w-full">
          <Breadcrumb items={breadcrumbItems} />
        </div>
        <div className="bg1 flex flex-col gap-6 w-full h-full px-8 py-4 rounded-md">
          <div
            className={`flex items-center justify-between bg-transparent gap-4  ${
              editMode ? '' : ''
            }  py-2 rounded-md text-gray-1 text-sm`}
          >
            <div className="flex gap-4 w-full">
              {!editMode && (
                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center">
                    <BackIcon
                      className={`hover:text-gray-5 mr-3`}
                      onClick={backClick}
                    />
                    <h1 className="font-semibold text-[20px] text-white-1">
                      Tickets details
                    </h1>
                  </div>
                  <div className="flex items-center">
                    <button
                      onClick={handleEditClick}
                      className="flex items-center rounded-md gap-2 py-3 px-2 hover:bg-black-1 "
                    >
                      <EditIcon />
                      Edit ticket
                    </button>
                    <button className="flex items-center text-error-2 rounded-md gap-2 py-3 px-2 hover:bg-black-1 ">
                      <TrashIcon />
                      Delete ticket
                    </button>
                  </div>
                </div>
              )}
              {editMode && (
                <div className="flex justify-between w-full items-center gap-4">
                  <div>
                    <h1 className="font-semibold text-[20px] text-white-1">
                      Edit Tickets
                    </h1>
                  </div>
                  <div className="flex items-center gap-2">
                    <div>
                      <GlobalButton
                        onClick={openConfirmSave}
                        variant={'blue'}
                        state={'default'}
                        size={'md'}
                      >
                        Save changes
                      </GlobalButton>
                    </div>

                    <button
                      className="flex items-center gap-2 py-3 px-8 rounded-full border border-error-2 text-error-2 min-w-24 text-center justify-center font-semibold"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="w-full flex items-center">
            {editMode ? (
              <div className="flex items-center gap-2 w-full">
                <h3 className="text-lg text-blue-2 font-bold flex-shrink-0">
                  Ticket title:
                </h3>
                <input
                  value={editedTitle}
                  autoFocus
                  onChange={(e) => setEditedTitle(e.target.value)}
                  className="text-lg text-gray-4 font-semibold placeholder:text-gray-2 w-full h-fit bg-transparent rounded-md py-[5px] px-[0px] focus:outline-none"
                />
              </div>
            ) : (
              <div className="flex items-center gap-1">
                <h3 className="text-lg text-blue-2 font-bold">Ticket title:</h3>
                <h1 className="text-lg text-gray-4 font-semibold">
                  {clickedTicket.title}
                </h1>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-4 ticketBodyBg border-0.5 border-gray-2/30 p-6 h-fit overflow-scroll rounded-md">
            <div className="flex flex-col gap-2 pb-4 border-b border-gray-2">
              <h3 className="text-blue-2 font-semibold text-base">
                Ticket description:
              </h3>
              {editMode ? (
                <textarea
                  value={editedDescription}
                  onChange={(e) => setEditedDescription(e.target.value)}
                  className="placeholder:text-gray-2  text-gray-4 text-base w-full h-fit max-h-[100px] bg-transparent py-[10px] px-[0] focus:outline-none"
                />
              ) : (
                <p
                  className="text-gray-4 text-base"
                  dangerouslySetInnerHTML={{
                    __html: editedDescription?.replace(/\n/g, '<br>'),
                  }}
                />
              )}
            </div>

            {clickedTicket?.acceptance_criteria && (
              <div className="flex flex-col gap-2 pb-4 border-b border-gray-2">
                <h3 className="text-blue-2 font-semibold text-base">
                  Acceptance criteria:{' '}
                </h3>
                {editMode ? (
                  <textarea
                    value={editedAcceptanceCriteria}
                    onChange={(e) =>
                      setEditedAcceptanceCriteria(e.target.value)
                    }
                    className="placeholder:text-gray-2 text-base text-gray-4 w-full h-[100px] bg-transparent rounded-md py-[5px] px-[0px] focus:outline-none"
                  />
                ) : (
                  <p
                    className="text-gray-4 text-base"
                    dangerouslySetInnerHTML={{
                      __html: editedAcceptanceCriteria?.replace(/\n/g, '<br>'),
                    }}
                  />
                )}
              </div>
            )}
            {clickedTicket?.test_cases && (
              <div className="flex flex-col gap-2 pb-4 border-b border-gray-2">
                <h3 className="text-blue-2 font-semibold text-base">
                  Test cases:{' '}
                </h3>
                {editMode ? (
                  <textarea
                    value={editedTestCases}
                    onChange={(e) => setEditedTestCases(e.target.value)}
                    className="placeholder:text-gray-2 text-base text-gray-4 w-full h-[100px] bg-transparent rounded-md  py-[5px] px-[0px] focus:outline-none"
                  />
                ) : (
                  <p
                    className="text-gray-1 text-base"
                    dangerouslySetInnerHTML={{
                      __html: editedTestCases?.replace(/\n/g, '<br>'),
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {confirmSave && (
        <ConfirmSave
          onClose={closeConfirmSave}
          onSave={handleSaveClick}
          isSaving={isSaving}
        />
      )}

      {showJiraModal && (
        <SendToJiraModal type={'ticket'} onClose={closeJiraModal} />
      )}
    </DashboardWrapper>
  );
}
