import React, { useState } from 'react';
import Caret from '../../icons/Caret';
import ProjectsIcon from '../../icons/ProjectsIcon';
import LoadingComponent from '../LoadingComponent';

export default function ProjectDropdown({
  id,
  name,
  disabled,
  label,
  isRequired,
  options,
  placeholder,
  isProject,
  onChange,
  value,
  fetchData,
  fetchJiraProjectEpics,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value);
  const [loading, setLoading] = useState(false);

  const handleOpenDropdown = async (e) => {
    e.preventDefault();
    if (
      !isOpen &&
      fetchData &&
      typeof fetchData === 'function' &&
      selectedOption === ''
    ) {
      setIsOpen(true);
      setLoading(true);
      await fetchData();
      setLoading(false);
    }
    setIsOpen(!isOpen);
  };

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    if (onChange && typeof onChange === 'function') {
      onChange(option);
    }
    fetchJiraProjectEpics(option.key);
    setIsOpen(false);
  };

  return (
    <div className="w-full flex flex-col items-start gap-2 project-dropdown">
      <label className="text-sm text-gray-4 font-semibold">
        {label}
        {isRequired && <span className="text-sm text-error-1">*</span>}
      </label>
      <div className="w-full relative">
        <button
          // onBlur={setIsOpen(false)}
          disabled={disabled}
          id={id}
          name={name}
          onClick={handleOpenDropdown}
          className={`flex w-full h-[40px] items-center gap-2 justify-between ${
            selectedOption ? 'text-white-2' : 'text-gray-2'
          } ${disabled ? 'cursor-not-allowed' : ''} text-gray-2 text-white bg-transparent rounded-md py-[10px] px-[14px] border-1 border-gray-2 focus:outline-none`}
        >
          <span className="flex items-center gap-2 text-gray-1 ">
            {isProject && <ProjectsIcon2 />}
            {selectedOption ? (
              <p className="text-white-1 font-semibold capitalize">
                {selectedOption?.name}
              </p>
            ) : (
              <p className="text-gray-4 font-normal">{placeholder}</p>
            )}
          </span>
          <Caret />
        </button>

        {isOpen && (
          <div className="rounded-md absolute w-full mt-2 max-h-[150px] overflow-y-scroll flex flex-col items-start text-gray-4 modalBg">
            {loading ? (
              <LoadingComponent />
            ) : options.length > 0 ? (
              <ul className="w-full">
                {options.map((option, index) => (
                  <li
                    key={index}
                    onClick={() => handleSelectOption(option)}
                    className="w-full text-left py-3 px-4 border-b-0.5 border-black-1 text-gray-4 hover:text-white-2 cursor-pointer capitalize"
                  >
                    {option.name}
                  </li>
                ))}
              </ul>
            ) : (
              <p className="w-full text-center py-6 px-4 text-gray-4">
                No Jira Projects found
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export const ProjectsIcon2 = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.64"
        d="M15.5 13.15H13.33C11.55 13.15 10.1 11.71 10.1 9.92V7.75C10.1 7.34 9.77 7 9.35 7H6.18C3.87 7 2 8.5 2 11.18V17.82C2 20.5 3.87 22 6.18 22H12.07C14.38 22 16.25 20.5 16.25 17.82V13.9C16.25 13.48 15.91 13.15 15.5 13.15Z"
        fill="#AAD5E3"
      />
      <path
        d="M17.82 2H15.85H14.76H11.93C9.67001 2 7.84001 3.44 7.76001 6.01C7.82001 6.01 7.87001 6 7.93001 6H10.76H11.85H13.82C16.13 6 18 7.5 18 10.18V12.15V14.86V16.83C18 16.89 17.99 16.94 17.99 16.99C20.22 16.92 22 15.44 22 12.83V10.86V8.15V6.18C22 3.5 20.13 2 17.82 2Z"
        fill="#AAD5E3"
      />
      <path
        d="M11.9796 7.14999C11.6696 6.83999 11.1396 7.04999 11.1396 7.47999V10.1C11.1396 11.2 12.0696 12.1 13.2096 12.1C13.9196 12.11 14.9096 12.11 15.7596 12.11C16.1896 12.11 16.4096 11.61 16.1096 11.31C15.0196 10.22 13.0796 8.26999 11.9796 7.14999Z"
        fill="#AAD5E3"
      />
    </svg>
  );
};
