import React, { useState } from 'react';
import GlobalButton from '@/components/ui/GlobalButton';

export default function ConfirmSave({ onClose, onSave, isSaving }) {
  return (
    <div className="modal">
      <div
        onClick={onClose}
        className="modal-overlay absolute inset-0 bg-black-3 opacity-100"
      ></div>

      <div className="modalBg2 px-6 py-8 w-[90%] md:w-[90%] lg:w-[40%] max-h-[90%] max-w-[500px] h-fit top-1/2 left-1/2 absolute border-0.5 border-gray-2/50 transform -translate-x-1/2 -translate-y-1/2 bg3 flex flex-col gap-5 items-center rounded-lg shadow-md text-white-1">
        <div className="flex flex-col gap-2 items-start text-left">
          <h1 className="font-semibold text-xl">Save changes?</h1>

          <p className="text-gray-4">
            You have made some changes to your ticket, saving these changes
            cannot be undone.{' '}
          </p>
        </div>

        <div className="flex gap-5 w-full">
          <div className="w-full">
            <GlobalButton
              onClick={onSave}
              variant={'blue'}
              size={'md'}
              state={isSaving ? 'inactive' : 'default'}
            >
              {isSaving ? 'Saving...' : 'Save Changes'}
            </GlobalButton>
          </div>
          <div className="w-full">
            <button
              onClick={onClose}
              className="text-error-2 border border-error-2 text-center font-semibold rounded-full w-full py-3 px-6 "
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
