import React from 'react';

export default function Timestamp() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.64"
        d="M10.5 12.0312C10.1413 12.0312 9.84375 11.7337 9.84375 11.375V7C9.84375 6.64125 10.1413 6.34375 10.5 6.34375C10.8587 6.34375 11.1562 6.64125 11.1562 7V11.375C11.1562 11.7337 10.8587 12.0312 10.5 12.0312Z"
        fill="#D4D4D4"
      />
      <path
        d="M13.0283 3.01875H7.97082C7.62082 3.01875 7.34082 2.73875 7.34082 2.38875C7.34082 2.03875 7.62082 1.75 7.97082 1.75H13.0283C13.3783 1.75 13.6583 2.03 13.6583 2.38C13.6583 2.73 13.3783 3.01875 13.0283 3.01875Z"
        fill="#D4D4D4"
      />
      <path
        opacity="0.4"
        d="M13.1251 17.4738V14.9013C13.1251 13.79 13.7901 13.125 14.9013 13.125H17.4738C17.6401 13.125 17.7888 13.1425 17.9376 13.1775C18.0338 12.6875 18.0863 12.18 18.0863 11.6638C18.0863 7.48126 14.6826 4.07751 10.5001 4.07751C6.31757 4.07751 2.91382 7.48126 2.91382 11.6638C2.91382 15.8463 6.31757 19.25 10.5001 19.25C11.5588 19.25 12.5651 19.0313 13.4838 18.6375C13.2563 18.34 13.1251 17.955 13.1251 17.4738Z"
        fill="#D4D4D4"
      />
      <path
        d="M17.4737 13.125H14.9013C13.79 13.125 13.125 13.79 13.125 14.9013V17.4737C13.125 18.585 13.79 19.25 14.9013 19.25H17.4737C18.585 19.25 19.25 18.585 19.25 17.4737V14.9013C19.25 13.79 18.585 13.125 17.4737 13.125ZM17.2288 17.0275L16.1963 17.6225C15.9863 17.745 15.7762 17.8063 15.5837 17.8063C15.435 17.8063 15.3038 17.7712 15.1813 17.7012C14.9013 17.535 14.7438 17.2113 14.7438 16.7825V15.5925C14.7438 15.1637 14.9013 14.84 15.1813 14.6738C15.4613 14.5075 15.82 14.5425 16.1963 14.7525L17.2288 15.3475C17.5963 15.5663 17.8063 15.8637 17.8063 16.1875C17.8063 16.5113 17.605 16.8087 17.2288 17.0275Z"
        fill="#F58E30"
      />
    </svg>
  );
}
