import React from 'react';
import { Link } from 'react-router-dom';
import Terms from '../Terms';
import close from '../../../src/icons/iconClose.png';
export default function TermsModal({ onClose }) {
  const acceptableUseList = [
    'Upload, transmit, or store any content that is infringing, obscene, defamatory, threatening, or otherwise unlawful.',
    'Interfere with the security of the Service or attempt to gain unauthorized access to the Service or any computer system or network connected to the Service.',
    'Use the Service to send spam or unsolicited commercial communications.',
    'Impersonate any person or entity, or otherwise misrepresent your affiliation with a person or entity.',
  ];
  return (
    <div className="modal">
      <div onClick={onClose} className="modal-overlay"></div>
      <div className="px-[30px] lg:px-[50px] md:px-[50px] py-5 text-gray-6 text-base flex flex-col  w-[95%] lg:w-full h-[90%]  z-20 bg3 border border-gray-4/30 rounded-xl">
        <div className="flex items-center justify-between w-full">
          <h1 className="text-2xl py-3 font-semibold text-white-1">
            Terms of service
          </h1>
          <button onClick={onClose}>
            <img src={close} alt="close" className="w-[13px] h-[13px]" />
          </button>
        </div>
        <div className="mt-6 overflow-scroll mb-[20px] flex flex-col gap-6">
          <div className="flex flex-col gap-3">
            <p className="mt-3 text-[14px] md:text-[16px] lg:text-[16px]">
              These Terms of Use govern your access to and use of the Crafter
              platform. By accessing or using the Service, you agree to be bound
              by these Terms. If you disagree with any part of the Terms, you
              may not access or use the Service.
            </p>
          </div>

          <Terms
            title={'Eligibility'}
            message={`You must be at least 18 years old and have the legal capacity to enter into a binding agreement in order to use the Service. By using the Service, you represent and warrant that you meet all of the foregoing requirements. If you are under 18, you may only use the Service under the supervision of a parent or legal guardian.`}
          />

          <div className="flex flex-col gap-1">
            <h1 className="text-xl text-white-1">User Accounts</h1>
            <p className="text-[14px] md:text-[16px] lg:text-[16px]">
              You will need to{' '}
              <Link
                className="underline underline-offset-2 text-blue-1 "
                href={`https://app.usecrafter.com/signup`}
                target="_blank"
              >
                Create an account{' '}
              </Link>
              to access certain features of the Service. You are responsible for
              maintaining the confidentiality of your Account information,
              including your username and password, and for all activity that
              occurs under your Account. You agree to notify Crafter immediately
              of any unauthorized use of your Account or any other security
              breach. Crafter will not be liable for any loss or damage arising
              from your failure to comply with these security obligations.
            </p>
          </div>

          <Terms
            title={'Acceptable Use'}
            message={`You agree to use the Service in a manner that is consistent with all applicable laws and regulations. You will not use the Service for any purpose that is prohibited by these Terms, or that could damage or impair the Service or any other service, system, or network. You are also prohibited from using the Service to:`}
            list={acceptableUseList}
          />
          <Terms
            title={'Content Ownership'}
            message={`You retain all ownership rights to the content you upload to the Service ("Your Content"). By uploading Your Content to the Service, you grant Crafter a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, publish, distribute, and publicly display Your Content solely for the purpose of providing the Service to you. You represent and warrant that you have all necessary rights and licenses to grant us this license.`}
          />
          <Terms
            title={'Third-Party Services'}
            message={`The Service may contain links to or integrations with third-party websites or services. Crafter is not responsible for the content or practices of any third-party websites or services. Your use of third-party websites or services is subject to the terms and conditions of those websites or services.`}
          />
          <Terms
            title={'Intellectual Property'}
            message={`The Service and all content and materials included in the Service, including, but not limited to, text, graphics, logos, icons, images, software, and other intellectual property  are the property of Crafter or its licensors. You are granted a limited, non-exclusive, non-transferable license to access and use the Crafter Content solely for your personal and non-commercial use.`}
          />

          <div className="flex flex-col gap-1 text-blue-1">
            <h1 className="text-xl text-blue-1 font-medium">
              Disclaimer of Warranties
            </h1>
            <p className="uppercase text-[14px] md:text-[16px] lg:text-[16px]">
              THE SERVICE IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT
              WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT
              LIMITED TO, THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
              PARTICULAR PURPOSE, AND NON-INFRINGEMENT. CRAFTER DOES NOT WARRANT
              THAT THE SERVICE WILL BE UNINTERRUPTED, ERROR-FREE, OR VIRUS-FREE.
              CRAFTER DOES NOT WARRANT THAT THE RESULTS THAT MAY BE OBTAINED
              FROM THE USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE.
            </p>
          </div>
          <div className="flex flex-col gap-1 text-blue-1">
            <h1 className="text-xl text-blue-1 font-medium">
              Limitation of Liability
            </h1>
            <p className="uppercase text-[14px] md:text-[16px] lg:text-[16px]">
              TO THE MAXIMUM EXTENT PERMITTED BY LAW, CRAFTER SHALL NOT BE
              LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL,
              SPECIAL, PUNITIVE, OR EXEMPLARY DAMAGES ARISING OUT OF OR RELATING
              TO YOUR USE OF THE SERVICE, EVEN IF CRAFTER HAS BEEN ADVISED OF
              THE POSSIBILITY OF SUCH DAMAGES.
            </p>
          </div>

          <Terms
            title={'Termination'}
            message={`Crafter may terminate your access to the Service at any time, with or without cause, upon written notice. You may also terminate your access to the Service at any time. Upon termination, all rights and licenses granted to you under these Terms will automatically terminate.`}
          />
          <Terms
            title={'Agreement'}
            message={`These Terms constitute the entire agreement between you and Crafter with respect to the use of the Service and supersede all prior or contemporaneous communications and proposals, whether oral or written.`}
          />

          {/* <div className="flex gap-6 mt-4">
						<Checkbox label={`I have read and accepted the terms of use`} />
						<div className="w-[150px]">
							<GlobalButton
								variant={`blue`}
								state="default"
								size={"sm"}>
								I accept
							</GlobalButton>
						</div>
					</div> */}
        </div>
      </div>
    </div>
  );
}
