import React, { useEffect, useState } from 'react';
import InputField5 from '@/components/ui/InputField5';
import { OpenAILogo } from '../../../svgs';
import GlobalButton from '@/components/ui/GlobalButton';
import useAuthStore from '../../../store/Auth';
import WebAppService from '../../../services/WebAppService';
import JiraConfigSuccess from '@/components/modals/JiraConfigSuccess';
import JiraConfigFailed from '@/components/modals/JiraConfigFailed';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from '../../../components/common/Breadcrumb/Breadcrumb';
import useActionStore from '../../../store/Actions';

export default function OpenAIForm() {
  const authStore = useAuthStore();
  const {
    toggleIntegrationSuccessModal,
    toggleIntegrationFailedModal,
    setOpenAIActiveComponent,
  } = useActionStore();
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [formData, setFormData] = useState({
    openai_api_key: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const organizationId = authStore.organization.id;
    setIsSubmitting(true);

    try {
      const configureOpenAIResponse = await WebAppService.configureOpenAI(
        organizationId,
        formData
      );

      if (configureOpenAIResponse.success) {
        setFormData({
          openai_api_key: '',
        });

        toggleIntegrationSuccessModal(true);
        setOpenAIActiveComponent('AlreadyConfigured');
      }

      const configureOpenAIResponseData = configureOpenAIResponse.data;

      authStore.updateOrganization({
        hasSetOpenAIConfig: configureOpenAIResponseData.has_set_openai_api_key,
        hasSetJiraConfig: configureOpenAIResponseData.has_set_jira_api_key,
        domain: configureOpenAIResponseData.domain,
        name: configureOpenAIResponseData.name,
        updatedAt: configureOpenAIResponseData.updatedAt,
        jira_url: configureOpenAIResponseData.jira_url,
        jira_admin_email: configureOpenAIResponseData.jira_admin_email,
        id: configureOpenAIResponseData.id,
      });
    } catch (error) {
      toggleIntegrationFailedModal(true);

      setIsSubmitting(false);
    }

    setIsSubmitting(false);
  };

  const handleCancel = () => {
    navigate('/integrations');
  };

  useEffect(() => {
    const isApiKeyValid =
      formData.openai_api_key !== '' && formData.openai_api_key.length > 10;

    setFormValid(isApiKeyValid);
  }, [formData]);

  const breadcrumbItems = [
    { label: 'Integrations', link: '/integrations' },
    {
      label: `OpenAI`,
      link: `/integrations/OpenAI`,
      isActive: true,
    },
  ];
  return (
    <div className="w-full h-screen pt-[60px] pb-[45px] px-4 text-sm text-white-1">
      <div className="flex innerFrameBg flex-col gap-8 w-full h-full p-8 mt-8">
        <div className="w-full h-full px-8 py-8 mx-auto rounded-xl bg1">
          <div className="w-full">
            <Breadcrumb items={breadcrumbItems} />
          </div>
          <div className="w-fit mt-12 flex flex-col items-start justify-center gap-10">
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-4">
                <div className="w-[50px]">
                  <OpenAILogo />
                </div>

                <div className="grid gap-2">
                  <h1 className="text-[20px] font-semibold">
                    Integrate with OpenAI
                  </h1>
                  <p className="text-base text-gray-6">
                    Integrate your Crafter account with OpenAI and enjoy a
                    seamless experience
                  </p>
                </div>
              </div>
            </div>
            <form className="w-full" onSubmit={handleSubmit}>
              <div className="flex flex-col gap-8">
                <InputField5
                  type={`password`}
                  label={`Your OpenAI integration key`}
                  name="openai_api_key"
                  isRequired={true}
                  value={formData.openai_api_key}
                  onChange={handleChange}
                  placeholder={`Input your OpenAI  key`}
                />
              </div>

              <div className="flex w-full justify-start mt-10">
                <div className="flex gap-2">
                  <div className="min-w-32">
                    <GlobalButton
                      variant={'blue'}
                      size={'md'}
                      state={isSubmitting ? 'inactive' : 'default'}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting
                        ? 'Integrating...'
                        : 'Integrate with OpenAI'}
                    </GlobalButton>
                  </div>

                  <div className="min-w-32">
                    <GlobalButton
                      onClick={handleCancel}
                      variant={'stroke'}
                      size={'md'}
                      state={'default'}
                    >
                      Cancel
                    </GlobalButton>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
