import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Body from './Body';
import { useProjects } from '../../context/ProjectsContext';

export default function Homepage({
  onCreateTickets,
  sentProjects,
  setSentProjects,
  projectsTotal,
  setProjectsTotal,
  setCreateTicketsResponse,
}) {
  const [loading, setLoading] = useState(false);

  const { projects, loading: projectsLoading, refetchProjects } = useProjects();

  return (
    <main className="relative text-gray-4 h-screen w-full flex flex-col">
      <Helmet>
        <title>Create ticket | Crafter</title>
      </Helmet>
      <div className="flex w-full h-full innerFrameBg">
        <div className={`w-full overflow-hidden h-full `}>
          <Body
            onCreateTickets={onCreateTickets}
            projectsTotal={projectsTotal}
            setProjectsTotal={setProjectsTotal}
            sentProjects={sentProjects}
            setSentProjects={setSentProjects}
            setCreateTicketsResponse={setCreateTicketsResponse}
            projects={projects}
            loading={projectsLoading}
            refetchProjects={refetchProjects}
          />
        </div>
      </div>
    </main>
  );
}
