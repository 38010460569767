import React from 'react';

export default function PdfIcon() {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.1875 0C4.4375 0 3 1.4375 3 3.1875V36.8125C3 38.5625 4.4375 40 6.1875 40H34.8125C36.5625 40 38 38.5625 38 36.8125V12.6875L26.1875 0H6.1875Z"
        fill="#FF2A2A"
      />
      <path
        d="M38 12.75V13.375H30C30 13.375 26.0625 12.5625 26.1875 9.1875C26.1875 9.1875 26.3125 12.75 29.9375 12.75H38Z"
        fill="#F5051E"
      />
      <path
        opacity="0.5"
        d="M26.1875 0V9.125C26.1875 10.1875 26.875 12.75 30 12.75H38L26.1875 0Z"
        fill="white"
      />
      <path
        d="M12.3125 30.625H10.25V33.1875C10.25 33.4375 10.0625 33.625 9.75 33.625C9.5 33.625 9.3125 33.4375 9.3125 33.1875V26.8125C9.3125 26.4375 9.625 26.125 10 26.125H12.3125C13.8125 26.125 14.6875 27.1875 14.6875 28.375C14.6875 29.625 13.8125 30.625 12.3125 30.625ZM12.25 26.9375H10.25V29.8125H12.25C13.125 29.8125 13.75 29.25 13.75 28.375C13.75 27.5 13.125 26.9375 12.25 26.9375ZM18.75 33.625H16.875C16.5 33.625 16.1875 33.3125 16.1875 32.9375V26.8125C16.1875 26.4375 16.5 26.125 16.875 26.125H18.75C21.0625 26.125 22.625 27.75 22.625 29.875C22.625 32 21.125 33.625 18.75 33.625ZM18.75 26.9375H17.125V32.75H18.75C20.5625 32.75 21.625 31.4375 21.625 29.8125C21.6875 28.25 20.625 26.9375 18.75 26.9375ZM28.9375 26.9375H25.3125V29.375H28.875C29.125 29.375 29.25 29.5625 29.25 29.8125C29.25 30.0625 29.0625 30.1875 28.875 30.1875H25.3125V33.1875C25.3125 33.4375 25.125 33.625 24.8125 33.625C24.5625 33.625 24.375 33.4375 24.375 33.1875V26.8125C24.375 26.4375 24.6875 26.125 25.0625 26.125H28.9375C29.1875 26.125 29.3125 26.3125 29.3125 26.5625C29.375 26.75 29.1875 26.9375 28.9375 26.9375Z"
        fill="white"
      />
    </svg>
  );
}
