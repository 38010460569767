import React from 'react';

export default function Step2() {
  return (
    <div className="flex flex-col items-start p-0">
      <span className="font-semibold text-[12px]">1 of 5</span>

      <p className="mt-3 text-[12px] text-left">
        To get started on Crafter you need to select your Jira projects to
        initiate analysis.
      </p>
    </div>
  );
}
