import React, { createContext, useContext, useState, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import WebAppService from '@/services/WebAppService';
import useAuthStore from '@/store/Auth';
import { useToast } from './ToastContext';

const ProjectsContext = createContext();

export const ProjectsProvider = ({ children }) => {
  const [organizationLoading, setOrganizationLoading] = useState(false);
  const { user, isAuthenticated } = useAuthStore(); // Get the current user and auth state from AuthStore
  const [projects, setProjects] = useState([]);
  const queryClient = useQueryClient();
  const authStore = useAuthStore();
  const { addToast } = useToast();
  const organizationId = authStore.user.organization;

  // useEffect(() => {
  // 	const fetchOrganization = async () => {
  // 		if (organizationId) {
  // 			setOrganizationLoading(true);
  // 			try {
  // 				const orgData = await WebAppService.getOrganizationById(
  // 					organizationId
  // 				);
  // 				console.log(orgData);
  // 			} catch (error) {
  // 				console.error("Error fetching organization:", error);
  // 				// addToast(
  // 				// 	`${error?.response?.data?.error || "Error fetching organization"}`,
  // 				// 	"error",
  // 				// 	3000
  // 				// );
  // 			} finally {
  // 				setOrganizationLoading(false);
  // 			}
  // 		}
  // 	};

  // 	fetchOrganization();
  // }, [organizationId, addToast]);

  const { data: projectsData, isLoading: projectsLoading } = useQuery(
    [
      'projects',
      {
        page: 1,
        searchQuery: '',
        limit: 10,
      },
      user?.id,
    ],
    WebAppService.getAllProjects,
    {
      enabled: isAuthenticated, // Only fetch if the user is authenticated
      onSuccess: (data) => {
        setProjects(data.data || []);
      },
      onError: (error) => {
        console.error('Error fetching projects:', error);
        addToast(
          `${error?.response?.data?.error || 'Error fetching projects'}`,
          'error',
          3000
        );
      },
    }
  );

  const refetchProjects = (page = 1, searchQuery = '', limit = 10) => {
    queryClient.invalidateQueries([
      'projects',
      {
        page,
        searchQuery,
        limit,
      },
      user?.id,
    ]);
  };

  return (
    <ProjectsContext.Provider
      value={{
        projects,
        projectsLoading,
        refetchProjects,
        organizationLoading,
      }}
    >
      {children}
    </ProjectsContext.Provider>
  );
};

export const useProjects = () => useContext(ProjectsContext);
