import caution from '../assets/caution.png';
import trophy from '../assets/trophy.png';

export const Warning = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M17.5666 7.15002V12.85C17.5666 13.7833 17.0666 14.65 16.2583 15.125L11.3083 17.9834C10.5 18.45 9.49994 18.45 8.68327 17.9834L3.73327 15.125C2.92493 14.6584 2.42493 13.7917 2.42493 12.85V7.15002C2.42493 6.21669 2.92493 5.34998 3.73327 4.87498L8.68327 2.01666C9.4916 1.54999 10.4916 1.54999 11.3083 2.01666L16.2583 4.87498C17.0666 5.34998 17.5666 6.20835 17.5666 7.15002Z"
        fill="#FF4E4E"
      />
      <path
        d="M10 11.4583C9.65833 11.4583 9.375 11.175 9.375 10.8333V6.45834C9.375 6.11668 9.65833 5.83334 10 5.83334C10.3417 5.83334 10.625 6.11668 10.625 6.45834V10.8333C10.625 11.175 10.3417 11.4583 10 11.4583Z"
        fill="#FF4E4E"
      />
      <path
        d="M9.99996 14.375C9.89163 14.375 9.78329 14.35 9.68329 14.3083C9.57496 14.2666 9.49161 14.2083 9.40827 14.1333C9.33327 14.05 9.27497 13.9583 9.22497 13.8583C9.1833 13.7583 9.16663 13.65 9.16663 13.5417C9.16663 13.325 9.24994 13.1083 9.40827 12.95C9.49161 12.875 9.57496 12.8167 9.68329 12.775C9.99162 12.6417 10.3583 12.7166 10.5916 12.95C10.6666 13.0333 10.7249 13.1167 10.7666 13.225C10.8083 13.325 10.8333 13.4333 10.8333 13.5417C10.8333 13.65 10.8083 13.7583 10.7666 13.8583C10.7249 13.9583 10.6666 14.05 10.5916 14.1333C10.4333 14.2917 10.225 14.375 9.99996 14.375Z"
        fill="#FF4E4E"
      />
    </svg>
  );
};

export const Caution = () => {
  return <img className="w-5" src={caution} alt=""></img>;
};
export const Trophy = () => {
  return <img className="w-5" src={trophy} alt=""></img>;
};

export const LineChartArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
        fill=""
        fill-opacity="0.4"
        stroke="currentColor"
        stroke-width="0.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 7.75V4.75"
        stroke="currentColor"
        stroke-width="0.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M4.5 5.75L6 4.25L7.5 5.75" fill="currentColor" />
      <path
        d="M4.5 5.75L6 4.25L7.5 5.75"
        stroke="currentColor"
        stroke-width="0.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
