import React, { useState } from 'react';

import GlobalButton from '../../../components/ui/GlobalButton';
import ExportIcon from '../../../icons/ExportIcon';
import Caret from '../../../icons/Caret';

export default function ExportButtonDropdown({ options, onSelect, state }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleButtonClick = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOptionSelect = (option) => {
    if (onSelect && typeof onSelect === 'function') {
      onSelect(option);
    }
    setIsOpen(false);
  };

  return (
    <div className="relative">
      <GlobalButton
        variant={'blue'}
        size={'md'}
        state={state}
        onClick={handleButtonClick}
        className={`font-semibold flex gap-2 rounded-lg`}
      >
        <span>
          <ExportIcon />
        </span>
        Export insights
        <span>
          <Caret />
        </span>
      </GlobalButton>

      {isOpen && (
        <div className="z-40 absolute border-0.5 border-gray-5 ml-[-50px] w-[250px] bg-black-1 mt-2 rounded-md shadow-lg py-4 px-4">
          <p className="text-lg font-semibold">Export insights as:</p>
          <ul className="w-full mt-2 flex flex-col gap-2 overflow-hidden text-gray-700">
            {options.map((option, index) => (
              <li
                key={index}
                onClick={() => handleOptionSelect(option)}
                className={`${option.isActive ? 'cursor-pointer hover:bg-gray-5' : 'cursor-not-allowed opacity-45 '} py-3 optionBg  rounded-lg px-4 `}
              >
                <span className="flex flex-row gap-2 items-center text-white-2">
                  {option.icon} {option.label}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
