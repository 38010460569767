import React from 'react';
import CustomModalOverlay2 from '../../../common/Custom/CustomModalOverlay2/CustomModalOverlay2';
import GlobalButton from '@/components/ui/GlobalButton';
import useActionStore from '../../../../store/Actions';
import { Link } from 'react-router-dom';
import useIntegrationsStore from '../../../../store/Integrations';

export default function IntegrationFailed() {
  const { integrationFailedModal, toggleIntegrationFailedModal } =
    useActionStore();
  const { selectedIntegration } = useIntegrationsStore();

  const handleClose = () => {
    toggleIntegrationFailedModal(false);
  };
  return (
    <CustomModalOverlay2
      open={integrationFailedModal}
      onClose={handleClose}
      width="600px"
      radius="12px"
      withCloseButton={false}
    >
      <div className={`flex flex-col items-start`}>
        <div className="w-full flex flex-col gap-1 items-start">
          <h1 className="font-bold text-[20px] text-white-2">
            Integration failed
          </h1>
          <p className="text-gray-4 text-left">
            Looks like we hit an iceberg there, please try integrating your{' '}
            {selectedIntegration} account again
          </p>
        </div>
        <div className="w-full mt-8">
          <GlobalButton
            onClick={handleClose}
            variant={'blue'}
            state={'default'}
            size={'lg'}
          >
            Integrate your {selectedIntegration}
          </GlobalButton>
        </div>
        <div className="mt-5">
          <p>
            Need help?{' '}
            <Link
              to={'https://usecrafter.com/contact'}
              target="_blank"
              className="text-orange-1 underline underline-offset-4 font-semibold"
            >
              Contact us
            </Link>
          </p>
        </div>
      </div>
    </CustomModalOverlay2>
  );
}
