import React from 'react';
import { Link } from 'react-router-dom';

export default function Breadcrumb({ items, separator = '>' }) {
  return (
    <nav aria-label="breadcrumb">
      <ul style={styles.breadcrumbList}>
        {items.map((item, index) => (
          <li key={index} style={styles.breadcrumbItem}>
            {item.link ? (
              <Link
                to={item.link}
                // style={styles.link}
                className={`${item.isActive ? 'text-white-1 font-semibold' : 'text-[#737373]'} text-xs hover:text-white-1 transition-all duration-300`}
              >
                {item.label}
              </Link>
            ) : (
              <span
                className={`${item.isActive ? 'text-white-1 font-semibold' : 'text-[#737373]'} text-xs`}
                // style={styles.label}
              >
                {item.label}
              </span>
            )}
            {index < items.length - 1 && (
              <span style={styles.separator}>
                <svg
                  width="12"
                  height="13"
                  viewBox="0 0 12 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.45508 10.46L7.71508 7.20004C8.10008 6.81504 8.10008 6.18504 7.71508 5.80004L4.45508 2.54004"
                    stroke="#737373"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
}

// Inline styles for simplicity
const styles = {
  breadcrumbList: {
    display: 'flex',
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  breadcrumbItem: {
    display: 'flex',
    alignItems: 'center',
  },

  separator: {
    margin: '0 8px',
    color: '#737373',
  },
};
