export const Stale = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.1125 6C0.1125 2.74842 2.74842 0.1125 6 0.1125C9.25158 0.1125 11.8875 2.74842 11.8875 6C11.8875 9.25158 9.25158 11.8875 6 11.8875C2.74842 11.8875 0.1125 9.25158 0.1125 6Z"
        stroke="#737373"
        stroke-width="0.225"
      />
      <circle opacity="0.25" cx="6" cy="6" r="6" fill="#939393" />
      <path
        d="M6.00008 9.58332C4.02341 9.58332 2.41675 7.97666 2.41675 5.99999C2.41675 4.02332 4.02341 2.41666 6.00008 2.41666C7.97675 2.41666 9.58341 4.02332 9.58341 5.99999C9.58341 7.97666 7.97675 9.58332 6.00008 9.58332ZM6.00008 2.91666C4.30008 2.91666 2.91675 4.29999 2.91675 5.99999C2.91675 7.69999 4.30008 9.08332 6.00008 9.08332C7.70008 9.08332 9.08341 7.69999 9.08341 5.99999C9.08341 4.29999 7.70008 2.91666 6.00008 2.91666Z"
        fill="#939393"
      />
      <path
        d="M6.00008 6.33332C5.81341 6.33332 5.66675 6.18332 5.66675 5.99999C5.66675 5.81666 5.81675 5.66666 6.00008 5.66666C6.18341 5.66666 6.33342 5.81666 6.33342 5.99999C6.33342 6.18332 6.18675 6.33332 6.00008 6.33332Z"
        fill="#939393"
      />
      <path
        d="M7.33333 6.33332C7.14667 6.33332 7 6.18332 7 5.99999C7 5.81666 7.15 5.66666 7.33333 5.66666C7.51667 5.66666 7.66667 5.81666 7.66667 5.99999C7.66667 6.18332 7.52 6.33332 7.33333 6.33332Z"
        fill="#939393"
      />
      <path
        d="M4.66659 6.33332C4.47992 6.33332 4.33325 6.18332 4.33325 5.99999C4.33325 5.81666 4.48325 5.66666 4.66659 5.66666C4.84992 5.66666 4.99992 5.81666 4.99992 5.99999C4.99992 6.18332 4.85325 6.33332 4.66659 6.33332Z"
        fill="#939393"
      />
    </svg>
  );
};

export const UpTrend = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.1125 6C0.1125 2.74842 2.74842 0.1125 6 0.1125C9.25158 0.1125 11.8875 2.74842 11.8875 6C11.8875 9.25158 9.25158 11.8875 6 11.8875C2.74842 11.8875 0.1125 9.25158 0.1125 6Z"
        stroke="#48AA3B"
        stroke-width="0.225"
      />
      <circle opacity="0.25" cx="6" cy="6" r="6" fill="#48AA3B" />
      <path
        d="M10.1562 8.3125C10.1562 8.38709 10.1266 8.45863 10.0739 8.51137C10.0211 8.56412 9.94959 8.59375 9.875 8.59375H3.125C3.05041 8.59375 2.97887 8.56412 2.92613 8.51137C2.87338 8.45863 2.84375 8.38709 2.84375 8.3125V2.6875C2.84375 2.61291 2.87338 2.54137 2.92613 2.48863C2.97887 2.43588 3.05041 2.40625 3.125 2.40625C3.19959 2.40625 3.27113 2.43588 3.32387 2.48863C3.37662 2.54137 3.40625 2.61291 3.40625 2.6875V6.50863L5.17602 4.73852C5.20214 4.71237 5.23315 4.69162 5.2673 4.67747C5.30144 4.66331 5.33804 4.65603 5.375 4.65603C5.41196 4.65603 5.44856 4.66331 5.4827 4.67747C5.51685 4.69162 5.54786 4.71237 5.57398 4.73852L6.5 5.66488L8.35238 3.8125H7.625C7.55041 3.8125 7.47887 3.78287 7.42613 3.73012C7.37338 3.67738 7.34375 3.60584 7.34375 3.53125C7.34375 3.45666 7.37338 3.38512 7.42613 3.33238C7.47887 3.27963 7.55041 3.25 7.625 3.25H9.03125C9.10584 3.25 9.17738 3.27963 9.23012 3.33238C9.28287 3.38512 9.3125 3.45666 9.3125 3.53125V4.9375C9.3125 5.01209 9.28287 5.08363 9.23012 5.13637C9.17738 5.18912 9.10584 5.21875 9.03125 5.21875C8.95666 5.21875 8.88512 5.18912 8.83238 5.13637C8.77963 5.08363 8.75 5.01209 8.75 4.9375V4.21012L6.69898 6.26148C6.67286 6.28763 6.64185 6.30838 6.6077 6.32253C6.57356 6.33669 6.53696 6.34397 6.5 6.34397C6.46304 6.34397 6.42644 6.33669 6.3923 6.32253C6.35815 6.30838 6.32714 6.28763 6.30102 6.26148L5.375 5.33512L3.40625 7.30387V8.03125H9.875C9.94959 8.03125 10.0211 8.06088 10.0739 8.11363C10.1266 8.16637 10.1562 8.23791 10.1562 8.3125Z"
        fill="#76FE65"
      />
    </svg>
  );
};
