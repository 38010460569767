import React from 'react';

const Step3 = () => {
  return (
    <div className="flex flex-col items-start p-0">
      <span className="font-semibold text-[12px]">2 of 5</span>

      <p className="mt-3 text-[12px] text-left">
        Select the epic to narrow down your analytics option to get more
        insights on your project.
      </p>
    </div>
  );
};

export default Step3;
