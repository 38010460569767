// import axios from 'axios';

// const WebAppAPI = () => {
//   const authToken = sessionStorage.getItem('authToken');
//   let headers = {};

//   if (authToken) {
//     headers = {
//       Authorization: `Bearer ${authToken}`,
//     };
//   }
//   const baseURL = process.env.REACT_APP_ACCESS_CODE_URL;

//   const axiosInstance = axios.create({
//     baseURL: baseURL,
//     timeout: 30000,
//     headers,
//   });

//   return axiosInstance;
// };

// export default WebAppAPI;

import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const isTokenExpired = (token) => {
  try {
    const decoded = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);
    return decoded.exp < currentTime;
  } catch (error) {
    console.error('Invalid token:', error);
    return true; // Treat invalid tokens as expired
  }
};

const logout = () => {
  sessionStorage.removeItem('authToken');
  window.location.href = '/login'; // Redirect to login page
};

const WebAppAPI = () => {
  const authToken = sessionStorage.getItem('authToken');
  const baseURL = process.env.REACT_APP_ACCESS_CODE_URL;

  const axiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 30000,
    headers: authToken ? { Authorization: `Bearer ${authToken}` } : {},
  });

  // Request Interceptor
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = sessionStorage.getItem('authToken');

      // Check if the token is expired
      if (token && isTokenExpired(token)) {
        logout(); // Log the user out if the token is expired
        return Promise.reject(new Error('Token expired'));
      }

      // Add the token to headers if it exists
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  // Optional: Response Interceptor for error handling
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      // Handle unauthorized responses (e.g., token expiry detected on the server)
      if (error.response?.status === 401) {
        logout();
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default WebAppAPI;
