import React, { useEffect, useState } from 'react';
import JiraIcon from '@/icons/JiraIcon';
import GlobalButton from '@/components/ui/GlobalButton';
import GlobalDropdown from '@/components/ui/GlobalDropdown';
import useAuthStore from '@/store/Auth';
import WebAppService from '@/services/WebAppService';
import { Link } from 'react-router-dom';
import { Navigate, useNavigate } from 'react-router-dom';
import ErrorToast from '@/components/modals/ErrorToast';
import JiraStatusModal from './JiraStatusModal';

export default function SendToJiraModal({ onClose, type, projectId }) {
  const [jiraProjectKeys, setJiraProjectKeys] = useState([]);
  const [selectedProjectKey, setSelectedProjectKey] = useState('');
  const [jiraStatusModal, setJiraStatusModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errorToast, setErrorToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(null);
  const authStore = useAuthStore();
  const navigate = useNavigate();

  const { organization } = authStore;
  const { hasSetJiraConfig } = organization;
  // const hasSetJiraConfig = false;

  const { user } = authStore;
  const organizationId = user.organization;
  const jira_url = organization.jira_url;

  const closeJiraStatusModal = () => {
    setJiraStatusModal(false);
  };

  useEffect(() => {
    WebAppService.getOrganizationJiraKey(organizationId)
      .then((response) => {
        const jiraKeys = response.data.values.map((project) => ({
          label: `${project.key} - ${project.name}`,
          value: project.key,
        }));

        setJiraProjectKeys(jiraKeys);
      })
      .catch((error) => {
        setError(error);
        console.error('Error getting Jira keys', error);
      });
  }, [organizationId]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const project_key = selectedProjectKey?.value;

    if (projectId && project_key) {
      setIsSubmitting(true);
      try {
        const sendProjectToJiraResponse = await WebAppService.sendProjectToJira(
          projectId,
          {
            project_key: project_key,
          }
        );

        // console.log(sendProjectToJiraResponse);

        if (sendProjectToJiraResponse?.success === true) {
          setFormSubmitted(true);
          // Close the modal after 5 seconds
          setTimeout(() => {
            onClose();
          }, 5000);
        } else {
        }
      } catch (error) {
        setErrorMessage(
          error?.response?.data?.error ||
            'Something went wrong. Please try again'
        );
        setTimeout(() => {
          setErrorToast(true);
        }, 1000);
        // setIsSubmitting(false);

        setTimeout(() => {
          setErrorToast(false);
        }, 4000);

        setTimeout(() => {
          onClose();
        }, 4000);
      }
    }
  };

  return (
    <div className="modal">
      <div
        onClick={(event) => {
          event.stopPropagation();
          onClose();
        }}
        className="modal-overlay absolute inset-0 bg-black-1/40 opacity-100"
      ></div>
      {formSubmitted ? (
        <JiraStatusModal type={type} onClose={onClose} />
      ) : (
        <div className="modalBg3 overflow-scroll px-8 py-10 w-[90%] md:w-[90%] lg:w-[40%] xl:max-w-[600px] max-h-[90%] h-fit top-1/2 left-1/2 absolute transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-start rounded-lg shadow-md text-white-1">
          {!hasSetJiraConfig && (
            <div className="flex items-center gap-2 pb-8">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.4"
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                  fill="#FF9595"
                />
                <path
                  d="M12 13.75C12.41 13.75 12.75 13.41 12.75 13V8C12.75 7.59 12.41 7.25 12 7.25C11.59 7.25 11.25 7.59 11.25 8V13C11.25 13.41 11.59 13.75 12 13.75Z"
                  fill="#FF9595"
                />
                <path
                  d="M12.92 15.62C12.87 15.5 12.8 15.39 12.71 15.29C12.61 15.2 12.5 15.13 12.38 15.08C12.14 14.98 11.86 14.98 11.62 15.08C11.5 15.13 11.39 15.2 11.29 15.29C11.2 15.39 11.13 15.5 11.08 15.62C11.03 15.74 11 15.87 11 16C11 16.13 11.03 16.26 11.08 16.38C11.13 16.51 11.2 16.61 11.29 16.71C11.39 16.8 11.5 16.87 11.62 16.92C11.74 16.97 11.87 17 12 17C12.13 17 12.26 16.97 12.38 16.92C12.5 16.87 12.61 16.8 12.71 16.71C12.8 16.61 12.87 16.51 12.92 16.38C12.97 16.26 13 16.13 13 16C13 15.87 12.97 15.74 12.92 15.62Z"
                  fill="#FF9595"
                />
              </svg>

              <p className="text-error-2">Cannot send project to Jira</p>
            </div>
          )}
          <div className="flex flex-col gap-3 items-start justify-start w-fit text-left">
            <div className="flex gap-2">
              <JiraIcon />
              <h2 className="font-bold text-[20px]">Send {type} to Jira</h2>
            </div>
            {hasSetJiraConfig ? (
              <p className="text-gray-4 text-[14px]">
                You are about to send this {type} and all the tickets in it to
                Jira
              </p>
            ) : (
              <p className="text-gray-4 text-[14px]">
                Your Jira integration is not set, you have to set it to help us
                connect with your organization account
              </p>
            )}
          </div>

          {hasSetJiraConfig ? (
            <form
              onSubmit={handleSubmit}
              className="w-full flex flex-col gap-2 mt-5"
            >
              {error ? null : (
                <div
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  className="font-semibold text-white-2"
                >
                  <GlobalDropdown
                    isRequired={true}
                    label={`Select your project`}
                    placeholder={`Select Jira project key`}
                    options={jiraProjectKeys}
                    value={selectedProjectKey}
                    onChange={setSelectedProjectKey}
                    disabled={error}
                  />
                </div>
              )}
              {error && (
                <p className="text-gray-4 text-center  mt-3">
                  You do not have any Jira Projects.{' '}
                  <Link
                    to={jira_url}
                    className="text-blue-1 font-semibold underline underline-offset-4"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Create here
                  </Link>
                </p>
              )}
              {error ? null : (
                <div className={'mt-8 flex items-center gap-8 w-full'}>
                  <div
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    className="flex w-full"
                  >
                    <GlobalButton
                      type={'submit'}
                      variant={'blue'}
                      size={'lg'}
                      state={isSubmitting ? 'inactive' : 'default'}
                    >
                      {isSubmitting ? 'Sending...' : `Send ${type} to Jira`}
                    </GlobalButton>
                  </div>
                  <GlobalButton
                    onClick={(event) => {
                      event.stopPropagation();
                      onClose();
                    }}
                    variant={`redStroke`}
                    state={`default`}
                    size={`lg`}
                    className={``}
                  >
                    Cancel
                  </GlobalButton>
                </div>
              )}
            </form>
          ) : (
            <div
              className={
                'mt-8 flex items-center justify-center mx-auto gap-8 w-full'
              }
            >
              <GlobalButton
                onClick={(event) => {
                  event.stopPropagation();
                  navigate('/integrations');
                }}
                variant={'blue'}
                size={'lg'}
                state={'default'}
              >
                Integrate your Jira
              </GlobalButton>

              <GlobalButton
                onClick={(event) => {
                  event.stopPropagation();
                  onClose();
                }}
                variant={`redStroke`}
                state={`default`}
                size={`lg`}
                className={``}
              >
                Cancel
              </GlobalButton>
            </div>
          )}
        </div>
      )}

      {/* {jiraStatusModal && <JiraStatusModal />} */}
      {errorToast && <ErrorToast message={errorMessage} />}
    </div>
  );
}
