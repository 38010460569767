import React from 'react';

export default function Insights({ active }) {
  return (
    <>
      {active ? (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.4"
            d="M15.938 4.53184L15.9389 4.53226C16.4895 4.77612 16.7599 5.04414 16.8961 5.25374C17.0319 5.46278 17.0581 5.65058 17.0581 5.7751C17.0581 5.89671 17.0311 6.08487 16.8936 6.29545C16.7559 6.50639 16.4844 6.77464 15.938 7.01835C15.9379 7.01837 15.9379 7.01838 15.9379 7.0184C15.9378 7.01841 15.9378 7.01843 15.9378 7.01845L11.056 9.19275C11.0558 9.19284 11.0556 9.19293 11.0554 9.19301C10.7727 9.31781 10.3942 9.39176 9.99974 9.39176C9.60533 9.39176 9.22684 9.31782 8.94412 9.19303C8.94391 9.19294 8.9437 9.19285 8.94349 9.19275L4.06171 7.01845C4.06164 7.01842 4.06157 7.01838 4.0615 7.01835C3.51509 6.77464 3.24363 6.50639 3.10587 6.29545C2.96835 6.08487 2.94141 5.89671 2.94141 5.7751C2.94141 5.65349 2.96835 5.46533 3.10587 5.25474C3.24363 5.04381 3.51509 4.77555 4.0615 4.53184C4.06157 4.53181 4.06164 4.53178 4.06171 4.53175L8.94349 2.35744C8.9437 2.35735 8.94392 2.35725 8.94413 2.35716C9.22423 2.23354 9.60179 2.1626 9.99974 2.1626C10.3977 2.1626 10.7753 2.23355 11.0554 2.35718C11.0556 2.35727 11.0558 2.35735 11.056 2.35744L15.938 4.53184Z"
            fill="#0182AB"
            stroke="#0182AB"
          />
          <path
            opacity="0.4"
            d="M10.0003 14.1998C9.68359 14.1998 9.36693 14.1332 9.07526 14.0082L3.45859 11.5082C2.60026 11.1248 1.93359 10.0998 1.93359 9.15817C1.93359 8.8165 2.20859 8.5415 2.55026 8.5415C2.89193 8.5415 3.16693 8.8165 3.16693 9.15817C3.16693 9.60817 3.54193 10.1915 3.95859 10.3748L9.57526 12.8748C9.84193 12.9915 10.1503 12.9915 10.4169 12.8748L16.0336 10.3748C16.4503 10.1915 16.8253 9.6165 16.8253 9.15817C16.8253 8.8165 17.1003 8.5415 17.4419 8.5415C17.7836 8.5415 18.0586 8.8165 18.0586 9.15817C18.0586 10.0915 17.3919 11.1248 16.5336 11.5082L10.9169 14.0082C10.6336 14.1332 10.3169 14.1998 10.0003 14.1998Z"
            fill="#F58E30"
          />
          <path
            d="M10.0003 18.3331C9.68359 18.3331 9.36693 18.2665 9.07526 18.1415L3.45859 15.6415C2.53359 15.2331 1.93359 14.3081 1.93359 13.2915C1.93359 12.9498 2.20859 12.6748 2.55026 12.6748C2.89193 12.6748 3.16693 12.9498 3.16693 13.2915C3.16693 13.8165 3.47526 14.2915 3.95859 14.5081L9.57526 17.0081C9.84193 17.1248 10.1503 17.1248 10.4169 17.0081L16.0336 14.5081C16.5086 14.2998 16.8253 13.8165 16.8253 13.2915C16.8253 12.9498 17.1003 12.6748 17.4419 12.6748C17.7836 12.6748 18.0586 12.9498 18.0586 13.2915C18.0586 14.3081 17.4586 15.2248 16.5336 15.6415L10.9169 18.1415C10.6336 18.2665 10.3169 18.3331 10.0003 18.3331Z"
            fill="#86A16B"
          />
        </svg>
      ) : (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.8416 2.43335L15.7583 4.61668C17.1749 5.24168 17.1749 6.27502 15.7583 6.90002L10.8416 9.08335C10.2833 9.33335 9.3666 9.33335 8.80827 9.08335L3.8916 6.90002C2.47493 6.27502 2.47493 5.24168 3.8916 4.61668L8.80827 2.43335C9.3666 2.18335 10.2833 2.18335 10.8416 2.43335Z"
            stroke="#A6A6A6"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2.5 9.16675C2.5 9.86675 3.025 10.6751 3.66667 10.9584L9.325 13.4751C9.75833 13.6667 10.25 13.6667 10.675 13.4751L16.3333 10.9584C16.975 10.6751 17.5 9.86675 17.5 9.16675"
            stroke="#A6A6A6"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2.5 13.3333C2.5 14.1083 2.95833 14.8083 3.66667 15.1249L9.325 17.6416C9.75833 17.8333 10.25 17.8333 10.675 17.6416L16.3333 15.1249C17.0417 14.8083 17.5 14.1083 17.5 13.3333"
            stroke="#A6A6A6"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      )}
    </>
  );
}
