import CrafterLogo from '@/icons/CrafterLogo';
import React from 'react';
import { Link } from 'react-router-dom';
import SignUpForm from './SignUpForm';
import { Helmet } from 'react-helmet';

export default function SignUp() {
  return (
    <main className="bg-black-7 authBgImg text-gray-4 min-h-screen w-full flex flex-col gap-y-[20px]  py-[30px] px-[15px] sm:px-[30px] lg:px-[100px] md:px-[50px]">
      <Helmet>
        <title>Sign up with Crafter</title>
        <meta
          name="description"
          content="Create your Crafter account to start managing projects, collaborating with your team, and unlocking productivity tools for your workflow."
        />
      </Helmet>

      <SignUpForm />
    </main>
  );
}
