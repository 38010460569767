import React, { useState } from 'react';
import LiteCard from './LiteCard';
import ProCard from './ProCard';
import FreeCard from './FreeCard';

export default function Plan({ plans, onCheckout, buttonState }) {
  const [activePeriod, setActivePeriod] = useState('Monthly');
  const freePlan = plans[1];
  const litePlan = plans[0];

  const handlePlanClick = (productId) => {
    if (onCheckout) {
      onCheckout(productId);
    }
  };

  return (
    <div
      id="container"
      className="flex flex-col gap-4 w-full h-full px-8 pt-8 pb-3 mt-8"
    >
      <div className="bg1 rounded-xl overflow-scroll bg1 py-8 px-8 h-full">
        <div className="flex flex-col items-start">
          <h1 className="font-semibold text-[24px] tracking-tight">
            Plans & billings
          </h1>
          <p className="text-gray-4">
            Get started with Crafter and experience the power of a seamless
            workflow
          </p>
        </div>

        <div className="grid grid-cols-1 xl:grid-cols-3 gap-10 items-start justify-center mx-auto w-full max-w-[1440px] h-[600px] mt-8">
          <div className="w-full h-full opacity-50 cursor-not-allowed">
            <FreeCard />
          </div>
          <div className="w-full h-full">
            <LiteCard
              activePeriod={activePeriod}
              litePlan={litePlan}
              onPlanSelect={handlePlanClick}
              buttonState={buttonState}
            />
          </div>
          <div className="w-full h-full">
            <ProCard activePeriod={activePeriod} />
          </div>
        </div>
      </div>
    </div>
  );
}
