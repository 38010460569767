import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

const initialState = {
  jiraProjectEpicChildren: {},
  selectedProject: '',
  selectedEpic: '',
  selectedChart: '',
  childrenLoading: false,
  jiraProjectEpics: {},
  jiraProjects: [],
  insightActiveComponent: 'insights',
};

const useInsightsStore = create(
  persist(
    (set) => ({
      ...initialState,
      updateJiraProjects: (projects) =>
        set({
          jiraProjects: [...projects],
        }),

      updateJiraProjectEpics: (epics) => {
        set(() => ({
          jiraProjectEpics: {
            message: epics.message,
            count: epics.count,
            refreshTime: new Date(epics.refreshTime).toLocaleString(),
            issues: epics.issues,
          },
        }));
      },
      updateJiraProjectEpicChildren: (epicChildren) =>
        set(() => ({
          jiraProjectEpicChildren: {
            message: epicChildren.message,
            projectKey: epicChildren.projectKey,
            count: epicChildren.count,
            refreshTime: new Date(epicChildren.refreshTime).toLocaleString(),
            issues: epicChildren.issues,
            completionStatus: epicChildren.completionStatus,
            progressPercentage: epicChildren.progressPercentage,
          },
        })),
      updateSelectedProject: (project) =>
        set({
          selectedProject: project,
        }),
      updateSelectedEpic: (epic) =>
        set({
          selectedEpic: epic,
        }),
      updateSelectedChart: (chart) =>
        set({
          selectedChart: chart,
        }),
      setChildrenLoading: (loading) =>
        set({
          childrenLoading: loading,
        }),
      setInsightActiveComponent: (component) =>
        set({
          insightActiveComponent: component,
        }),
    }),
    {
      name: 'insights',
      getStorage: () => createJSONStorage(() => sessionStorage),
    }
  )
);

export default useInsightsStore;
