import { create } from 'zustand';
import WebAppAPI from '../utils/WebAppAPI';
import { persist, createJSONStorage } from 'zustand/middleware';
import { v4 as uuidv4 } from 'uuid'; // For generating unique IDs

const useChatsStore = create(
  persist(
    (set, get) => ({
      conversation: [],
      inputValue: '',
      // Add a new chat object with an initial user question
      addChatObject: (userQuestion) => {
        const newChatObject = {
          id: uuidv4(),
          threadId: null,
          userQuestion,
          aiResponse: null,
          timestamp: new Date().toISOString(),
        };
        set((state) => ({
          conversation: [...state.conversation, newChatObject],
        }));
        return newChatObject.id; // Return the chat ID to update later
      },
      // Update an existing chat object by ID
      updateChatObject: (chatId, updatedFields) =>
        set((state) => ({
          conversation: state.conversation.map((chat) =>
            chat.id === chatId ? { ...chat, ...updatedFields } : chat
          ),
        })),

      setInputValue: (newValue) =>
        set(() => ({
          inputValue: newValue,
        })),
    }),
    {
      name: 'chats',
      getStorage: () => createJSONStorage(() => sessionStorage),
    }
  )
);

export default useChatsStore;

export const fetchAIResponse = async (question, projectKey, epicId) => {
  const { addChatObject, updateChatObject } = useChatsStore.getState();

  // Step 1: Add a new chat object with the user question
  const chatId = addChatObject(question);

  try {
    // Step 2: Make API call
    const response = await WebAppAPI().post(
      `/reports/${projectKey}/${epicId}/chat`,
      { prompt: question }
    );

    // console.log(response.data);

    const { threadId, message } = response.data;
    const aiResponse = message;

    updateChatObject(chatId, { threadId, aiResponse });
  } catch (error) {
    console.error('Error fetching AI response:', error);

    updateChatObject(chatId, {
      threadId: null,
      aiResponse: 'Error occurred while fetching response.',
    });
  }
};
