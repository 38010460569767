import React, { useState, useEffect, useRef } from 'react';
import GlobalButton from '@/components/ui/GlobalButton';
import WebAppService from '@/services/WebAppService';
import useAuthStore from '@/store/Auth';
import { Link, useNavigate } from 'react-router-dom';
import CrafterLogo from '../../../icons/CrafterLogo';

export default function VerifyOTP({ enteredEmail }) {
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const inputRefs = useRef([]);
  const [isVerifying, setIsVerifying] = useState(false);
  const [timer, setTimer] = useState(59);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [buttonSize, setButtonSize] = useState('md');
  const authStore = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  const formatTimer = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleVerifyOTP = async () => {
    const otpCode = otp.join('');
    setIsVerifying(true);
    try {
      const loginResponse = await WebAppService.verifyAndLogin({
        email: enteredEmail,
        purpose: 'authorization',
        otp: otpCode,
      });

      const userData = loginResponse.data;
      // console.log(userData);
      authStore.updateUser({
        email: userData?.email,
        role: userData?.role,
        createdAt: userData?.created_at,
        updatedAt: userData?.updated_at,
        id: userData?.id,
        organization: userData?.organization,
        isNew: userData?.isNew,
      });
      authStore.updateToken(userData.access_token);

      const authToken = loginResponse.data.access_token;

      sessionStorage.setItem('authToken', authToken);

      authStore.authenticate();
      navigate('/');

      await WebAppService.getOrganizationById(userData.organization)
        .then((response) => {
          console.log(response);
          const organizationResponse = response;

          // console.log(organizationResponse);

          authStore.updateOrganization({
            hasSetJiraConfig: organizationResponse?.has_set_jira_api_key,
            hasSetOpenAIConfig: organizationResponse?.has_set_openai_api_key,
            domain: organizationResponse?.domain,
            name: organizationResponse?.name,
            updatedAt: organizationResponse?.updatedAt,
            jira_url: organizationResponse?.jira_url,
            jira_admin_email: organizationResponse?.jira_admin_email,
            id: organizationResponse?.id,
          });
        })
        .catch((error) => {
          console.error('Error fetching organisation data:', error);
        });
    } catch (error) {
      setShowErrorMessage(true);
      setErrorMessage(error.response.data.error);
      setTimeout(() => {
        setShowErrorMessage(false);
        setOtp(new Array(6).fill(''));
      }, 3000);

      console.error('OTP verification failed:', error);
      setIsVerifying(false);
    }
  };

  const handleResendOTP = async (event) => {
    event.preventDefault();
    setTimer(59);

    try {
      const response = await WebAppService.getOTP({
        email: enteredEmail,
        purpose: 'authorization',
        // otp: otpCode,
      });
    } catch (error) {
      console.log(error);
      // throw error;
    }
    setIsVerifying(false);
  };

  const handleChange = (e, index) => {
    const { value } = e.target;
    const updatedOtp = [...otp];

    if (!isNaN(value) && value !== '') {
      updatedOtp[index] = value;
      setOtp(updatedOtp);

      // Move focus to the next input if it exists
      if (index < otp.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    } else if (value === '') {
      updatedOtp[index] = '';
      setOtp(updatedOtp);

      // Move focus to the previous input if it exists
      if (index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault(); // Prevent default paste behavior

    const value = e.clipboardData.getData('text');
    const otpDigits = value.match(/\d/g); // Extract only digits from pasted value

    if (otpDigits !== null) {
      // Update OTP with pasted digits
      const updatedOtp = otpDigits.map((digit) => parseInt(digit));
      setOtp(updatedOtp);
    }
  };

  useEffect(() => {
    // Determine the screen size and set button size accordingly
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setButtonSize('sm');
      } else if (window.innerWidth > 1024) {
        setButtonSize('lg');
      } else {
        setButtonSize('md');
      }
    };

    // Initial call to set the button size
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const isOtpComplete = otp.every((digit) => digit !== '');
  const formattedTimer = formatTimer(timer);

  return (
    <div className="flex flex-col gap-8 md:gap-8 pt-[60px] md:pt-0  md:m-auto items-center justify-center w-full">
      <div className="w-full flex flex-col gap-6">
        <div className="w-full flex flex-col items-center gap-6">
          <Link
            className="w-[120px] md:w-[120px] lg:w-[180px]"
            to={'https://usecrafter.com'}
            target="_blank"
          >
            <CrafterLogo />
          </Link>
        </div>
        <div className="flex m-auto h-fit px-5 md:px-10 lg:px-10 py-16 md:py-10 lg:py-10 w-[95%] md:w-[70%] lg:w-[70%] xl:w-[60%] xl:max-w-[550px] authBorder">
          <div className="w-full flex flex-col items-center justify-center m-auto gap-6">
            <div className="w-full md:w-4/5 lg:w-4/5 text-center flex flex-col gap-3">
              <h1 className="text-center text-white-1 font-bold text-[18px] md:text-[20px] lg:text-[24px] tracking-tight">
                OTP Verification
              </h1>
              <p className="text-[12px] md:text-[14px] lg:text-[14px]">
                Kindly enter the OTP code sent to your email address to complete
                your verification.
              </p>
            </div>
            <div className="w-full mt-1 flex gap-2 md:gap-4 lg:gap-4 items-center justify-center px-2">
              {otp.map((digit, index) => (
                <div
                  key={index}
                  className="w-[35px] h-[40px] md:w-[55px] md:h-[60px] lg:w-[55px] lg:h-[60px] otpBg"
                >
                  <input
                    ref={(el) => (inputRefs.current[index] = el)}
                    className="text-center text-[24px] font-semibold focus:outline-none w-full h-full bg-transparent"
                    type="text"
                    value={digit}
                    maxLength={1}
                    onChange={(e) => handleChange(e, index)}
                    onPaste={handlePaste}
                  />
                </div>
              ))}
            </div>
            {formattedTimer !== '0:00' && (
              <span className="text-gray-6 text-[12px] md:text-[14px] lg:text-[16px]">
                Request another code in:{' '}
                <span className="text-blue-2 font-semibold">
                  {formattedTimer}
                </span>{' '}
                secs
              </span>
            )}

            {showErrorMessage && (
              <p className="text-error-1 font-semibold">{errorMessage}</p>
            )}
            {timer === 0 && (
              <div className="w-full md:w-4/5 lg:w-4/5 flex flex-col gap-3 text-center">
                <p className="text-[12px] md:text-[14px] lg:text-[14px]">
                  Didn’t receive an email?
                </p>
                <p className="text-[12px] md:text-[14px] lg:text-[14px]">
                  Check your spam folder or{' '}
                  <span
                    onClick={handleResendOTP}
                    className="cursor-pointer text-blue-2 underline underline-offset-2"
                  >
                    Click to resend
                  </span>
                </p>
              </div>
            )}
            <div className="w-4/5 flex mx-auto">
              <GlobalButton
                onClick={handleVerifyOTP}
                variant="secondary"
                size={buttonSize}
                state={isVerifying || !isOtpComplete ? 'inactive' : 'default'}
              >
                {isVerifying ? 'Verifying...' : 'Verify'}
              </GlobalButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
