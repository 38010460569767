import React from 'react';

const Table = ({ columns, rows }) => {
  return (
    <table className="min-w-full divide-y divide-gray-2">
      <thead className="table-head-bg ">
        <tr>
          {columns.map((column, index) => (
            <th
              key={index}
              scope="col"
              className="px-6 py-4 text-left text-sm font-semibold text-white-2  "
            >
              {column}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-2 table-head-bg">
        {rows.map((row, rowIndex) => (
          <tr className="table-row" key={rowIndex}>
            {columns.map((column, colIndex) => (
              <td
                key={colIndex}
                className="px-6 py-3 whitespace-nowrap text-sm text-gray-900"
              >
                {row[column.toLowerCase()]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
