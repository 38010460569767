import React from 'react';

export default function InfoCircle() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M10.0001 18.3332C14.6025 18.3332 18.3334 14.6022 18.3334 9.99984C18.3334 5.39746 14.6025 1.6665 10.0001 1.6665C5.39771 1.6665 1.66675 5.39746 1.66675 9.99984C1.66675 14.6022 5.39771 18.3332 10.0001 18.3332Z"
        fill="#FF9595"
      />
      <path
        d="M10 11.4582C10.3417 11.4582 10.625 11.1748 10.625 10.8332V6.6665C10.625 6.32484 10.3417 6.0415 10 6.0415C9.65833 6.0415 9.375 6.32484 9.375 6.6665V10.8332C9.375 11.1748 9.65833 11.4582 10 11.4582Z"
        fill="#FF9595"
      />
      <path
        d="M10.7667 13.0169C10.7251 12.9169 10.6667 12.8252 10.5917 12.7419C10.5084 12.6669 10.4167 12.6086 10.3167 12.5669C10.1167 12.4836 9.88341 12.4836 9.68341 12.5669C9.58341 12.6086 9.49175 12.6669 9.40841 12.7419C9.33341 12.8252 9.27508 12.9169 9.23341 13.0169C9.19175 13.1169 9.16675 13.2252 9.16675 13.3336C9.16675 13.4419 9.19175 13.5502 9.23341 13.6502C9.27508 13.7586 9.33341 13.8419 9.40841 13.9252C9.49175 14.0002 9.58341 14.0586 9.68341 14.1002C9.78341 14.1419 9.89175 14.1669 10.0001 14.1669C10.1084 14.1669 10.2167 14.1419 10.3167 14.1002C10.4167 14.0586 10.5084 14.0002 10.5917 13.9252C10.6667 13.8419 10.7251 13.7586 10.7667 13.6502C10.8084 13.5502 10.8334 13.4419 10.8334 13.3336C10.8334 13.2252 10.8084 13.1169 10.7667 13.0169Z"
        fill="#FF9595"
      />
    </svg>
  );
}
