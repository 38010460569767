import React, { useState } from 'react';
import GlobalButton from '../../components/ui/GlobalButton';
import useAuthStore from '@/store/Auth';
import { useNavigate } from 'react-router-dom';

export default function LogoutModal({ onClose }) {
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const navigate = useNavigate();

  const { logout } = useAuthStore();

  const handleLogOut = (e) => {
    e.stopPropagation();
    logout();
    window.sessionStorage.removeItem('auth-store');
    window.sessionStorage.removeItem('insights');
    window.sessionStorage.removeItem('authToken');
    window.localStorage.removeItem('authToken');
    window.localStorage.removeItem('token');

    navigate('/login', {
      replace: true,
    });
    window.location.href = '/login';
  };
  return (
    <div className="modal">
      <div
        onClick={onClose}
        className="modal-overlay absolute inset-0 bg-black-3 opacity-95"
      ></div>
      <div className="modalBg px-8 py-10 w-[90%] md:w-[90%] lg:w-[40%] max-h-[90%] h-fit top-1/2 left-1/2 absolute border-0.5 border-gray-2/50 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-7 items-center rounded-lg shadow-md text-white-1">
        <div className="flex flex-col gap-1 text-center">
          <h1 className="font-bold text-xl">Want to logout?</h1>

          <p className="text-gray-4 text-sm">
            Logging out? No problem! Your account and preferences will be
            waiting for you when you return.
          </p>
        </div>

        <hr className="border-0.5 border-black-1 w-full" />

        <div className="flex gap-5 w-full">
          <div className="w-full">
            <GlobalButton
              onClick={handleLogOut}
              variant={`redStroke`}
              size={`lg`}
              state={isLoggingOut ? 'inactive' : 'default'}
            >
              {isLoggingOut ? 'Logging out...' : `Logout`}
            </GlobalButton>
          </div>
          <div className="w-full border-error-1">
            <GlobalButton
              onClick={(event) => {
                event.stopPropagation();
                onClose();
              }}
              variant={'secondaryRed'}
              size={'lg'}
              state={'default'}
            >
              Cancel
            </GlobalButton>
          </div>
        </div>
      </div>
    </div>
  );
}
