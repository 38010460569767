import React from 'react';

export default function Step1() {
  const step1List = [
    'Dive deep into your project progress and stay on track.',
    'Create, track, and complete tasks seamlessly.',
    'Work with your team, and achieve goals faster.',
  ];
  return (
    <div className="flex flex-col items-start px-2">
      <div className="flex justify-center w-full">
        <h4 className="font-bold text-[32px] text-white-1 border-b border-gray-4/40 w-full pb-4">
          Welcome to Crafter Insights! 🎊
        </h4>
      </div>

      <div className="mt-6 flex flex-col gap-4">
        <p className="text-[20px] font-semibold text-left  text-white-1">
          Your productivity partner is here to help you craft success!
        </p>
        <p className=" text-[16px] text-white-1 text-left">
          💡 Here’s what you can do:
        </p>

        <div className="flex flex-col items-start gap-2">
          {step1List.map((item, index) => (
            <li key={index} className="list-outside text-white-3">
              {item}
            </li>
          ))}
        </div>
      </div>
    </div>
  );
}
