import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

const initialState = {
  user: {
    email: '',
    firstName: '',
    lastName: '',
    role: '',
    occupation: '',
    companySize: '',
    createdAt: '',
    updatedAt: '',
    fullName: '',
    id: '',
    organization: '',
    hasSetJiraConfig: false,
    hasSetOpenAIConfig: false,
    isNew: true,
  },
  organization: {
    hasSetJiraConfig: false,
    hasSetOpenAIConfig: false,
    domain: '',
    name: '',
    updatedAt: '',
    id: '',
    jira_admin_email: '',
    jira_url: '',
  },
  token: '',
  isAuthenticated: false,
};

const useAuthStore = create(
  persist(
    (set) => ({
      ...initialState,
      updateUser: (data) =>
        set({
          user: { ...data },
        }),
      updateOrganization: (data) =>
        set({
          organization: {
            ...data,
          },
        }),
      updateToken: (token) => set({ token }),
      authenticate: () =>
        set({
          isAuthenticated: true,
        }),
      logout: () =>
        set({
          ...initialState,
          isAuthenticated: false,
        }),
    }),
    {
      name: 'auth-store',
      getStorage: () => createJSONStorage(() => sessionStorage),
    }
  )
);

export default useAuthStore;
