import React from 'react';
// import { Link } from "react-router-dom";
import Terms from '../Terms';
import close from '../../../src/icons/iconClose.png';
export default function PrivacyModal({ onClose }) {
  const informationWeCollectList = [
    'Account Information: When you create an account, we collect your name, email address, and password.',
    'Usage Data: We collect information about how you use the Service, such as the documents you upload, the tickets you create, and the features you access.',
    `Log Data: When you access the Service, our servers automatically record information. Log Data may include your IP address, browser type, operating system, referral URL, access times, and other information about your interaction with the Service.`,
    `Cookies and Similar Technologies: We may use cookies and similar technologies to track the activity on our Service and hold certain information.`,
  ];
  const howWeUseYourInformationList = [
    `To provide and improve the Service: We use your information to operate the Service, deliver content, analyze usage patterns, personalize your experience, and develop new features.`,
    `To communicate with you: We may use your information to send you emails about your account, the Service, and other announcements.`,
    `To comply with law: We may disclose your information to comply with legal obligations, respond to court orders, or investigate potential or actual wrongdoing.`,
    `Cookies and Similar Technologies: We may use cookies and similar technologies to track the activity on our Service and hold certain information.`,
  ];
  return (
    <div className="modal">
      <div onClick={onClose} className="modal-overlay"></div>
      <div className="px-[30px] lg:px-[50px] md:px-[50px] py-5 text-gray-6 text-base flex flex-col w-[95%] lg:w-full h-[90%]  z-20 bg3 border border-gray-4/30 rounded-xl">
        <div className="flex items-center justify-between w-full">
          <h1 className="text-2xl py-3 font-semibold text-white-1 ">
            Privacy policy
          </h1>
          <button onClick={onClose}>
            <img src={close} alt="close" className="w-[13px] h-[13px]" />
          </button>
        </div>
        <div className="mt-6 overflow-scroll mb-[20px] flex flex-col gap-6">
          <div className="flex flex-col gap-3">
            <p className="text-[14px] md:text-[16px] lg:text-[16px]">
              Crafter is committed to protecting the privacy of our user. This
              Privacy Policy explains how we collect, use, disclose, and protect
              your information when you use our platform, the Crafter Service.
            </p>
          </div>

          <Terms
            title={'Information We Collect'}
            message={`We collect several types of information to provide and improve the Service:`}
            list={informationWeCollectList}
          />
          <Terms
            title={'How We Use Your Information'}
            message={`We use your information for the following purposes:`}
            list={howWeUseYourInformationList}
          />
          <Terms
            title={'Sharing Your Information'}
            message={`We may share your information with third-party service providers who help us operate the Service. These service providers are contractually obligated to keep your information confidential and secure. We will not share your information with any third-party for marketing purposes without your consent.`}
          />
          <Terms
            title={'Data Retention'}
            message={`We will retain your information for as long as your account is active or as needed to provide you with the Service. We may also retain your information for a longer period as required by law or to comply with regulatory requirements.`}
          />
        </div>
      </div>
    </div>
  );
}
