import DropdownArrow from '@/icons/DropdownArrow';
import React, { useState } from 'react';

export default function GlobalDropdown2({
  label,
  isRequired,
  options,
  placeholder,
  onChange,
  value,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenDropdown = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleSelectOption = (option) => {
    setIsOpen(false);
    onChange(option);
  };

  return (
    <div className="w-full flex flex-col items-start gap-2">
      <label className="text-sm text-white-1 font-semibold">
        {label}
        {isRequired && <span className="text-sm text-error-1">*</span>}
      </label>
      <div className="w-full">
        <button
          onClick={handleOpenDropdown}
          className={`flex w-full items-center justify-between border-1 border-gray-3/70 ${
            value ? 'text-white-2' : 'text-gray-3'
          } text-gray-2 bg-transparent rounded-md py-[10px] px-[14px] border-1 border-black-1 focus:outline-none`}
        >
          {value || placeholder} <DropdownArrow />
        </button>

        {isOpen && (
          <ul className="w-full mt-2 max-h-[150px] overflow-y-scroll flex flex-col items-start text-gray-4">
            {options.map((option, index) => (
              <li
                key={index}
                onClick={() => handleSelectOption(option)} // Handle option selection
                className="w-full text-left py-3 px-4 border-b-0.5 border-black-1 hover:text-white-2 cursor-pointer"
              >
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}
