import React from 'react';

export default function ProjectIcon({ active }) {
  return (
    <>
      {active ? (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.0835 8.4915H14.6752C12.7002 8.4915 11.0918 6.88317 11.0918 4.90817V2.49984C11.0918 2.0415 10.7168 1.6665 10.2585 1.6665H6.72516C4.1585 1.6665 2.0835 3.33317 2.0835 6.30817V13.6915C2.0835 16.6665 4.1585 18.3332 6.72516 18.3332H13.2752C15.8418 18.3332 17.9168 16.6665 17.9168 13.6915V9.32484C17.9168 8.8665 17.5418 8.4915 17.0835 8.4915Z"
            fill="#939393"
          />
          <path
            d="M13.1662 1.84158C12.8246 1.49991 12.2329 1.73325 12.2329 2.20825V5.11658C12.2329 6.33325 13.2662 7.34158 14.5246 7.34158C15.3162 7.34991 16.4162 7.34991 17.3579 7.34991C17.8329 7.34991 18.0829 6.79158 17.7496 6.45825C16.5496 5.24991 14.3996 3.07491 13.1662 1.84158Z"
            fill="#F58E30"
          />
          <path
            d="M11.25 11.4585H6.25C5.90833 11.4585 5.625 11.1752 5.625 10.8335C5.625 10.4918 5.90833 10.2085 6.25 10.2085H11.25C11.5917 10.2085 11.875 10.4918 11.875 10.8335C11.875 11.1752 11.5917 11.4585 11.25 11.4585Z"
            fill="#0182AB"
          />
          <path
            d="M9.58333 14.7915H6.25C5.90833 14.7915 5.625 14.5082 5.625 14.1665C5.625 13.8248 5.90833 13.5415 6.25 13.5415H9.58333C9.925 13.5415 10.2083 13.8248 10.2083 14.1665C10.2083 14.5082 9.925 14.7915 9.58333 14.7915Z"
            fill="#B1CB96"
          />
        </svg>
      ) : (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.3334 8.33317V12.4998C18.3334 16.6665 16.6667 18.3332 12.5001 18.3332H7.50008C3.33341 18.3332 1.66675 16.6665 1.66675 12.4998V7.49984C1.66675 3.33317 3.33341 1.6665 7.50008 1.6665H11.6667"
            stroke="#A6A6A6"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M18.3334 8.33317H15.0001C12.5001 8.33317 11.6667 7.49984 11.6667 4.99984V1.6665L18.3334 8.33317Z"
            stroke="#A6A6A6"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5.83325 10.8335H10.8333"
            stroke="#D4D4D4"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5.83325 14.1665H9.16659"
            stroke="#737373"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      )}
    </>
  );
}
