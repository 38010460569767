import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import useAuthStore from '@/store/Auth';
import AccessRequest from './AccessRequest';
import AlreadyConfigured from './AlreadyConfigured';
import JiraIntegrationForm from './JiraIntegrationForm';
import { useNavigate } from 'react-router-dom';
import useActionStore from '@/store/Actions';
import IntegrationFailed from '@/components/modals/StatusModals/IntegrationFailed/IntegrationFailed';
import IntegrationSuccess from '@/components/modals/StatusModals/IntegrationSuccess/IntegrationSuccess';

export default function Jira() {
  const [collapsed, setCollapsed] = useState(false);

  const { jiraActiveComponent, setJiraActiveComponent } = useActionStore();
  const navigate = useNavigate();
  const authStore = useAuthStore();

  const hasSetJiraConfig = authStore.organization.hasSetJiraConfig;

  const handleRequestGranted = () => {
    setJiraActiveComponent('JiraIntegrationForm');
  };

  const handleCancel = () => {
    navigate('/integrations');
  };

  useEffect(() => {
    if (hasSetJiraConfig) {
      setJiraActiveComponent('AlreadyConfigured');
    } else {
      setJiraActiveComponent('AccessRequest');
    }
  }, [hasSetJiraConfig]);

  return (
    <main className="relative innerFrameBg text-gray-4 h-screen overflow-hidden w-full flex flex-col">
      <Helmet>
        <title>Jira | Crafter</title>
      </Helmet>
      <div className="flex w-full h-full">
        <div
          className={`w-full ${
            collapsed ? 'w-[95%]' : 'w-[80%]'
          } overflow-scroll h-screen`}
        >
          {jiraActiveComponent === 'AccessRequest' && (
            <AccessRequest
              onCancel={handleCancel}
              onContinue={handleRequestGranted}
            />
          )}
          {jiraActiveComponent === 'AlreadyConfigured' && (
            <AlreadyConfigured onCancel={handleCancel} />
          )}
          {jiraActiveComponent === 'JiraIntegrationForm' && (
            <JiraIntegrationForm onCancel={handleCancel} />
          )}
        </div>
      </div>

      <IntegrationSuccess />
      <IntegrationFailed />
    </main>
  );
}
