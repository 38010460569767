import React from 'react';

export default function HomeIcon({ active }) {
  return (
    <>
      {active ? (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.5 13.0415H12.5C12.4699 13.0415 12.4386 13.0292 12.4129 13.0036C12.3873 12.9779 12.375 12.9467 12.375 12.9165C12.375 12.8864 12.3873 12.8551 12.4129 12.8294C12.4386 12.8038 12.4699 12.7915 12.5 12.7915H17.5C17.5301 12.7915 17.5614 12.8038 17.5871 12.8294C17.6127 12.8551 17.625 12.8864 17.625 12.9165C17.625 12.9467 17.6127 12.9779 17.5871 13.0036C17.5614 13.0292 17.5301 13.0415 17.5 13.0415Z"
            fill="#D4D4D4"
            stroke="#333333"
          />
          <path
            d="M17.5 16.375H12.5C12.4699 16.375 12.4386 16.3627 12.4129 16.3371C12.3873 16.3114 12.375 16.2801 12.375 16.25C12.375 16.2199 12.3873 16.1886 12.4129 16.1629C12.4386 16.1373 12.4699 16.125 12.5 16.125H17.5C17.5301 16.125 17.5614 16.1373 17.5871 16.1629C17.6127 16.1886 17.625 16.2199 17.625 16.25C17.625 16.2801 17.6127 16.3114 17.5871 16.3371C17.5614 16.3627 17.5301 16.375 17.5 16.375Z"
            fill="#F58E30"
            stroke="#F58E30"
          />
          <path
            opacity="0.4"
            d="M16.4781 8.24151V8.2415H16.475H13.1083C12.4831 8.2415 12.1731 8.12694 12.0137 7.98583C11.8699 7.85863 11.75 7.61986 11.75 7.0915V3.3165C11.75 2.79336 11.8695 2.55343 12.0144 2.42466C12.1744 2.28248 12.4849 2.1665 13.1083 2.1665H16.475C17.0985 2.1665 17.4089 2.28248 17.5689 2.42466C17.7139 2.55343 17.8333 2.79336 17.8333 3.3165V7.09984C17.8333 7.62336 17.7137 7.86252 17.5697 7.99C17.4107 8.13065 17.1013 8.24543 16.4781 8.24151Z"
            fill="#0182AB"
            stroke="#0182AB"
          />
          <path
            d="M8.75008 7.09984V3.3165C8.75008 2.1415 8.21675 1.6665 6.89175 1.6665H3.52508C2.20008 1.6665 1.66675 2.1415 1.66675 3.3165V7.0915C1.66675 8.27484 2.20008 8.7415 3.52508 8.7415H6.89175C8.21675 8.74984 8.75008 8.27484 8.75008 7.09984Z"
            fill="#86A16B"
          />
          <path
            opacity="0.4"
            d="M8.75008 16.475V13.1083C8.75008 11.7833 8.21675 11.25 6.89175 11.25H3.52508C2.20008 11.25 1.66675 11.7833 1.66675 13.1083V16.475C1.66675 17.8 2.20008 18.3333 3.52508 18.3333H6.89175C8.21675 18.3333 8.75008 17.8 8.75008 16.475Z"
            fill="#F58E30"
          />
        </svg>
      ) : (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.3333 6.8915V3.52484C18.3333 2.19984 17.8 1.6665 16.475 1.6665H13.1083C11.7833 1.6665 11.25 2.19984 11.25 3.52484V6.8915C11.25 8.2165 11.7833 8.74984 13.1083 8.74984H16.475C17.8 8.74984 18.3333 8.2165 18.3333 6.8915Z"
            stroke="#A6A6A6"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.74984 7.09984V3.3165C8.74984 2.1415 8.2165 1.6665 6.8915 1.6665H3.52484C2.19984 1.6665 1.6665 2.1415 1.6665 3.3165V7.0915C1.6665 8.27484 2.19984 8.7415 3.52484 8.7415H6.8915C8.2165 8.74984 8.74984 8.27484 8.74984 7.09984Z"
            stroke="#A6A6A6"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.74984 16.475V13.1083C8.74984 11.7833 8.2165 11.25 6.8915 11.25H3.52484C2.19984 11.25 1.6665 11.7833 1.6665 13.1083V16.475C1.6665 17.8 2.19984 18.3333 3.52484 18.3333H6.8915C8.2165 18.3333 8.74984 17.8 8.74984 16.475Z"
            stroke="#D4D4D4"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.5 12.9165H17.5"
            stroke="#737373"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <path
            d="M12.5 16.25H17.5"
            stroke="#333333"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg>
      )}
    </>
  );
}
