import React, { useState } from 'react';
import Card from './Card';

import SlackIcon from '@/icons/SlackIcon';
import GithubIcon from '@/icons/GithubIcon';
import DashboardContainer from '@/components/ui/DashboardContainer';
import DashboardWrapper from '@/components/ui/DashboardWrapper';
import useAuthStore from '@/store/Auth';
import { useNavigate } from 'react-router-dom';
import { OpenAILogo, JiraIcon2 } from '../../svgs';
import useIntegrationsStore from '../../store/Integrations';

export default function Body({ collapsed }) {
  const authStore = useAuthStore();
  const { updateSelectedIntegration } = useIntegrationsStore();

  const navigate = useNavigate();

  const handleIntegrationClick = (integration) => {
    updateSelectedIntegration(integration);
    navigate(`/integrations/${integration}`);
  };

  return (
    <div
      className={`w-full h-screen pt-[65px] pb-[45px] px-4 text-sm text-white-1 overflow-hidden `}
    >
      <div className="flex flex-col gap-4 w-full h-full px-8 pt-8 pb-3 mt-8 ">
        <div className="bg1 rounded-xl overflow-hidden bg1 py-8 px-8 h-full">
          <div className="flex flex-col items-start gap-1 justify-between">
            <h1 className="font-semibold text-[24px] tracking-tight">
              Integrations
            </h1>
            <p className="text-[16px] text-gray-4 mt-1">
              Integrate Crafter with your favorite productivity tools to better
              manage your projects/teams
            </p>
          </div>
          <div className="mt-8 w-full lg:w-[90%] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-5 h-fit">
            <Card
              isIntegrated={authStore.organization.hasSetJiraConfig}
              collapsed={collapsed}
              onClick={() => handleIntegrationClick('Jira')}
              component={<JiraIcon2 />}
              title={'Jira'}
              deletable={false}
              description={
                'A project management tool developed by Atlassian for software development teams.'
              }
              isInactive={false}
            />
            <Card
              isIntegrated={authStore.organization.hasSetOpenAIConfig}
              component={<OpenAILogo />}
              onClick={() => handleIntegrationClick('OpenAI')}
              title={'OpenAI'}
              description={
                'OpenAI builds innovative power tools like ChatGPT and prioritize responsible AI use.'
              }
              available={true}
              deletable
              isInactive={false}
            />
            {/* <Card
                component={<SlackIcon />}
                title={'Slack'}
                description={
                  'A platform designed to improve team communication and collaboration.'
                }
                available={false}
                isInactive={true}
              />
              <Card
                component={<GithubIcon />}
                title={'Github'}
                description={
                  'A version control system, which allows developers to track changes in code over time.'
                }
                available={false}
                isInactive={true}
              /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
