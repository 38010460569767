import React, { useState } from 'react';
import GlobalButton from '@/components/ui/GlobalButton';

const liteDetails = [
  'Chat with Crafter AI',
  'Unlimited task generation from tech specs and PRDs',
  'Access to all supported document formats.',
  'Unlimited task edits and saves',
  'Access to Jira project insights and summary reports',
  'Unlimited CSV download',
];

export default function LiteCard({
  onContinue,
  litePlan,
  activePeriod,
  onPlanSelect,
  buttonState,
}) {
  // const [buttonState, setButtonState] = useState('default');

  // const litePlanFeatures = litePlan?.feature;

  // const capitalizeFirstLetter = (string) => {
  //   if (!string) return '';
  //   return string.charAt(0).toUpperCase() + string.slice(1);
  // };
  // const decapitalizeFirstLetter = (string) => {
  //   if (!string) return '';
  //   return string.charAt(0).toLowerCase() + string.slice(1);
  // };

  // Define pricing based on activePeriod
  const pricing =
    activePeriod === 'Monthly'
      ? `$${litePlan?.prices?.monthly[0]?.amount}`
      : `$${litePlan?.prices?.yearly[0]?.amount}`;

  const handleButtonClick = () => {
    // setButtonState('inactive');
    onPlanSelect(litePlan?.id);
  };

  return (
    <div className="flex flex-col gap-5 h-[600px] md:h-full lg:h-full pt-5 pb-10 px-5 w-full pricingCardBg rounded-3xl">
      <div className="flex w-full items-left flex-col justify-between">
        <p className="text-base font-normal text-blue-3">
          {litePlan?.name || `Crafter Lite`}
        </p>
        <h1 className="text-white-1 font-semibold text-[32px]">
          {pricing}
          <span className={`text-base text-gray-4`}>/Month</span>
        </h1>
      </div>

      <p className="text-gray-6 font-normal text-[16px]">
        Work with your team faster and better by creating tickets easily
      </p>

      <div className="flex flex-col gap-8">
        <div>
          <GlobalButton
            onClick={handleButtonClick}
            variant={'blue'}
            size={'md'}
            state={buttonState}
          >
            Subscribe
          </GlobalButton>
        </div>

        <div className={`flex items-center gap-4`}>
          <div className={`w-full flex items-center`}>
            <div className={`w-1 h-1 bg-gray-6/30 rounded-full`}></div>
            <hr className={`border-0.5 border-gray-6/30 w-full`} />
          </div>
          <span className={`text-gray-6 text-sm`}>Features</span>
          <div className={`w-full flex items-center`}>
            <hr className={`border-0.5 border-gray-6/30 w-full`} />
            <div className={`w-1 h-1 bg-gray-6/30 rounded-full`}></div>
          </div>
        </div>

        {/* <ul className="w-full text-base flex flex-col gap-4 list-disc list-outside pl-5 text-white-2">
          <li>{`${capitalizeFirstLetter(
            litePlanFeatures?.upload
          )} uploads + ticket generation (can upload up to 10mb document)`}</li>
          <li>{`Access to ${decapitalizeFirstLetter(
            litePlanFeatures?.documentFormats
          )} supported document formats.`}</li>
          <li>{`${capitalizeFirstLetter(
            litePlanFeatures?.editsSave
          )} edits + save`}</li>
          <li>{`${capitalizeFirstLetter(
            litePlanFeatures?.csvDownloads
          )} CSV downloads`}</li>
          <li>{`${capitalizeFirstLetter(
            litePlanFeatures?.uploadToJira
          )} exports to Jira`}</li>
        </ul> */}

        <div className="w-full text-sm flex flex-col gap-5 text-gray-4">
          {liteDetails.map((liteDetail, index) => (
            <span className={`flex items-start gap-2`} key={index}>
              <span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                    stroke="#737373"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.75 12L10.58 14.83L16.25 9.16998"
                    stroke="#737373"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              {liteDetail}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}
