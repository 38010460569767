export function monitorSessionStorageSpace(
  thresholdPercentage,
  keyToTarget,
  pruneLogic
) {
  const getTotalSessionStorageSize = () => {
    let totalSize = 0;
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      if (key) {
        const value = sessionStorage.getItem(key);
        totalSize += key.length + (value ? value.length : 0);
      }
    }
    return totalSize;
  };

  const getRemainingStorageSpace = (originalSpace) => {
    return originalSpace - getTotalSessionStorageSize();
  };

  const pruneStorageKey = () => {
    const storedValue = sessionStorage.getItem(keyToTarget);
    if (!storedValue) {
      console.warn(`Key "${keyToTarget}" not found in sessionStorage.`);
      return;
    }

    try {
      const parsedData = JSON.parse(storedValue);

      // Apply pruning logic
      const prunedData = pruneLogic(parsedData);

      // Save the pruned data back to sessionStorage
      sessionStorage.setItem(keyToTarget, JSON.stringify(prunedData));
      // console.log(`Pruned "${keyToTarget}" in sessionStorage.`);
    } catch (error) {
      console.error(
        `Failed to parse or prune data in "${keyToTarget}".`,
        error
      );
    }
  };

  const manageStorageSpace = () => {
    const originalSpace = 5 * 1024 * 1024; // Default sessionStorage space in most browsers is ~5MB.
    const remainingSpace = getRemainingStorageSpace(originalSpace) / 1024; // Convert to KB
    const totalSpace = originalSpace / 1024; // Convert to KB
    const thresholdSpace = (originalSpace * thresholdPercentage) / (100 * 1024); // Convert to KB

    // console.log(
    //   `Remaining space is ${remainingSpace.toFixed(2)} KB, out of ${totalSpace.toFixed(2)} KB`
    // );
    // console.log(`Threshold space: ${thresholdSpace.toFixed(2)} KB`);

    if (remainingSpace < thresholdSpace) {
      console.warn(
        `Remaining space is below ${thresholdPercentage}%. Pruning "${keyToTarget}" in sessionStorage.`
      );
      pruneStorageKey();
    }
  };

  // Return the monitoring function for manual or interval-based usage
  return manageStorageSpace;
}
