import React from 'react';
import CustomModalOverlay from '../../common/Custom/CustomModalOverlay/CustomModalOverlay';
import GlobalButton from '../../ui/GlobalButton';
import useActionStore from '../../../store/Actions';

export default function TemporaryChatModal() {
  const temporaryList = [
    {
      icon: PromptIcon,
      title: 'No history',
      desc: 'There will be no chat history as each chat log will be cleared when a new insight for a project is generated',
    },
    {
      icon: PromptIcon,
      title: 'Limited prompt history',
      desc: 'We will only save a limited number of prompt history cor the duration of the chat session',
    },
    {
      icon: PromptIcon,
      title: 'Best results',
      desc: 'For best results, keep your chats with Crafter focused on your projects',
    },
  ];

  const { toggleTemporaryChat, temporaryChat } = useActionStore();
  return (
    <CustomModalOverlay
      title={'This is a temporary chat'}
      open={temporaryChat}
      onClose={() => {
        sessionStorage.setItem('hasViewedTemporaryChatModal', 'true');
        toggleTemporaryChat(false);
      }}
      width="700px"
      withCloseButton={false}
    >
      <div className="mt-10 flex flex-col gap-8">
        <div className="flex flex-col gap-8">
          {temporaryList.map((item, index) => (
            <div className="flex items-start gap-4" key={index}>
              <div>
                <item.icon />
              </div>
              <div className="flex flex-col gap-1 items-start">
                <h3 className="text-xl font-bold text-white-1">{item.title}</h3>
                <p className="text-white-3">{item.desc}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="w-56 flex ml-auto">
          <GlobalButton
            onClick={() => {
              sessionStorage.setItem('hasViewedTemporaryChatModal', 'true');
              toggleTemporaryChat(false);
            }}
            size={'lg'}
            state={'default'}
            variant={'blue'}
          >
            Continue
          </GlobalButton>
        </div>
      </div>
    </CustomModalOverlay>
  );
}

const PromptIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.3697 4.89L13.5097 2.28C12.6497 1.9 11.3497 1.9 10.4897 2.28L4.62969 4.89C3.14969 5.55 2.92969 6.45 2.92969 6.93C2.92969 7.41 3.14969 8.31 4.62969 8.97L10.4897 11.58C10.9197 11.77 11.4597 11.87 11.9997 11.87C12.5397 11.87 13.0797 11.77 13.5097 11.58L19.3697 8.97C20.8497 8.31 21.0697 7.41 21.0697 6.93C21.0697 6.45 20.8597 5.55 19.3697 4.89Z"
        fill="#F0EEEE"
      />
      <path
        d="M12.0003 17.04C11.6203 17.04 11.2403 16.96 10.8903 16.81L4.15031 13.81C3.12031 13.35 2.32031 12.12 2.32031 10.99C2.32031 10.58 2.65031 10.25 3.06031 10.25C3.47031 10.25 3.80031 10.58 3.80031 10.99C3.80031 11.53 4.25031 12.23 4.75031 12.45L11.4903 15.45C11.8103 15.59 12.1803 15.59 12.5003 15.45L19.2403 12.45C19.7403 12.23 20.1903 11.54 20.1903 10.99C20.1903 10.58 20.5203 10.25 20.9303 10.25C21.3403 10.25 21.6703 10.58 21.6703 10.99C21.6703 12.11 20.8703 13.35 19.8403 13.81L13.1003 16.81C12.7603 16.96 12.3803 17.04 12.0003 17.04Z"
        fill="#F0EEEE"
      />
      <path
        d="M12.0003 22C11.6203 22 11.2403 21.92 10.8903 21.77L4.15031 18.77C3.04031 18.28 2.32031 17.17 2.32031 15.95C2.32031 15.54 2.65031 15.21 3.06031 15.21C3.47031 15.21 3.80031 15.54 3.80031 15.95C3.80031 16.58 4.17031 17.15 4.75031 17.41L11.4903 20.41C11.8103 20.55 12.1803 20.55 12.5003 20.41L19.2403 17.41C19.8103 17.16 20.1903 16.58 20.1903 15.95C20.1903 15.54 20.5203 15.21 20.9303 15.21C21.3403 15.21 21.6703 15.54 21.6703 15.95C21.6703 17.17 20.9503 18.27 19.8403 18.77L13.1003 21.77C12.7603 21.92 12.3803 22 12.0003 22Z"
        fill="#F0EEEE"
      />
    </svg>
  );
};
