import React from 'react';

export default function Badge({ status, withPointer }) {
  function renderStyles() {
    const _status = status?.toLowerCase();
    switch (_status) {
      case 'completed':
      case 'done':
        return 'bg-[#4BCE9717] text-[#4BCE97] border-0.5 border-[#4BCE97]';
      case 'pending':
      case 'in progress':
        return 'bg-[#56ACC714] text-[#56ACC7] border-0.5 border-[#56ACC7]';
      case 'canceled':
        return 'bg-red-200 text-red-800 border-0.5 border-[#4BCE97]';
      case 'on hold':
      case 'not started':
      case 'to do':
        return 'bg-[#F58E3014] text-[#F58E30] border-0.5 border-[#F58E30]';
      default:
        return 'bg-[#181818] text-[#939393] border-0.5 border-[#939393]';
    }
  }

  function renderPointerStyles() {
    const _status = status?.toLowerCase();
    switch (_status) {
      case 'completed':
      case 'done':
        return 'bg-[#4BCE97]';
      case 'pending':
      case 'in progress':
        return 'bg-[#56ACC7]';
      case 'canceled':
        return 'bg-red-800';
      case 'on hold':
      case 'not started':
      case 'to do':
        return 'bg-[#F58E30]';
      default:
        return 'bg-[#939393]';
    }
  }

  return (
    <div
      className={`${
        withPointer && status ? 'w-fit' : 'w-fit justify-center'
      } flex items-center gap-2 px-2 py-1 rounded-md text-[12px] font-semibold ${renderStyles()}`}
    >
      {withPointer && status ? (
        <div
          className={`w-[8px] h-[8px] rounded-full ${renderPointerStyles()}`}
        ></div>
      ) : null}
      <p className={`w-fit capitalize text-center`}>
        {status || '.................'}
      </p>
    </div>
  );
}
