import React from 'react';
import GlobalButton from '../ui/GlobalButton';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export default function GetOnboard({ onClose }) {
  const navigate = useNavigate();
  return (
    <div className="modal">
      <div
        // onClick={onClose}
        className="modal-overlay absolute inset-0 bg-black opacity-100 "
      ></div>

      <div className="bg6 modal-container px-8 py-8 w-[40%] h-fit top-1/2 left-1/2 absolute border-0.5 border-gray-2/50 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-5 items-center rounded-3xl shadow-md text-white-1">
        <div className="flex flex-col items-center justify-center text-center gap-1 w-full">
          <h1 className="font-bold text-[20px]">Get on board with Crafter</h1>

          <p className="text-gray-4 text-sm w-[90%]">
            Leverage AI-powered document analysis, automate ticket creation, and
            gain access to advanced features that empower you to achieve more
            with your projects.
          </p>
        </div>

        <div className="flex flex-col gap-3 items-start justify-start mt-2 text-sm w-[90%]">
          <ul className="text-sm flex flex-col gap-3 text-gray-4 list-disc list-inside">
            <li>Effortless document analysis with actionable insights.</li>
            <li>
              Streamlined ticket creation to boost your team's efficiency.
            </li>
            <li>
              Improved project management through Crafter's core features.
            </li>
          </ul>
        </div>

        <div className="w-[90%]">
          <GlobalButton
            variant={`blue`}
            state={'default'}
            size={'md'}
            onClick={() => {
              navigate('/plans');
              onClose();
            }}
          >
            View my plans
          </GlobalButton>
        </div>

        <p className="text-sm mt-2">
          Need help? Feel free to reach out to us
          {'  '}
          <Link
            to={'https://usecrafter.com/contact'}
            target="_blank"
            className="text-orange-1 underline underline-offset-4 font-semibold"
          >
            Contact us
          </Link>
        </p>
      </div>
    </div>
  );
}
