import React, { useState, useEffect } from 'react';
import signupsvg from '@/assets/signupsvg.svg';
import InputField4 from '@/components/ui/InputField4';
import GlobalButton from '@/components/ui/GlobalButton';
import WebAppService from '@/services/WebAppService';
import AuthStatusModal from '@/components/modals/AuthStatusModal';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import TermsModal from '@/components/modals/TermsModal';
import PrivacyModal from '@/components/modals/PrivacyModal';
import InputField5 from '@/components/ui/InputField5';
import CrafterLogo from '../../../icons/CrafterLogo';
import CustomButton from '../../../components/common/Custom/CustomButton/CustomButton';

export default function SignUpForm() {
  const [formData, setFormData] = useState({
    email: '',
    organization_name: '',
    first_name: '',
    last_name: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showPolicy, setShowPolicy] = useState(false);
  const [buttonSize, setButtonSize] = useState('md');
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const openTerms = () => {
    setShowTerms(true);
  };

  const closeTerms = () => {
    setShowTerms(false);
  };
  const openPolicy = () => {
    setShowPolicy(true);
  };

  const closePolicy = () => {
    setShowPolicy(false);
  };

  useEffect(() => {
    const { email, organization_name, first_name, last_name } = formData;

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const isValid =
      first_name.trim() !== '' &&
      last_name.trim() !== '' &&
      email.trim() !== '' &&
      organization_name.trim() !== '' &&
      emailRegex.test(email); // Check if email matches regex

    setFormValid(isValid);
  }, [formData]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      // Make API call to sign up
      await WebAppService.signUp(formData);

      // Reset form fields after successful submission
      setFormData({
        email: '',
        organization_name: '',
        first_name: '',
        last_name: '',
      });

      // Show success status modal or any other feedback
      setIsSuccess(true);
      setShowStatusModal(true);
      setTimeout(() => {
        setShowStatusModal(false);
        navigate('/login');
      }, 3000);
    } catch (error) {
      // Handle error
      console.error('Error during sign up:', error);

      // Check if error response exists
      if (error.response.status === 409) {
        // Extract error message from response data
        const signUpErrorMessage =
          error.response.data.error || 'Something went wrong. Please retry.';

        // Set error message state
        setErrorMessage(signUpErrorMessage);
        setIsSuccess(false);
        setShowStatusModal(true);
        setTimeout(() => {
          setShowStatusModal(false);
        }, 3000);
      } else {
        // If no response is received, set a generic error message
        setErrorMessage('Something went wrong. Please retry.');
        setIsSuccess(false);
        setShowStatusModal(true);
        setTimeout(() => {
          setShowStatusModal(false);
        }, 3000);
      }

      // Show error status modal or any other feedback
      setIsSuccess(false);
      setShowStatusModal(true);
      setTimeout(() => {
        setShowStatusModal(false);
      }, 3000);
    }

    setIsSubmitting(false);
  };

  useEffect(() => {
    // Determine the screen size and set button size accordingly
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setButtonSize('sm');
      } else if (window.innerWidth > 1024) {
        setButtonSize('lg');
      } else {
        setButtonSize('md');
      }
    };

    // Initial call to set the button size
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div className="flex flex-col gap-6 md:gap-8 pt-[40px] md:pt-0  md:m-auto items-center justify-center w-full">
        <div className="w-full flex flex-col items-center gap-6">
          <Link
            className="w-[120px] md:w-[120px] lg:w-[180px]"
            to={'https://usecrafter.com'}
            target="_blank"
          >
            <CrafterLogo />
          </Link>
        </div>
        <div className="h-fit px-5 py-6 lg:py-10 lg:px-10 w-[100%] md:w-[70%] lg:w-[70%] xl:w-[60%] xl:max-w-[550px] authBorder">
          <div className="flex flex-col gap-7">
            <div className="w-full sm:w-4/5 md:w-4/5 lg:w-full flex flex-col gap-2">
              <h1 className="text-left text-white-1 font-bold text-[20px] md:text-[20px] lg:text-[24px]">
                Create your account
              </h1>
              <p className="text-left lg:text-left text-[14px] md:text-[16px] lg:text-[16px]">
                Join thousands of engineering and product teams experiencing the
                Crafter advantage.
              </p>
            </div>
            <form
              className="w-full flex flex-col gap-6"
              onSubmit={handleSubmit}
            >
              <div className="w-full flex flex-col gap-6">
                <InputField4
                  name={'first_name'}
                  label={'First Name'}
                  type={'text'}
                  placeholder={'First name'}
                  isRequired={true}
                  value={formData.first_name}
                  onChange={handleInputChange}
                />
                <InputField4
                  name={'last_name'}
                  label={'Last Name'}
                  type={'text'}
                  placeholder={'Last name'}
                  isRequired={true}
                  value={formData.last_name}
                  onChange={handleInputChange}
                />

                <InputField5
                  type="email"
                  name="email"
                  label="Company Email"
                  placeholder="Input your company email"
                  isRequired={true}
                  value={formData.email}
                  onChange={handleInputChange}
                  message="Kindly note that to successfully create an account, you must use a work email (not gmail, outlook or yahoo mail)"
                />
                <InputField4
                  name={'organization_name'}
                  label={'Company Name'}
                  placeholder={'Input your company name'}
                  isRequired={true}
                  value={formData.organization_name}
                  onChange={handleInputChange}
                />
              </div>
              <CustomButton
                variant={'secondary'}
                size={buttonSize}
                state={
                  isSubmitting ? 'inactive' : formValid ? 'default' : 'inactive'
                }
                type="submit"
                disabled={!formValid || isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </CustomButton>
            </form>

            <p className="text-center text-[12px] md:text-[14px] lg:text-[16px]">
              Already have an account?{' '}
              <Link
                className={`text-blue-1 font-semibold underline underline-offset-4`}
                to={'/login'}
              >
                Login to your account
              </Link>
            </p>

            {/* <small className="w-[90%] lg:w-full text-center text-[12px] md:text-[14px] lg:text-[16px]">
              By creating an account you accept the{' '}
              <span
                onClick={openTerms}
                className="cursor-pointer text-blue-4 font-medium underline-offset-2 underline"
              >
                Terms of service
              </span>{' '}
              and{' '}
              <span
                onClick={openPolicy}
                className="cursor-pointer text-blue-4 font-medium underline-offset-2 underline"
              >
                Privacy Policy
              </span>
            </small> */}
          </div>
        </div>
      </div>
      {showStatusModal && (
        <AuthStatusModal isSuccess={isSuccess} errorMessage={errorMessage} />
      )}

      {showTerms && <TermsModal onClose={closeTerms} />}
      {showPolicy && <PrivacyModal onClose={closePolicy} />}
    </>
  );
}
